//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Cookie from "./Cookie/Default";
//Install
import Brand from "./Header/Brand";
//Install
import Menu from "./Header/Menu";
//Install
import SET from "./Header/SET";
//Install
import Welcome from "./Mobile/Welcome";
//Install
import Mini from "./Mobile/Brand";
//Install
import Nav from "./Mobile/Menu";
//Install
import Submenu from "./Mobile/Submenu";

//Element
class Header extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "header", { className: "float-start w-100 position-relative w1324l", itemProp: "hasPart", itemScope: "itemScope", itemType: "https://schema.org/WPHeader" },
            //Element
            React.createElement( "aside", { className: "float-start w-100 p-0 m-0 hw9107" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Cookie, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "aside", { className: "float-start w-100 p-0 m-0 s8956w" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Welcome, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "nav", { className: "float-start w-100 p-0 m-0 f55237" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Brand, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "menu", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 i72gp6" },
                                //Element
                                React.createElement( Menu, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( SET, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 m78d3j" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Mini, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( Nav, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "nav", { className: "float-start w-100 p-0 m-0 position-fixed w22y4l" },
                //Element
                React.createElement( Submenu, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Header;