//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Partner extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/partner/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 hl280a" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 a917yq" },
                //Element
                React.createElement( "picture", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 o840cp" },
                    //Element
                    React.createElement( "img", { alt: Title, title: Title, src: Image, width: "26", height: "26" } )
                )
            ),
            //Element
            React.createElement( "span", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 mt-3 x661n3", title: Title },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Partner.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Partner;