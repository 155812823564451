//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../Media/Mobile/006.svg";

//Element
class Search extends Component {
    //Element
    Target = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Value = document.getElementById( "React_S84V9B" ).value
        //Element
        if( Value === "" || Value === null || Value === "undefined" ){
            //Element
            return
        //Element
        } else{
            //Element
            window.location.href = "/on-bilgilendirme-talebi"
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 pt-4 pb-4 m-0 z120dk" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 k922mv", method: "GET", onSubmit: this.Target },
                //Element
                React.createElement( "input", { id: "React_S84V9B", name: "React_S84V9B", className: "float-start w-100 p-0 ps-4 pe-4 m-0 bg-white border-0 s2p966", placeholder: "Arama...", type: "text", required: "required" } ),
                //Element
                React.createElement( "button", { id: "React_Z2917D", name: "React_Z2917D", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 position-absolute end-0 top-0 d8005g", type: "button" },
                    //Element
                    React.createElement( Icon, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Search;