//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Default extends Component {
    //Element
    Target = () => {
        //Element
        window.location.href = "/kisisel-verilerin-korunmasi"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:10 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 g301va" },
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-truncate p083cW" },
                        //Element
                        "Bu sitenin daha iyi, daha hızlı ve güvenli çalışması için çerez kullanılmaktadır. Site üzerinde kullanılan çerezler hakkında detaylı bilgi almak için ",
                        //Element
                        React.createElement( "u", {}, 
                            //Element
                            "Çerez Politikası"
                        ),
                        //Element
                        "'nı incelemenizi rica ederiz."
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:2 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 g053w3" },
                    //Element
                    React.createElement( "button", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 bg-transparent border-0 text-truncate u96gu5", type: "button", onClick: this.Target },
                        //Element
                        "Hemen incele"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;