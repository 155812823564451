//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Store } from "../../Media/App/001.svg";
//Install
import { ReactComponent as Play } from "../../Media/App/002.svg";

//Element
class App extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 r766cl" },
            //Element
            React.createElement( "li", { className: "float-start w-auto", itemScope: "itemScope", itemType: "http://schema.org/SoftwareApplication" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 me-2 rounded-circle", title: "App Store", itemProp: "sameAs", target: "_blank", href: "https://apps.apple.com/us/app/upwork-for-freelancers/id1446736499" },
                    //Element
                    React.createElement( "meta", { itemProp: "name", content: "Upwork for Freelancers" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "description", content: "Serbest çalışanlar için yüksek değerli müşterilerden kaliteli iş bulmak için en iyi yer." } ),
                    //Element
                    React.createElement( "meta", { itemProp: "image", content: "https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/73/2b/5c/732b5ccc-8c55-e51a-1e10-482792eb1b49/FreelancerAppIcon-1x_U007emarketing-0-7-0-85-220.png/1200x630wa.png" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "datePublished", content: "Jan 11, 2019" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "operatingSystem", content: "Requires iOS 15.0 or later. Compatible with iPhone, iPad, and iPod touch." } ),
                    //Element
                    React.createElement( "meta", { itemProp: "applicationCategory", content: "Business" } ),
                    //Element
                    React.createElement( "div", { itemProp: "author", itemScope: "itemScope", itemType: "https://schema.org/Person" },
                        //Element
                        React.createElement( "meta", { itemProp: "name", content: "Upwork Global Inc." } ),
                        //Element
                        React.createElement( "meta", { itemProp: "url", content: "https://apps.apple.com/us/developer/upwork-global-inc/id329377609" } )
                    ),
                    //Element
                    React.createElement( "div", { itemProp: "aggregateRating", itemScope: "itemScope", itemType: "http://schema.org/AggregateRating" },
                        //Element
                        React.createElement( "meta", { itemProp: "ratingValue", content: "4.6" } ),
                        //Element
                        React.createElement( "meta", { itemProp: "ratingCount", content: "42281" } )
                    ),
                    //Element
                    React.createElement( "div", { itemProp: "offers", itemScope: "itemScope", itemType: "http://schema.org/Offer" },
                        //Element
                        React.createElement( "meta", { itemProp: "category", content: "Free" } ),
                        //Element
                        React.createElement( "meta", { itemProp: "price", content: "0.00" } )
                    ),
                    //Element
                    React.createElement( Store, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto", itemScope: "itemScope", itemType: "http://schema.org/SoftwareApplication" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 rounded-circle", title: "Google Play", itemProp: "sameAs", target: "_blank", href: "https://play.google.com/store/apps/details?id=com.upwork.android.apps.main&hl=tr&gl=US" },
                    //Element
                    React.createElement( "meta", { itemProp: "name", content: "Upwork for Freelancers" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "description", content: "Serbest çalışanlar için yüksek değerli müşterilerden kaliteli iş bulmak için en iyi yer." } ),
                    //Element
                    React.createElement( "meta", { itemProp: "image", content: "https://play-lh.googleusercontent.com/y4bswMT02OROjzOPa5zDGsnXX5-cBABjF93j26seJH2cEHD4PuBW1d5VvwfYleeKf4_X" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "operatingSystem", content: "Android" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "applicationCategory", content: "Business" } ),
                    //Element
                    React.createElement( "div", { itemProp: "author", itemScope: "itemScope", itemType: "https://schema.org/Person" },
                        //Element
                        React.createElement( "meta", { itemProp: "name", content: "Upwork Global Inc." } ),
                        //Element
                        React.createElement( "meta", { itemProp: "url", content: "https://www.upwork.com/" } )
                    ),
                    //Element
                    React.createElement( "div", { itemProp: "aggregateRating", itemScope: "itemScope", itemType: "http://schema.org/AggregateRating" },
                        //Element
                        React.createElement( "meta", { itemProp: "ratingValue", content: "4.154093265533447" } ),
                        //Element
                        React.createElement( "meta", { itemProp: "ratingCount", content: "151867" } )
                    ),
                    //Element
                    React.createElement( "div", { itemProp: "offers", itemScope: "itemScope", itemType: "http://schema.org/Offer" },
                        //Element
                        React.createElement( "meta", { itemProp: "price", content: "0.00" } ),
                        //Element
                        React.createElement( "meta", { itemProp: "priceCurrency", content: "TRY" } )
                    ),
                    //Element
                    React.createElement( Play, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default App;