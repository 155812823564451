//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Headline from "./Content/Headline";
//Install
import Count from "./Content/Count";
//Install
import Icon from "./Content/Icon";
//Install
import Sub from "./Content/Sub";
//Install
import Showcase from "./Content/Showcase";
//Install
import Featured from "./Content/Featured";
//Install
import User from "./Content/User";
//Install
import Comment from "./Content/Comment";
//Install
import Reference from "./Content/Reference";
//Install
import Brand from "./Content/Brand";
//Install
import Tab from "./Content/Tab";
//Install
import Features from "./Content/Features";
//Install
import Slogan from "./Content/Slogan";
//Install
import Partner from "./Content/Partner";
//Install
import Middle from "./Headline/Middle";
//Install
import Hope from "./Headline/Hope";
//Install
import Design from "./Headline/Design";
//Install
import Internet from "./Headline/Internet";

//Element
class Main extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Güçlü Bir Marka Kimliği Oluşturun. - Benozgurtosun.com"
        //Element
        Description.content = "Ben Özgür Tosun, 7 Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Web Tasarım Hizmetleri Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Güçlü Bir Marka Kimliği Oluşturun. - Benozgurtosun.com"
        //Element
        Description.content = "Ben Özgür Tosun, 7 Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Web Tasarım Hizmetleri Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Güçlü Bir Marka Kimliği Oluşturun. - Benozgurtosun.com"
        //Element
        Description.content = "Ben Özgür Tosun, 7 Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Web Tasarım Hizmetleri Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description   = document.querySelector( ".e745l2" )
        //Element
        let Sub           = document.querySelector( "#React_S97S23" )
        //Element
        let Middle        = document.querySelector( "#main_Middle .qo3320" )
        //Element
        let Internet      = document.querySelector( "#main_Internet .qo3320" )
        //Element
        let Success       = document.querySelector( "#main_Success .qo3320" )
        //Element
        let Partner       = document.querySelector( "#main_Internet .i31t7q" )
        //Element
        let Awareness     = document.querySelector( "#React_UM296S" )
        //Element
        let Relationships = document.querySelector( "#React_FT982U" )
        //Element
        let New           = document.querySelector( "#React_G80S0M" )
        //Element
        let First         = document.querySelector( "#React_B88U12 .t77am5" )
        //Element
        let Brand         = document.querySelector( "#React_Q1995G .t77am5" )
        //Element
        let Rivalry       = document.querySelector( "#React_T5390N .t77am5" )
        //Element
        let Heading       = document.querySelector( "#main_Middle .c28g97" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "İşinizi online dünyada öne çıkarmak ve müşterilerle güçlü bağlantılar kurmak için kurumsal web tasarım ile tanışın!"
            //Element
            Middle.innerHTML        = "Bugün 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet, önemli müşteriler edinmek için muazzam bir fırsat sunar. Hemen harekete geçin ve işinizi daha ileriye taşıyın."
            //Element
            Success.innerHTML       = "Kurumsal bir web tasarım ile markanızı vurgulayın ve müşterileri etkileyin. Hemen ilk adımı atın."
            //Element
            Partner.innerHTML       = "Benimle çalışan işletmelere, değer katan küresel markalar, işletmelere başarılı olabilmeleri için gereken araçları sunar."
            //Element
            Awareness.innerHTML     = "Benzersiz bir web tasarım ile önemli müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından birini keşfedin."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirin, işinizin dijital varlığını web tasarım ile oluşturun ve müşterilerle güçlü bir bağ kurun."
            //Element
            New.innerHTML           = "Yeni müşteriler edinmek için, iyi bir web tasarımı şart. Benimle çalışın ve müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "Web sitesi ziyaretçileri, ilk izlenimlere büyük önem verir. İyi bir web tasarımı, işletme ciddiyetini artırarak güven oluşturabilir."
            //Element
            Brand.innerHTML         = "Web tasarım işletmenizin marka kimliğini etkili bir şekilde yansıtarak, marka tanıtımını güçlendiren önemli bir unsurdur."
            //Element
            Rivalry.innerHTML       = "Kurumsal bir web tasarımı, rakiplerin arasından öne çıkmanıza ve müşteri çekmenize yardımcı olur."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Çevrimiçi varlığınızı güçlendirmek ve müşterileriniz ile sağlam bağlar kurmak için, kurumsal web tasarım ile tanışın."
            //Element
            Middle.innerHTML        = "Bugün, 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet, İşletmenizi dijital ile buluşturmanın en etkili yolu. İşinizi öne çıkarın, çevrimiçi varlığınızı güçlendirin."
            //Element
            Success.innerHTML       = "Etkileyici, bir web tasarımı ile markanızı öne çıkarın. Fark yaratmak için adım atın, dijital dünyada kendinizi gösterin."
            //Element
            Partner.innerHTML       = "Bu küresel markalar, işletmelerin başarısı için gerekli araçları sunarak değer katıyor."
            //Element
            Awareness.innerHTML     = "Kurumsal bir web tasarım, önemli müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından biridir."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirin, işletmenizin online varlığını web tasarım ile oluşturun."
            //Element
            New.innerHTML           = "Yeni müşteriler çekmek için iyi bir web tasarım şart. Benimle çalışın, müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "İnsanlar ilk izlenime önem verir. Şık bir web tasarımı işletme ciddiyetini artırarak güven oluşturabilir."
            //Element
            Brand.innerHTML         = "Web site tasarımı, marka kimliğinizi etkili bir şekilde yansıtarak tanıtımınızı güçlendirir."
            //Element
            Rivalry.innerHTML       = "İyi bir web tasarımı, rakipler arasında sıyrılmanıza ve müşteri çekmenize katkı sağlar."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Müşterilerinizle daha güçlü bağlantılar kurun! İyi bir web tasarım ile dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
            //Element
            Middle.innerHTML        = "Bugün 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet, önemli müşteriler edinme şansı sunar. İşinizi tanıtarak büyüme şansınızı artırabilirsiniz."
            //Element
            Success.innerHTML       = "İnternet işletmeler için başarının anahtarıdır! Çevrimiçi varlığınızı güçlendirerek daha fazla müşteri çekebilir ve rekabet avantajı elde edebilirsiniz."
            //Element
            Partner.innerHTML       = "Benim ile çalışan işletmelere, değer katan bu markalar işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
            //Element
            Awareness.innerHTML     = "Kurumsal bir web tasarım, önemli müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından biridir."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirin. İşletmenizin çevrimiçi varlığını web tasarım ile oluşturun."
            //Element
            New.innerHTML           = "Önemli müşteriler çekmek için iyi bir web tasarım şart. Benimle çalışın, müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "İnsanlar ilk izlenime önem verir. Güçlü bir web tasarımı işletme ciddiyetini artırarak güven oluşturabilir."
            //Element
            Brand.innerHTML         = "Web sitesi tasarımı, markanızın özünü etkili bir şekilde yansıtarak çevrimiçi varlığınızı güçlendirir."
            //Element
            Rivalry.innerHTML       = "Kaliteli web tasarımı, rekabetin yoğun olduğu ortamda öne çıkmanıza ve müşteri çekmenize katkıda bulunur."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Gururu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Müşterilerle daha güçlü bağlantılar kurun! Etkileyici bir web tasarım ile dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
            //Element
            Middle.innerHTML        = "Bugün, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet, önemli müşteri edinme fırsatları sunar. İşinizi tanıtarak büyüme şansınızı artırabilirsiniz."
            //Element
            Success.innerHTML       = "İnternet işletmeler için başarının anahtarıdır! Çevrimiçi varlığınızı güçlendirerek müşteri çekebilir ve rekabet avantajı elde edebilirsiniz."
            //Element
            Partner.innerHTML       = "İşbirliği yaptığı işletmelere değer katan bu markalar, orta ölçekli işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
            //Element
            Awareness.innerHTML     = "Kurumsal bir web tasarım önemli müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından biridir."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirmek için işletmenizin online varlığını etkili bir web tasarımı ile kurun."
            //Element
            New.innerHTML           = "Önemli müşteriler çekmek için etkili bir web tasarım şart. Benimle çalışın, müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "Müşteriler ilk izlenime önem verir. Güçlü bir web tasarımı işletme ciddiyetini artırarak güven oluşturabilir."
            //Element
            Brand.innerHTML         = "Web sitesi tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtarak çevrimiçi varlığınızı güçlendirir."
            //Element
            Rivalry.innerHTML       = "Güçlü web tasarım, rekabetin yoğun olduğu ortamda öne çıkmanıza ve müşteri çekmenize katkıda bulunur."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Müşteriler ile daha güçlü bağlantılar kurun! Kurumsal bir web tasarım ile dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
            //Element
            Middle.innerHTML        = "Bugün, 7 Bölge'de, 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet, müşteri edinme konusunda mükemmel fırsatlar sunar. İşinizi tanıtarak büyüme potansiyelinizi artırabilirsiniz."
            //Element
            Success.innerHTML       = "İşletmeler için başarıya giden yolun anahtarı internettir! Çevrimiçi varlığınızı güçlendirerek müşteri çekebilir ve rekabet avantajı elde edebilirsiniz."
            //Element
            Partner.innerHTML       = "Benimle çalışan işletmelere değer katan küresel markalar, işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
            //Element
            Awareness.innerHTML     = "Kurumsal bir web tasarım önemli müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından biridir."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirmek için işletmenizin online varlığını etkili bir web tasarımı ile kurun."
            //Element
            New.innerHTML           = "Önemli müşteriler çekmek için etkili bir web tasarım şart. Benimle çalışın, müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "Müşteriler ilk izlenime önem verir. Güçlü bir web tasarımı işletme ciddiyetini artırarak güven oluşturabilir."
            //Element
            Brand.innerHTML         = "Web sitesi tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtarak çevrimiçi varlığınızı güçlendirir."
            //Element
            Rivalry.innerHTML       = "Güçlü web tasarım rekabetin yoğun olduğu ortamda öne çıkmanıza ve müşteri çekmenize katkıda bulunur."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Müşteriler ile daha güçlü bağlantılar kurun! Kurumsal bir web tasarım ile dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
            //Element
            Middle.innerHTML        = "Bugün, 7 Bölge'de, 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet, müşteri edinme konusunda mükemmel fırsatlar sunar. İşinizi tanıtarak büyüme potansiyelinizi artırabilirsiniz."
            //Element
            Success.innerHTML       = "İşletmeler için başarıya giden yolun anahtarı internettir! Çevrimiçi varlığınızı güçlendirerek müşteri çekebilir ve rekabet avantajı elde edebilirsiniz."
            //Element
            Partner.innerHTML       = "Benimle çalışan işletmelere değer katan küresel markalar, işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
            //Element
            Awareness.innerHTML     = "Kurumsal bir web tasarım önemli müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından biridir."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirmek için işletmenizin online varlığını etkili bir web tasarımı ile kurun."
            //Element
            New.innerHTML           = "Önemli müşteriler çekmek için etkili bir web tasarım şart. Benimle çalışın, müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "Müşteriler ilk izlenime önem verir. Güçlü bir web tasarımı işletme ciddiyetini artırarak güven oluşturabilir."
            //Element
            Brand.innerHTML         = "Web sitesi tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtarak çevrimiçi varlığınızı güçlendirir."
            //Element
            Rivalry.innerHTML       = "Güçlü web tasarım rekabetin yoğun olduğu ortamda öne çıkmanıza ve müşteri çekmenize katkıda bulunur."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Müşterilerle daha sağlam bağlar kurmak için, kurumsal bir web tasarımı kullanın! Dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
            //Element
            Middle.innerHTML        = "Bugün, 7 Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet müşteri edinme konusunda harika fırsatlar sunar. İşinizi tanıtarak büyüme potansiyelinizi artırabilirsiniz."
            //Element
            Success.innerHTML       = "Başarının kapıları, işletmeler için internet üzerinden açılır. Dijital varlığınızı güçlendirerek müşteri çekebilir ve rekabet avantajı elde edebilirsiniz."
            //Element
            Partner.innerHTML       = "Benimle çalışan işletmelere değer katan küresel markalar işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
            //Element
            Awareness.innerHTML     = "Kurumsal bir web tasarım önemli müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından biridir."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirmek için işletmenizin online varlığını etkili bir web tasarımı ile kurun."
            //Element
            New.innerHTML           = "Önemli müşteriler çekmek için etkili bir web tasarım şart. Benimle çalışın, müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "Müşteriler ilk izlenime önem verir. Güçlü bir web tasarımı işletme ciddiyetini artırarak güven oluşturabilir."
            //Element
            Brand.innerHTML         = "Web sitesi tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtarak çevrimiçi varlığınızı güçlendirir."
            //Element
            Rivalry.innerHTML       = "Güçlü web tasarım rekabetin yoğun olduğu ortamda öne çıkmanıza ve müşteri çekmenize katkıda bulunur."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun 68 Farklı Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Dijital varlık oluşturarak, işletmenizi öne çıkarabilir, önemli müşterilere ulaşabilir ve satışlarınızı artırabilirsiniz."
            //Element
            Middle.innerHTML        = "Bugün 7 Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "İnternet : İşletmenizin öne çıkması ve önemli müşterilere ulaşmanın kapılarını aralamak için güçlü bir araçtır."
            //Element
            Success.innerHTML       = "Markanızı ön plana çıkarmak ve müşterilerinizi etkilemek için kurumsal bir web tasarım ile öne geçin."
            //Element
            Partner.innerHTML       = "İş ortaklarıma değer katan bu küresel markalar şirketlere başarılı olabilmeleri için gereken araçları sunar."
            //Element
            Awareness.innerHTML     = "Kurumsal bir web tasarımı ile önemli müşterilere güçlü bir ilk izlenim oluşturmanın etkili bir yolunu keşfedin."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirin. Web tasarım ile işletmenizin dijital varlığını oluşturun, müşteriler ile derin bir bağ kurun."
            //Element
            New.innerHTML           = "Yeni müşteriler edinmek için profesyonel bir web tasarımı şart. Benimle çalışın ve müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "İnternet kullanıcıları, bir web sitesini ziyaret ettiklerinde ilk izlenimlere önem verir. Profesyonel web tasarım, güveni artırarak işletmenizin ciddiyetini yansıtabilir."
            //Element
            Brand.innerHTML         = "Web tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtan önemli bir unsurdur. Renkler, logo konumu ve genel tasarım, marka tanıtımını güçlendirebilir."
            //Element
            Rivalry.innerHTML       = "Profesyonel bir web sitesi, rakipler arasında öne çıkmanıza ve müşteri çekmenize yardımcı olur. Estetik, müşteri çekme konusunda önemli avantajlar sunar."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Online varlık oluşturarak, işletmenizi görünür kılabilir, önemli müşterilere ulaşabilir ve hem online hem de yerel satışları artırabilirsiniz."
            //Element
            Middle.innerHTML        = "Bugün; 7 Bölge'de, 68+ Şehir'de 5.000+ Farklı İşletme İçin Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "Online varlık oluşturarak işletmenizi daha görünür kılabilir. Yeni müşterilere erişim sağlayabilir ve hem online hem de yerel satışlarınızı artırabilirsiniz."
            //Element
            Success.innerHTML       = "Kurumsal web tasarım ile markanızı öne çıkarın müşterileri etkileyin ve dijital alanda fark yaratın."
            //Element
            Partner.innerHTML       = "İş ortaklarıma değer katan bu markalar, küçük işletmelerin başarılı olmaları için gerekli araçları sağlar."
            //Element
            Awareness.innerHTML     = "Profesyonel bir web tasarım ile potansiyel müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından birini keşfedin."
            //Element
            Relationships.innerHTML = "Sadece web tasarımı yapmakla kalmayıp işinizi özel ve etkili bir şekilde temsil etmek için dijital varlığınızı güçlendiriyorum."
            //Element
            New.innerHTML           = "Yeni müşteriler edinmek için benzersiz bir web tasarımı şart. Benimle çalışın, çevrimiçi varlığınızı güçlendirin ve müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "İnternet kullanıcıları, bir web sitesini ziyaret ettiklerinde ilk izlenimlere önem verir. Profesyonel tasarım, güveni artırarak işletmenizin ciddiyetini yansıtabilir."
            //Element
            Brand.innerHTML         = "Web tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtan önemli bir unsurdur. Renkler, logo konumu ve genel tasarım, marka tanıtımını güçlendirebilir."
            //Element
            Rivalry.innerHTML       = "Profesyonel bir web sitesi, rakipler arasında öne çıkmanıza ve müşteri çekmenize yardımcı olur. Estetik ve işlevsellik, müşteri çekme konusunda önemli avantajlar sunar."
            //Element
            Heading.innerHTML       = "Bugün 68 Farklı Şehir'de 5.000 Farklı İşletme."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML   = "Ben Özgür Tosun, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Online varlık oluşturarak, işletmenizi görünür kılabilir, önemli müşterilere ulaşabilir ve hem online hem de yerel satışları artırabilirsiniz."
            //Element
            Middle.innerHTML        = "Bugün; 7 Bölge'de, 68+ Şehir'de 5.000+ Farklı İşletme İçin Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "Online varlık oluşturarak işletmenizi daha görünür kılabilir. Yeni müşterilere erişim sağlayabilir ve hem online hem de yerel satışlarınızı artırabilirsiniz."
            //Element
            Success.innerHTML       = "Kurumsal web tasarım ile markanızı öne çıkarın, müşterileri etkileyin ve dijital alanda fark yaratın."
            //Element
            Partner.innerHTML       = "İş ortaklarıma değer katan bu markalar, küçük işletmelerin başarılı olmaları için gerekli araçları sağlar."
            //Element
            Awareness.innerHTML     = "Profesyonel bir web tasarım ile potansiyel müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından birini keşfedin."
            //Element
            Relationships.innerHTML = "Sadece web tasarımı yapmakla kalmayıp işinizi özel ve etkili bir şekilde temsil etmek için dijital varlığınızı güçlendiriyorum."
            //Element
            New.innerHTML           = "Yeni müşteriler edinmek için benzersiz bir web tasarımı şart. Benimle çalışın, çevrimiçi varlığınızı güçlendirin ve müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "İnternet kullanıcıları, bir web sitesini ziyaret ettiklerinde ilk izlenimlere önem verir. Profesyonel tasarım, güveni artırarak işletmenizin ciddiyetini yansıtabilir."
            //Element
            Brand.innerHTML         = "Web tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtan önemli bir unsurdur. Renkler, logo konumu ve genel tasarım, marka tanıtımını güçlendirebilir."
            //Element
            Rivalry.innerHTML       = "Profesyonel bir web sitesi, rakipler arasında öne çıkmanıza ve müşteri çekmenize yardımcı olur. Estetik ve işlevsellik, müşteri çekme konusunda önemli avantajlar sunar."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
            //Element
            Description.innerHTML   = "Merhaba, Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML           = "Dijital varlık oluşturarak işletmenizi daha görünür kılabilir, önemli müşterilere erişim sağlayabilir ve hem online hem de yerel satışlarınızı artırabilirsiniz."
            //Element
            Middle.innerHTML        = "Bugün 7 Bölge'de, 68 Farklı Şehir'de 5.000 Farklı İşletme İçin Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
            //Element
            Internet.innerHTML      = "Dijital varlık oluşturarak işletmenizi daha görünür kılabilir, yeni müşterilere erişim sağlayabilir ve hem online hem de yerel satışlarınızı artırabilirsiniz."
            //Element
            Success.innerHTML       = "Profesyonel web tasarım ile markanızı öne çıkarın, müşterileri etkileyin ve dijital alanda fark yaratın."
            //Element
            Partner.innerHTML       = "İş ortaklarıma değer katan global markalar, küçük işletmelerin başarılı olmaları için gerekli destek ve araçları sağlar."
            //Element
            Awareness.innerHTML     = "Profesyonel bir web tasarım ile potansiyel müşterilere güçlü bir ilk izlenim bırakmanın en etkili yollarından birini keşfedin."
            //Element
            Relationships.innerHTML = "Sadece web tasarımı yapmakla kalmayıp işinizi özel ve etkili bir şekilde temsil etmek için dijital varlığınızı güçlendiriyorum."
            //Element
            New.innerHTML           = "Yeni müşteriler edinmek için profesyonel bir web tasarımı şart. Benimle çalışın, çevrimiçi varlığınızı güçlendirin ve müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "İnternet kullanıcıları, bir web sitesini ziyaret ettiklerinde ilk izlenimlere önem verir. Profesyonel tasarım, güveni artırarak işletmenizin ciddiyetini yansıtabilir."
            //Element
            Brand.innerHTML         = "Web tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtan önemli bir unsurdur. Renkler, logo konumu ve genel tasarım, marka tanıtımını güçlendirebilir."
            //Element
            Rivalry.innerHTML       = "Profesyonel bir web sitesi, rakipler arasında öne çıkmanıza ve müşteri çekmenize yardımcı olur. Estetik ve işlevsellik, müşteri çekme konusunda önemli avantajlar sunar."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Awareness.innerHTML     = "Profesyonel bir web tasarımı ile önemli müşterilere, güçlü bir ilk izlenim oluşturmanın etkili bir yolunu keşfedin."
            //Element
            Relationships.innerHTML = "İlişkilerinizi güçlendirin. Kurumsal web tasarım ile işletmenizin çevrimiçi varlığını oluşturun ve müşteriler ile derin bir bağ kurun."
            //Element
            New.innerHTML           = "Yeni müşteriler edinmek için profesyonel bir web tasarımı şart. Benimle çalışın ve müşteri kitlenizi genişletin."
            //Element
            First.innerHTML         = "İnternet kullanıcıları, bir internet sitesini ziyaret ettiklerinde ilk izlenimlere önem verir. Profesyonel tasarım, güveni artırarak işletmenizin ciddiyetini yansıtabilir."
            //Element
            Brand.innerHTML         = "Web tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtan önemli bir unsurdur. Renkler, logo ve tasarım, marka tanıtımını güçlendirebilir."
            //Element
            Rivalry.innerHTML       = "Profesyonel bir web sitesi, rakipler arasında öne çıkmanıza ve müşteri çekmenize yardımcı olur. Estetik ve işlevsellik, müşteri çekme konusunda önemli avantajlar sunar."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    Comments = () => {
        //Element
        let Comment_One   = document.querySelector( "#React_3UD7Q9" )
        //Element
        let Comment_Two   = document.querySelector( "#React_VG49X1" )
        //Element
        let Comment_Three = document.querySelector( "#React_Z3721Y" )
        //Element
        let Comment_Four  = document.querySelector( "#React_088KZ0" )
        //Element
        let Comment_Five  = document.querySelector( "#React_P82B3E" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayalimdeki web tasarımını harika bir domain önerisiyle hayata geçirdi. Hiç tereddüt etmeden herkese tavsiye ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarımındaki etkili çalışmaları ve olağanüstü iletişim becerileriyle beni kendine hayran bıraktı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında gerçekten muazzam işlere imza attı. Hızlı iletişimi ve olumlu müşteri geri dönüşleri için kendisine teşekkür ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür harika bir web tasarımcı! Yaratıcılığı ve iletişim becerileri beni etkiledi. Onu gönül rahatlığıyla tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür, işbirliği sürecinde öne çıkan bir isimdi. Profesyonelliği sayesinde mükemmel bir sonuç elde ettik. Kendisine teşekkür ederim."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayalimdeki web tasarımını harika bir alan adı önerisiyle gerçeğe dönüştürdü. Kesinlikle tavsiye ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür, etkileyici web tasarımı ve mükemmel iletişim becerileriyle öne çıktı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında olağanüstü başarılar elde etti. Olumlu müşteri geri dönüşleri için kendisine teşekkür ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, muhteşem bir web tasarımcı! Yaratıcılığı ve iletişim becerileri beni etkiledi. Kesinlikle tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey, işbirliğimizde öne çıkan bir isimdi. Profesyonel ve yaratıcı yaklaşımıyla harika bir sonuç aldık. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için harika bir web tasarım ve iyi bir domain önerisiyle beklentilerimi aştı. Kendisine teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetinizden memnunum. Harika bir site ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, şirket web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak sadece keyifli değil, aynı zamanda uzmanlığı sayesinde harika bir sonuç elde etmemi sağladı."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için harika bir web tasarım ve iyi bir domain önerisiyle beklentilerimi aştı. Kendisine teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetinizden memnunum. Harika bir site ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, şirket web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak sadece keyifli değil, aynı zamanda uzmanlığı sayesinde harika bir sonuç elde etmemi sağladı."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletme için harika bir web tasarım ve mükemmel bir domain önerisiyle beklentilerimi fazlasıyla karşıladı. Kendisine minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetiniz beni memnun etti. İyi bir web sitesi ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, işletme web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak sadece keyifli değil, aynı zamanda uzmanlığı sayesinde harika bir sonuç elde etmemi sağladı."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Comment_One.innerHTML   = "Özgür, işletmem için mükemmel bir web tasarımı ve harika bir domain önerisiyle beklentilerimi fazlasıyla karşıladı. Kendisine minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetiniz beni memnun etti. İyi bir web sitesi ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, işletme web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey'in web site tasarımındaki uzmanlığı ve profesyonelliği, başarı elde etmemize önemli katkı sağladı. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Comment_One.innerHTML   = "Özgür, işletmem için mükemmel bir web tasarımı ve harika bir domain önerisiyle beklentilerimi fazlasıyla karşıladı. Kendisine minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetiniz beni memnun etti. İyi bir web sitesi ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, işletme web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey'in web site tasarımındaki uzmanlığı ve profesyonelliği, başarı elde etmemize önemli katkı sağladı. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayallerimdeki web tasarımı gerçeğe dönüştürdü ve harika bir domain önerisiyle beni etkiledi. Kesinlike tavsiye ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür muhteşem bir internet sitesi tasarladı ve iletişimdeki profesyonelliği benzersizdi. Kesinlikle tavsiye ediyorum."
            //Element
            Comment_Three.innerHTML = "Özgür web tasarımında harikalar yarattı. İletişim hızlı ve müşteri geri dönüşleri çok olumlu. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonelliği, yaratıcılığı ve iletişim becerileri gerçekten etkileyici. Tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey'le çalışmak keyifliydi. Profesyonelliği ve tasarım yetenekleri sayesinde mükemmel bir sonuç elde ettik. Teşekkür ederim."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayal ettiğim web tasarımını sundu ve mükemmel bir domain önerisiyle şaşırttı. Profesyonelliği ve yaratıcılığı için minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, muhteşem bir web sitesi tasarladı ve iletişimdeki profesyonelliği benzersizdi. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında harikalar yarattın! Müşteri geri dönüşleri pozitif ve iletişim hızlı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel, yaratıcı ve iletişim becerileri harika. Kesinlikle tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey ile işbirliği keyifliydi. Profesyonelliği ve tasarım yetenekleri mükemmel bir sonuç elde etmemize katkı sağladı. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayal ettiğim web tasarımını sundu ve mükemmel bir domain önerisiyle şaşırttı. Profesyonelliği ve yaratıcılığı için minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, muhteşem bir web sitesi tasarladı ve iletişimdeki profesyonelliği benzersizdi. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında harikalar yarattın! Müşteri geri dönüşleri pozitif ve iletişim hızlı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel, yaratıcı ve iletişim becerileri harika. Kesinlikle tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey ile işbirliği keyifliydi. Profesyonelliği ve tasarım yetenekleri mükemmel bir sonuç elde etmemize katkı sağladı. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, sunduğu benzersiz web tasarımı ve ideal domain önerisiyle beklentilerimi aştı. Profesyonelliği ve yaratıcılığı için kendisine teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür, harika bir web sitesi tasarladın ve iletişimdeki profesyonellik takdire değerdi. Sizi memnuniyetle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında harika bir iş çıkardın. Müşteri geri dönüşleri olumlu ve iletişim hızlıydı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonel, yaratıcı ve sonuç odaklı. İletişimi mükemmel. Kesinlikle tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür ile işbirliği keyifliydi. Profesyonelliği ve tasarım yetenekleri mükemmel bir sonuç elde etmemize katkı sağladı. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için sunduğunuz özel web tasarımı ve harika domain önerisi beni etkiledi. Profesyonellik ve yaratıcılığınız için teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür'ün işletmem için hazırladığı web sitesine bayıldım! Harika bir site ve profesyonel yaklaşımıyla beni etkiledi. İçtenlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, kurumsal web tasarımımızı yeniden şekillendirdiğin için minnettarız! İletişim becerilerin ve işbirliği anlayışın sayesinde projemiz çok başarılı oldu. Teşekkürler."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcısı! Profesyonellik, yaratıcılık ve müşteri memnuniyeti konusundaki yetenekleri beni çok etkiledi. Çok memnun kaldım. Teşekkürler."
            //Element
            Comment_Five.innerHTML  = "Özgür ile çalışmak inanılmazdı! Web sitemin tasarımındaki ustalığı ve yaratıcılığı sayesinde mükemmel bir sonuç elde ettik. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için özel bir web tasarımı ve mükemmel bir domain önerisiyle beni şaşırttı. Profesyonelliğiniz ve yaratıcılığınız için teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür web tasarımınıza hayran kaldım. Oluşturduğunuz harika web sitesi ve gösterdiğiniz profesyonellik beni etkiledi. Sizi içtenlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, kurumsal web tasarımımızı yeniden şekillendirdiğin için minnettarız! İletişim becerilerin ve işbirliği anlayışın sayesinde projemiz çok başarılı oldu. Teşekkür ederim!"
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcısı! Profesyonellik, yaratıcılık ve müşteri memnuniyeti konusundaki yetenekleri beni çok etkiledi. Sonuçtan fazlasıyla memnun kaldım."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak muhteşemdi! Web sitemin tasarımındaki profesyonellik ve yetenekleri sayesinde harika bir sonuç aldık. Teşekkür ederim."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Güçlü Bir Marka Kimliği Oluşturun. - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Ben Özgür Tosun, 7 Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Web Tasarım Hizmetleri Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
        //Element
        this.Comments()
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "4",
            //Element
            autoplay        : {
                //Element
                delay       : 2000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 2
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 2
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 2
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 4
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Marketing = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "3",
            //Element
            autoplay        : {
                //Element
                delay       : 3000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 2
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 3
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Comments = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            pagination      : {
                //Element
                clickable   : true
            },
            //Element
            autoplay        : {
                //Element
                delay       : 4000
            },
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const References = {
            //Element
            spaceBetween    : "20.25",
            //Element
            slidesPerView   : "9",
            //Element
            autoplay        : {
                //Element
                delay       : 2000
            },
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                346:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                390:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                393:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                400:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                411:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                432:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                720:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 8
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 8
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 9
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 position-relative h119in", itemProp: "hasPart", itemScope: "itemScope", itemType: "https://schema.org/CreativeWork" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 w958nq" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Headline, { ID: "0", Title: "Tam İstediğiniz Gibi Bir Site Oluşturabilirim.", Summary: "Ben Özgür Tosun 7 Farklı Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım.", Photo: "000.mp4", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 c250ba" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "7", Summary: "Farklı Bölge'de", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "68", Summary: "Farklı Şehir'de", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "2.900", Summary: "Yeni Kurulan İşletme", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "1.800", Summary: "Küçük Ölçekli İşletme", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "1.300", Summary: "Orta Ölçekli İşletme", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "99.8%", Summary: "İşletme Başarı Oranları", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 x35f74", itemScope: "itemScope", itemType: "https://schema.org/HowToDirection" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 c741h7" },
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "HTML5", Photo: "001.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "CSS3", Photo: "002.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "JavaScript", Photo: "003.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "jQuery", Photo: "004.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Sass", Photo: "005.svg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 h4s606" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 e74i23" },
                                    //Element
                                    React.createElement( "img", { alt: "Müşteriler İle Daha Güçlü Bağlantılar Kurun.", title: "Müşteriler İle Daha Güçlü Bağlantılar Kurun.", src: "https://cdn.benozgurtosun.com/v1/corporation/813844.webp", width: "526", height: "477", itemProp: "image" } ) 
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 c741h7" },
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "React", Photo: "006.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Node.JS", Photo: "007.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "CodeceptJS", Photo: "021.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Vue.js", Photo: "009.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Angular", Photo: "010.svg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Sub, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { id: "main_Middle", className: "float-start w-100 p-0 m-0 p2z98p" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Middle, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative sI631f" },
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Form Sünger", Photo: "004.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "GSD Marin", Photo: "005.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Pürsu", Photo: "017.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Europap", Photo: "007.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Kalekim", Photo: "009.webp", Target: "/referanslar" } )
                    )
                ),
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Marketing },
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Featured, { ID: "0", Title: "Intimissimi", Photo: "002.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Featured, { ID: "0", Title: "BYD", Photo: "001.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Featured, { ID: "0", Title: "HES Kablo", Photo: "006.webp", Target: "/referanslar" } )
                    )
                ),
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "ZES", Photo: "014.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Şahinler", Photo: "016.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Delphi", Photo: "010.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Çelikel", Photo: "012.webp", Target: "/referanslar" } )
                    ),
                    //Element
                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/SoftwareSourceCode" },
                        //Element
                        React.createElement( Showcase, { ID: "0", Title: "Demir Export", Photo: "013.webp", Target: "/referanslar" } )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 j0r112" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Ahmet Delioğlu", Photo: "006.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Nejdet Baş", Photo: "007.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Bünyamin Baykal", Photo: "008.webp", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Nur Hendekli", Photo: "009.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Doğu Dost Onural", Photo: "002.webp", Target: "/" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 h68k88" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Comments },
                                    //Element
                                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/Review" },
                                        //Element
                                        React.createElement( Comment, { ID: "React_3UD7Q9", User: "Ömer Araz", Firm: "Viennalife Emeklilik ve Hayat A.Ş.", Photo: "005.webp", Text: "Özgür bey, işletmeme özel harika bir web tasarımı ve ideal bir domain önerisiyle beklentilerimi aştı. Profesyonelliği ve yaratıcılığı için kendisine teşekkür ederim.", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/Review" },
                                        //Element
                                        React.createElement( Comment, { ID: "React_VG49X1", User: "Cemgil Kaya", Firm: "Still Arser İş Makinaları Servis ve Tic. A.Ş.", Photo: "004.webp", Text: "Özgür, web tasarım hizmetinizden son derece memnun kaldım. Harika bir web sitesi oluşturdunuz ve iletişimdeki profesyonellik takdire değerdi. Sizi gönül rahatlığıyla tavsiye ederim.", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/Review" },
                                        //Element
                                        React.createElement( Comment, { ID: "React_Z3721Y", User: "Meral Kamberoğlu", Firm: "Korozo Ambalaj San. ve Tic. A.Ş.", Photo: "003.webp", Text: "Özgür, şirketimizin web tasarımında dokunuşunu kattı ve sonuç harika! Müşterilerden olumlu geri dönüşler alıyoruz. İletişimi hızlı ve işbirliği keyifliydi. Hiç tereddüt etmeden tavsiye ederim.", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/Review" },
                                        //Element
                                        React.createElement( Comment, { ID: "React_088KZ0", User: "Doğu Dost Onural", Firm: "Karcher Servis Ticaret A.Ş.", Photo: "002.webp", Text: "Özgür, harika bir web tasarımcısı! Profesyonel, yaratıcı ve müşteri memnuniyetine odaklı bir yaklaşımı var. İletişimi mükemmel ve sonuç beni çok memnun etti. Kesinlikle tavsiye ederim.", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { itemScope: "itemScope", itemType: "https://schema.org/Review" },
                                        //Element
                                        React.createElement( Comment, { ID: "React_P82B3E", User: "İhsan Aydoğdu", Firm: "Turpak Elektromanyetik Yakıt İkmal Sis. Tic. A.Ş.", Photo: "001.webp", Text: "Özgür ile işbirliği yapmak gerçekten keyifliydi. Web sitemin tasarımındaki yetenekleri ve profesyonelliği, mükemmel bir sonuca ulaşmamıza katkı sağladı. Teşekkürler.", Target: "/referanslar" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Cemgil Kaya", Photo: "004.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "İhsan Aydoğdu", Photo: "001.webp", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Özlem Altun", Photo: "013.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Elçin Önel", Photo: "014.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Melih Şahinöz", Photo: "015.webp", Target: "/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e8336u" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...References },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "1", Title: "Ceyda Alev", Photo: "001.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "2", Title: "Ataer Emlak", Photo: "002.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "3", Title: "Demircioğlu İnşaat", Photo: "003.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "4", Title: "Demirler Hukuk", Photo: "004.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "5", Title: "Melekler Elektrik", Photo: "005.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "6", Title: "Deryalar", Photo: "006.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "7", Title: "Fabrika İnşaat", Photo: "007.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "8", Title: "Günal Lojistik", Photo: "008.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "9", Title: "Mine Tekstil", Photo: "010.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "10", Title: "Türker İlaç", Photo: "009.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "11", Title: "Baks Motor", Photo: "011.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "12", Title: "NOTA", Photo: "012.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "13", Title: "Emin Emlak", Photo: "013.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "14", Title: "Akallar Kırtasiye", Photo: "014.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "15", Title: "Filizler", Photo: "015.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "16", Title: "Nispetiye Hukuk", Photo: "016.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "17", Title: "Hayla Grup", Photo: "017.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "18", Title: "MSGR", Photo: "018.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "19", Title: "MakineX", Photo: "019.webp", Target: "/referanslar" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Reference, { ID: "20", Title: "Sul Yapı", Photo: "020.webp", Target: "/referanslar" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { id: "main_Internet", className: "float-start w-100 p-0 m-0 p2z98p", style: { height: "300px"} },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Hope, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 s3zb07" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "header", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 a419o0" },
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "Google", Photo: "011.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "Instagram", Photo: "012.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "Youtube", Photo: "013.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "WhatsApp", Photo: "014.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "TikTok", Photo: "015.svg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:10 },
                            //Element
                            React.createElement( Tab, { ID: "0", Title: "Web Tasarım", Summary: "Profesyonel bir web tasarım ile müşteri tabanınızı genişleterek dijital varlığınızı güçlendirin ve müşteri kitlenizi genişletin.", Photo: "001.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "footer", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 a419o0" },
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "X", Photo: "016.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "Yahoo", Photo: "017.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "Threads", Photo: "018.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "Baidu", Photo: "019.svg", Target: "/" } ),
                                //Element
                                React.createElement( Brand, { ID: "0", Title: "Reddit", Photo: "020.svg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Features, {} )
                    )
                )
            ),
            //Element
            React.createElement( "section", { id: "main_Success", className: "float-start w-100 p-0 m-0 p2z98p", style: { height: "300px" } },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Design, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 m6747x" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Slogan, { ID: "React_B88U12", Title: "İlk İzlenim", Summary: "İnternet kullanıcıları, bir web sitesini ziyaret ettiklerinde ilk izlenime önem verir. Profesyonel bir tasarım, güveni artırarak işletmenizin ciddiyetini yansıtabilir.", Photo: "001.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Slogan, { ID: "React_Q1995G", Title: "Marka Kimliği", Summary: "Web tasarımı, işletmenizin marka kimliğini etkili bir şekilde yansıtan kritik bir unsurdur. Renk, logo konumu ve genel tasarım, marka tanıtımını güçlendirebilir.", Photo: "004.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Slogan, { ID: "React_T5390N", Title: "Rekabet Avantajı", Summary: "Profesyonel bir web sitesi, rakipler arasında sıyrılmanıza ve müşteri çekmenize katkı sağlar. Estetik ve işlevsellik, müşteri çekme konusunda önemli avantajlar sunar.", Photo: "002.svg", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { id: "main_Internet", className: "float-start w-100 p-0 m-0 p2z98p", style: { height: "350px" } },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Internet, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 g64my5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 m505cz" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "1", Title: "Meta", Photo: "009.svg", Target: "https://about.meta.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "2", Title: "Symantec", Photo: "007.svg", Target: "https://sep.securitycloud.symantec.com/v2/landing" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "3", Title: "BigCommerce", Photo: "002.svg", Target: "https://www.bigcommerce.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "4", Title: "GoDaddy", Photo: "005.svg", Target: "https://www.godaddy.com/tr-tr" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "5", Title: "Semrush", Photo: "001.svg", Target: "https://www.semrush.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "6", Title: "Workplace", Photo: "006.svg", Target: "https://www.workplace.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "7", Title: "Hotjar", Photo: "003.svg", Target: "https://www.hotjar.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "8", Title: "Cloudways", Photo: "004.svg", Target: "https://www.cloudways.com/en/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "9", Title: "Shutterstock", Photo: "008.svg", Target: "https://www.shutterstock.com/tr/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Partner, { ID: "10", Title: "HubSpot", Photo: "010.svg", Target: "https://www.hubspot.com/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;