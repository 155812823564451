//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Sales extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Mağaza satışlarını artırın - Benozgurtosun.com"
        //Element
        Description.content = "Çevrimiçi hedef kitlelere erişerek ve müşteri ilişkileri kurarak önemli müşterilere erişin, mağaza satışlarınızı artırın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/isletme-rehberligi/satislari-artirin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Mağaza satışlarını artırın - Benozgurtosun.com"
        //Element
        Description.content = "Çevrimiçi hedef kitlelere erişerek ve müşteri ilişkileri kurarak önemli müşterilere erişin, mağaza satışlarınızı artırın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/isletme-rehberligi/satislari-artirin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Mağaza satışlarını artırın - Benozgurtosun.com"
        //Element
        Description.content = "Çevrimiçi hedef kitlelere erişerek ve müşteri ilişkileri kurarak önemli müşterilere erişin, mağaza satışlarınızı artırın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi yansıtan, güçlü bir web tasarımı ile öne çıkın. İyi bir tasarım, dijital varlığınızı güçlendirerek müşterilerinizi etkiler."
            //Element
            Two.innerHTML         = "Dijital dünyada, markanızı çekici bir dijital vitrin ile sergileyin ve potansiyel müşterileri çekin."
            //Element
            Three.innerHTML       = "Rakiplerinizin önüne geçin! Güçlü bir web tasarım ile markanızı çekici kılın ve dijital alanda öne çıkın."
            //Element
            Description.innerHTML = "Mağaza satışlarınızı artırmak için, web tasarım ile dijital varlığınızı güçlendirin."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi yansıtan güçlü bir web tasarımla öne çıkın. İyi bir tasarım, dijital varlığınızı güçlendirerek müşterilerinizi etkiler."
            //Element
            Two.innerHTML         = "Dijital dünyada, markanızı çekici bir dijital vitrin ile sergileyin ve potansiyel müşterileri çekin."
            //Element
            Three.innerHTML       = "Rakiplerinizin önüne geçin! Güçlü bir web tasarım ile markanızı çekici kılın ve öne çıkın."
            //Element
            Description.innerHTML = "Mağaza satışlarınızı artırmak için, web tasarım ile dijital varlığınızı güçlendirin."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlü bir şekilde yansıtan, iyi bir web tasarımı, işletmenizi dijital dünyada ayırt edici kılar."
            //Element
            Two.innerHTML         = "İşletmeniz için şık bir çevrimiçi vitrin oluşturarak, dijital varlığınızı güçlendirin. Kurumsal bir web tasarım ile önemli müşterilere erişin."
            //Element
            Three.innerHTML       = "Sektörde öne çıkmak ve dikkat çekmek için benzersiz bir web tasarım, işletmenize belirgin bir rekabet avantajı sağlar."
            //Element
            Description.innerHTML = "Çevrimiçi hedef kitlelere erişerek ve müşteri ilişkileri kurarak mağaza satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlü bir şekilde yansıtan, iyi bir web tasarımı, işletmenizi dijital dünyada ayırt edici kılar."
            //Element
            Two.innerHTML         = "İşletmeniz için şık bir çevrimiçi vitrin oluşturarak, dijital varlığınızı güçlendirin. Kurumsal bir web tasarım ile önemli müşterilere erişin."
            //Element
            Three.innerHTML       = "Sektörde öne çıkmak ve dikkat çekmek için benzersiz bir web tasarım, işletmenize belirgin bir rekabet avantajı sağlar."
            //Element
            Description.innerHTML = "Çevrimiçi hedef kitlelere erişerek ve müşteri ilişkileri kurarak mağaza satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Markanızı yansıtan özel web tasarımı ile online dünyada fark yaratın. Estetik ve işlevselliği bir araya getirerek, özgün bir dijital deneyim sunuyoruz."
            //Element
            Two.innerHTML         = "İşinizi dijital dünyada parlatın! Kurumsal bir web tasarım ile etkileyici bir dijital vitrin yaratın, müşterilerinizi çekin ve markanızı çevrimiçi olarak güçlendirin."
            //Element
            Three.innerHTML       = "Rakiplerinizin önünde yer alın! Etkileyici web tasarımı ile şık ve etkili bir çevrimiçi varlık oluşturun, markanızı dijital dünyada lider konumuna taşıyın."
            //Element
            Description.innerHTML = "Çevrimiçi hedef kitlelere ulaşıp, müşteri ilişkileri kurarak müşterilere erişin ve mağaza satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
           One.innerHTML         = "Markanızı yansıtan iyi bir web tasarım ile dijital dünyada fark yaratın. Estetik ve işlevselliği bir araya getirerek, özgün bir dijital deneyim sunuyoruz."
           //Element
           Two.innerHTML         = "İşinizi dijital dünyada parlatın! Kurumsal bir web tasarımı ile etkileyici bir dijital vitrin yaratın, müşterilerinizi çekin ve markanızı çevrimiçi olarak güçlendirin."
           //Element
           Three.innerHTML       = "Rakiplerinizin önünde yer alın! Etkileyici web tasarımı ile şık ve etkili bir çevrimiçi varlık oluşturun, markanızı dijital dünyada lider konumuna taşıyın."
           //Element
           Description.innerHTML = "Çevrimiçi hedef kitlelere ulaşıp, müşteri ilişkileri kurarak mağaza satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
           //Element
           One.innerHTML         = "Markanızı yansıtan iyi bir web tasarım ile dijital dünyada fark yaratın. Estetik ve işlevselliği bir araya getirerek, özgün bir dijital deneyim sunuyoruz."
           //Element
           Two.innerHTML         = "İşinizi dijital dünyada parlatın! Kurumsal bir web tasarımı ile etkileyici bir dijital vitrin yaratın, müşterilerinizi çekin ve markanızı çevrimiçi olarak güçlendirin."
           //Element
           Three.innerHTML       = "Rakiplerinizin önünde yer alın! Etkileyici web tasarımı ile şık ve etkili bir çevrimiçi varlık oluşturun, markanızı dijital dünyada lider konumuna taşıyın."
           //Element
           Description.innerHTML = "Çevrimiçi hedef kitlelere ulaşıp, müşteri ilişkileri kurarak mağaza satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
           One.innerHTML         = "Markanızı yansıtan, iyi bir web tasarım ile dijital dünyada fark yaratın. Estetik ve işlevselliği bir araya getirerek, özgün bir dijital deneyim sunuyoruz."
           //Element
           Two.innerHTML         = "İşinizi dijital dünyada parlatın! Kurumsal bir web tasarımı ile etkileyici bir dijital vitrin yaratın, müşterilerinizi çekin ve markanızı çevrimiçi olarak güçlendirin."
           //Element
           Three.innerHTML       = "Rakiplerinizin önünde yer alın! Etkileyici web tasarımı ile şık, etkili bir dijital varlık oluşturun, markanızı online dünyada lider konumuna taşıyın."
           //Element
           Description.innerHTML = "Çevrimiçi hedef kitlelere ulaşıp, müşteri ilişkileri kurarak mağaza satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Özgün marka kimliğiniz için özel web tasarımı! İşinizi dijital dünya'da ön plana çıkarın, etkileyici bir çevrimiçi varlık oluşturalım!"
            //Element
            Two.innerHTML         = "İşinizi dijital dünyada öne çıkarmak için iyi bir web tasarımı tercih edin! Markanızı etkili bir dijital vitrinle çevrimiçi olarak parlatın."
            //Element
            Three.innerHTML       = "Rakiplerinizden sıyrılın! Özel bir web tasarımı ile, benzersiz bir çevrimiçi varlık oluşturun, çevrimiçi dünyada lider olmanın avantajını yaşayın."
            //Element
            Description.innerHTML = "Mağaza satışlarını artırmak için, web tasarım uzmanları ile işbirliği yapın! Modern, çekici ve kullanıcı dostu bir web sitesi ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Özgün marka kimliğiniz için özel web tasarımı! İşinizi dijital dünya'da ön plana çıkarın, etkileyici bir çevrimiçi varlık oluşturalım!"
            //Element
            Two.innerHTML         = "İşinizi dijital dünyada öne çıkarmak için iyi bir web tasarımı tercih edin! Markanızı etkili bir dijital vitrinle çevrimiçi olarak parlatın."
            //Element
            Three.innerHTML       = "Rakiplerinizden sıyrılın! Özel bir web tasarımı ile, benzersiz bir çevrimiçi varlık oluşturun, çevrimiçi dünyada lider olmanın avantajını yaşayın."
            //Element
            Description.innerHTML = "Mağaza satışlarınızı artırmak için web tasarım uzmanları ile işbirliği yapın! Modern, çekici ve kullanıcı dostu bir web sitesi ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Özgün marka kimliğiniz için özel web tasarımı! İşinizi çevrimiçi dünya'da ön plana çıkarın, etkileyici bir çevrimiçi varlık oluşturalım!"
           //Element
           Two.innerHTML         = "İşinizi online dünyada parlatmak için özel bir web site tasarımı kullanın! İyi bir dijital vitrin oluşturarak markanızı çevrimiçi olarak öne çıkarın."
           //Element
           Three.innerHTML       = "Rakiplerinizden sıyrılın! Özel bir web tasarımı ile benzersiz bir çevrimiçi varlık oluşturun, dijital dünyada lider olmanın avantajını yaşayın."
           //Element
           Description.innerHTML = "Mağaza satışlarınızı artırmak için web tasarım uzmanlarımızla işbirliği yapın! Modern, çekici ve kullanıcı dostu bir web sitesi ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Özgün marka kimliğiniz için özel web tasarımı! İşinizi online dünya'da ön plana çıkarın, etkileyici bir çevrimiçi varlık oluşturalım!"
            //Element
            Two.innerHTML         = "İşinizi online dünyada parlatmak için özel bir web tasarım kullanın! İyi bir dijital vitrin oluşturarak markanızı çevrimiçi olarak öne çıkarın."
            //Element
            Three.innerHTML       = "Rakiplerinizden sıyrılın! Özel web tasarımınızla benzersiz bir çevrimiçi varlık oluşturun, dijital dünyada lider olmanın avantajını yaşayın."
            //Element
            Description.innerHTML = "Mağaza satışlarınızı artırmak için web tasarım uzmanlarımızla işbirliği yapın! Modern, çekici ve kullanıcı dostu bir web sitesi ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Özgün marka kimliğiniz için özel bir web tasarım. İşinizi dijital dünyada ön plana çıkarın, etkileyici bir çevrimiçi varlık oluşturalım!"
            //Element
            Two.innerHTML         = "İşinizi dijital dünyada parlatın! Özel web tasarımımızla etkileyici bir dijital vitrin oluşturun, markanızı çevrimiçi olarak öne çıkarın."
            //Element
            Three.innerHTML       = "Rakipleriniz arasından sıyrılın! Özel web tasarımımızla benzersiz bir dijital varlık oluşturarak, işinizi dijital dünyada lider konumuna taşımanın avantajını yaşayın."
            //Element
            Description.innerHTML = "Web tasarım uzmanlarıyla çalışarak mağaza satışlarınızı artırın – modern, çekici ve kullanıcı dostu bir web sitesi ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Mağaza satışlarını artırın - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Çevrimiçi hedef kitlelere erişerek ve müşteri ilişkileri kurarak önemli müşterilere erişin, mağaza satışlarınızı artırın."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Mağaza satışlarını artırın.", itemProp: "name headline" },
                                    //Element
                                    "Mağaza ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "satışlarını"
                                    ),
                                    //Element
                                    " artırın."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 text-center u04m7q", itemProp: "description" },
                                    //Element
                                    "Çevrimiçi hedef kitlelere erişerek ve müşteri ilişkileri kurarak önemli müşterilere",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "erişin, mağaza satışlarınızı artırın."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Mağaza satışlarını artırın.", title: "Mağaza satışlarını artırın.", src: "https://cdn.benozgurtosun.com/v1/business/689981.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Marka Kimliğinizi Yansıtın", Summary: "Markanızı yansıtan özel web tasarımı ile online dünyada fark yaratın. Estetik ve işlevselliği bir araya getirerek, özgün bir dijital deneyim sunuyoruz.", Photo: "504217.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Dijital Vitrin Yaratın", Summary: "İşinizi dijital dünyada parlatın! Profesyonel bir web tasarım ile etkileyici bir dijital vitrin yaratın, müşterilerinizi çekin ve markanızı çevrimiçi olarak güçlendirin.", Photo: "964607.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Rakiplerinizden Öne Çıkın", Summary: "Rakiplerinizin önünde yer alın! Profesyonel web tasarımımızla şık ve etkili bir çevrimiçi varlık oluşturun, markanızı dijital dünyada lider konumuna taşıyın.", Photo: "816154.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Sales;