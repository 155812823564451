//Install
import React, { Component } from "react";
//Install
import Mark from "./Mark";

//Element
class Sub extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 d3726r" },
            //Element
            React.createElement( "h2", { className: "float-start w-auto p-0 m-0 mb-1 bg-transparent border-0 t4q297", title: "Müşteriler İle Daha Güçlü Bağlantılar Kurun.", itemProp: "alternateName" },
                //Element
                "Yeni ",
                //Element
                "Müşteriler İle Daha Güçlü ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Bağlantılar"
                ),
                //Element
                " Kurun."
            ),
            //Element
            React.createElement( "p", { id: "React_T922P1", className: "float-start w-auto p-0 m-0 mt-2 mb-2 o4e46u", itemProp: "text" },
                //Element
                "İnternette varlık oluşturmanız, işletmenizin bilinirliğini artırmanıza, daha fazla",
                //Element
                React.createElement( "br", {} ),
                //Element
                " müşteri bulmanıza ve hem internetten satışları hem de yerel satışları",
                //Element
                React.createElement( "br", {} ),
                //Element
                " artırmanıza yardımcı olabilir."
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 mt-3 t50h35" },
                //Element
                React.createElement( Mark, { ID: "0", Title: ".COM Domain İle İnternette Profesyonel Görünün" } ),
                //Element
                React.createElement( Mark, { ID: "0", Title: "Linux ve Windows Tabanlı Bedava Hosting" } ),
                //Element
                React.createElement( Mark, { ID: "0", Title: "Profesyoneller İçin e-Posta - @sirketiniz.com" } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Sub;