//Install
import React, { Component } from "react";

//Element
class Headline extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
            //Element
            React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Bugün 68 Farklı Şehir'de. 5000 Farklı İşletme.", itemProp: "name headline" },
                //Element
                "Bugün ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "68"
                ),
                //Element
                " Farklı Şehir'de. ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "5.000"
                ),
                //Element
                " Farklı İşletme."
            ),
            //Element
            React.createElement( "p", { id: "React_023OGK", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                //Element
                "Merhaba, Ben Özgür Tosun 7 Farklı Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "Geride Bıraktım."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Headline;