//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Information from "./Information";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:9 },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Genel Bilgilendirme" },
                                //Element
                                "Genel Bilgilendirme"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Kurumsal", href: "/kurumsal" , itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Kurumsal"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Manifesto", href: "/manifesto", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Manifesto"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Ücretlendirme", href: "/ucretlendirme", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Ücretlendirme"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Kimlere Hizmet Sunuldu?", href: "/referanslar", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Kimlere Hizmet Sunuldu?"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Kariyer Fırsatları", href: "/kariyer", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Kariyer Fırsatları"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Banka Hesabı Bilgileri", href: "/odeme", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Banka Hesabı Bilgileri"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Kişisel Verilen Korunması", href: "/kisisel-verilerin-korunmasi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Kişisel Verilen Korunması"
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Sunulan Hizmetler" },
                                //Element
                                "Sunulan Hizmetler"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Web Tasarım", href: "/hemen-ulasin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Web Tasarım"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Domain", href: "/e-isletme/domain", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Domain"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Hosting", href: "/e-isletme/hosting", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Hosting"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "SSL Sertifikaları", href: "/e-isletme/ssl", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "SSL Sertifikaları"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Kurumsal E-posta", href: "/e-isletme/e-posta", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Kurumsal E-posta"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Search Engine Optimization", href: "/e-isletme/seo", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Search Engine Optimization"
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Rekabet Programı" },
                                //Element
                                "Rekabet Programı"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Hızlı Tüketim Malları", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Hızlı Tüketim Malları"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Otomobil", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Otomobil"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "e-Ticaret", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "e-Ticaret"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Eğitim", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Eğitim"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Finansal Hizmetler", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Finansal Hizmetler"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Gayrimenkul", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Gayrimenkul"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Restoranlar", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Restoranlar"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Perakende", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Perakende"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Seyahat", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Seyahat"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Telekomünikasyon", href: "/rekabet-programi", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Telekomünikasyon"
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Maksimum Performans" },
                                //Element
                                "Maksimum Performans"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Küçük işletmeler", href: "/maksimum-performans/kucuk-isletmeler-icin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Küçük işletmeler"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Orta Ölçekli İşletmeler", href: "/maksimum-performans/orta-olcekli-isletmeler-icin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Orta Ölçekli İşletmeler"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Şirketler", href: "/maksimum-performans/sirketler-icin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Şirketler"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Yeni İşletmeler", href: "/maksimum-performans/yeni-isletmeler-icin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Yeni İşletmeler"
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Hemen Müşteri Edinin" },
                                //Element
                                "Hemen Müşteri Edinin"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 mb-5 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Bilinirliği Artırın", href: "/isletme-rehberligi/bilinirligi-artirin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Bilinirliği Artırın"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Satışları Artırın", href: "/isletme-rehberligi/satislari-artirin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Satışları Artırın"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Yeni müşteriler edinin", href: "/isletme-rehberligi/yeni-musteriler-edinin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Yeni müşteriler edinin"
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Reklam Danışmanlığı" },
                                //Element
                                "Reklam Danışmanlığı"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 mb-5 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Facebook", href: "/reklam-ver/facebook", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Facebook"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Google", href: "/reklam-ver/google", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Google"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Youtube", href: "/reklam-ver/youtube", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Youtube"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Yandex", href: "/reklam-ver/yandex", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Yandex"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Instagram", href: "/reklam-ver/instagram", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Instagram"
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Global Partner" },
                                //Element
                                "Global Partner"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 mb-5 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Amazon Web Services", href: "/partner", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Amazon Web Services"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Microsoft", href: "/partner", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Microsoft"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Adobe Creative Cloud", href: "/partner", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Adobe Creative Cloud"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Semrush", href: "/partner", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Semrush"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "Oracle", href: "/partner", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "Oracle"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "GoDaddy™", href: "/partner", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "GoDaddy™"
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n90bv9" },
                            //Element
                            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 a407p5", title: "Hemen Ulaşın" },
                                //Element
                                "Hemen Ulaşın"
                            ),
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 mb-5 r3Bx93", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100", title: "İletişim Bilgileri", href: "/hemen-ulasin", itemProp: "url" },
                                        //Element
                                        React.createElement( "span", { itemProp: "name" },
                                            //Element
                                            "İletişim Bilgileri"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( Information, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;