//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Count extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 mt-5 z9w60a" },
            //Element
            React.createElement( "label", { className: "float-start w-auto p-0 m-0 i745hd" },
                //Element
                Title
            ),
            //Element
            React.createElement( "span", { className: "float-start w-auto p-0 m-0 g30798" },
                //Element
                Summary
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Count.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Count;