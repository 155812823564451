//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Feature extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 pb-3 m-0 mt-3 br6312", itemScope: "itemScope", itemType: "https://schema.org/Guide" },
            //Element
            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 mt-3 mb-1 s63448", itemProp: "name alternateName", title: Title },
                //Element
                Title
            ),
            //Element
            React.createElement( "p", { id: ID, className: "float-start w-100 p-0 m-0 mt-1 v976lh", itemProp: "description" },
                //Element
                Summary
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Feature.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Feature;