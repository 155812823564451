//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Company extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/reference/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 z82455", title: Title, href: Target },
            //Element
            React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-2 m-0 o01a97", itemScope: "itemScope", itemType: "https://schema.org/ImageObject" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "110", height: "110", itemProp: "contentUrl" } ),
                //Element
                React.createElement( "meta", { itemProp: "name", content: Title } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Company.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Company;