//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let I, II, III
        //Element
        let URL   = "https://cdn.benozgurtosun.com/v1/competition/"
        //Element
        let Image = URL + Photo
        //Element
        if( ID === "01" ){
            //Element
            I   = "Araç Listeleme"
            //Element
            II  = "Filtreleme ve Arama"
            //Element
            III = "Otomobil Karşılaştırma"
        //Element
        } else if( ID === "02" ){
            //Element
            I   = "Projeler"
            //Element
            II  = "Proje İlerleme Durumu"
            //Element
            III = "Planlar ve Kat Planları"
        //Element
        } else if( ID === "03" ){
            //Element
            I   = "Randevu Alma Sistemi"
            //Element
            II  = "Hasta Bilgileri"
            //Element
            III = "Hasta Yorum ve İncelemeleri"
        //Element
        } else if( ID === "04" ){
            //Element
            I   = "e-Ticaret İmkanı"
            //Element
            II  = "Stok Durumu Göstergesi"
            //Element
            III = "Özelleştirme Seçenekleri"
        //Element
        } else if( ID === "05" ){
            //Element
            I   = "Tatil ve Tur Paketleri"
            //Element
            II  = "Online Rezervasyon"
            //Element
            III = "Destinasyon Rehberleri"
        //Element
        } else if( ID === "06" ){
            //Element
            I   = "Ürün Kataloğu"
            //Element
            II  = "e-Ticaret İmkanı"
            //Element
            III = "Ürün Arama ve Filtreleme"
        //Element
        } else if( ID === "07" ){
            //Element
            I   = "Mağaza Bulucu"
            //Element
            II  = "Tarım Ürün Kataloğu"
            //Element
            III = "İlaç ve Gübre Bilgileri"
        //Element
        } else if( ID === "08" ){
            //Element
            I   = "Mağaza Bulucu"
            //Element
            II  = "Sipariş Alma İşlevi"
            //Element
            III = "Ürün ve Ürün Katalogu"
        //Element
        } else if( ID === "09" ){
            //Element
            I   = "Ürün Kataloğu"
            //Element
            II  = "Fiyatlandırma ve İndirimler"
            //Element
            III = "Satın Alma veya Sipariş İşlevi"
        //Element
        } else if( ID === "10" ){
            //Element
            I   = "e-Ticaret İmkanı"
            //Element
            II  = "Ürün Arama ve Filtreleme"
            //Element
            III = "Fiyatlandırma ve İndirimler"
        //Element
        } else if( ID === "11" ){
            //Element
            I   = "Müşteri Girişi"
            //Element
            II  = "Fiyatlandırma Bilgileri"
            //Element
            III = "Çevrimiçi Takip ve İzleme"
        //Element
        } else if( ID === "12" ){
            //Element
            I   = "Çevrimiçi Başvuru"
            //Element
            II  = "Tarifeler ve Ücretler"
            //Element
            III = "Hesap ve İşlem Yönetimi"
        //Element
        } else if( ID === "13" ){
            //Element
            I   = "Sigorta Ürünleri"
            //Element
            II  = "Teklif Talep Formları"
            //Element
            III = "Sigorta Hesaplama Araçları"
        //Element
        } else if( ID === "14" ){
            //Element
            I   = "Emlak Listeleri"
            //Element
            II  = "Emlak Fiyat Endeksleri"
            //Element
            III = "Emlak Arama ve Filtreleme"
        //Element
        } else if( ID === "15" ){
            //Element
            I   = "Müşteri Hesapları"
            //Element
            II  = "Sipariş Takibi"
            //Element
            III = "Sepet ve Ödeme İşlemleri"
        //Element
        } else if( ID === "16" ){
            //Element
            I   = "İş Sektörleri"
            //Element
            II  = "Proje Talep Formları"
            //Element
            III = "Ürün ve Hizmet Kataloğu"
        //Element
        } else if( ID === "17" ){
            //Element
            I   = "Ürün Kataloğu"
            //Element
            II  = "Özel Teklifler"
            //Element
            III = "Ürün Filtreleme ve Arama"
        //Element
        } else if( ID === "18" ){
            //Element
            I   = "Menü"
            //Element
            II  = "Rezervasyon"
            //Element
            III = "Müşteri İncelemeleri"
        //Element
        } else if( ID === "19" ){
            //Element
            I   = "Etkinlik Takvimi"
            //Element
            II  = "Etkinlikler ve Programlar"
            //Element
            III = "Rezervasyon ve Bilet Satışı"
        //Element
        } else if( ID === "20" ){
            //Element
            I   = "Ürün Kataloğu"
            //Element
            II  = "Ürün Filtreleme ve Arama"
            //Element
            III = "Ürün Karşılaştırma"
        }
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-5 m-0 mb-5 t079x8" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 k96847" },
                //Element
                React.createElement( "picture", { className: "float-start w-auto p-0 m-0 f062xc", itemScope: "itemScope", itemType: "https://schema.org/ImageObject" },
                    //Element
                    React.createElement( "img", { alt: Title, title: Title, src: Image, width: "25", height: "25", itemProp: "contentUrl" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "name", content: Title } )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 pb-4 m-0 mt-4 mb-1 lh-1 r22m60" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 ps-3 m-0 mt-3 y8450e" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-start align-items-center p-0 ps-2 m-0" },
                            //Element
                            React.createElement( "strong", { className: "float-start w-auto p-0 m-0" },
                                //Element
                                I
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-start align-items-center p-0 ps-2 m-0" },
                            //Element
                            React.createElement( "strong", { className: "float-start w-auto p-0 m-0" },
                                //Element
                                II
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-start align-items-center p-0 ps-2 m-0" },
                            //Element
                            React.createElement( "strong", { className: "float-start w-auto p-0 m-0" },
                                //Element
                                III
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Default;