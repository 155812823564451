//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Youtube extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Youtube Ads : YouTube Reklamları İle İşinizi Büyütün - Benozgurtosun.com"
        //Element
        Description.content = "Önemli müşteriler YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/youtube"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Youtube Ads : YouTube Reklamları İle İşinizi Büyütün - Benozgurtosun.com"
        //Element
        Description.content = "Önemli müşteriler YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/youtube"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Youtube Ads : YouTube Reklamları İle İşinizi Büyütün - Benozgurtosun.com"
        //Element
        Description.content = "Önemli müşteriler YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Öne çıkın, markanızı binlere tanıtın! YouTube Ads ile hedef kitlenizin zihninde iz bırakın."
            //Element
            Two.innerHTML         = "Markanızı öne çıkarın ve müşteri kitlenizin ilgisini çekin. YouTube ile markanızı unutulmaz bir deneyimle sunun!"
            //Element
            Three.innerHTML       = "YouTube reklamları ile mağaza satışlarınızı artırın! Müşteri kitlenizi etkileyin ve işinizi öne çıkarın."
            //Element
            Description.innerHTML = "YouTube Ads aracılığıyla önemli müşterilere erişin Hemen başlayın ve etkili sonuçlar elde edin!"
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Öne çıkın ve markanızı binlere tanıtın! YouTube ile hedef kitlenizin zihninde iz bırakın."
            //Element
            Two.innerHTML         = "Markanızı öne çıkarın ve müşteri kitlenizin ilgisini çekin. YouTube ile markanızı unutulmaz bir deneyimle sunun!"
            //Element
            Three.innerHTML       = "YouTube reklamları ile mağaza satışlarınızı artırın! Müşteri kitlenizi etkileyin ve işinizi öne çıkarın."
            //Element
            Description.innerHTML = "YouTube Ads ile önemli müşterilere erişin. Hemen başlayın ve etkili sonuçlar elde edin!"
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "YouTube, Google verilerini kullanarak reklamları doğru zamanda doğru kişilerle buluşturur."
            //Element
            Two.innerHTML         = "İnternet üzerinde arama yapan çeşitli siteleri gezen ve video içerikleri tüketen müşterilere daha etkili bir şekilde ulaşın."
            //Element
            Three.innerHTML       = "YouTube Ads, müşterilerin işletmenizi tercih etmelerini kolaylaştırır. Daha fazla satış yapın, abone olan ve web sitenizi ziyaret eden kullanıcı sayısını artırın."
            //Element
            Description.innerHTML = "Önemli müşteriler, YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun ve sonuç almaya başlayın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "YouTube Google verilerini kullanarak reklamlarınızı doğru zamanda doğru kişilerle buluşturur."
            //Element
            Two.innerHTML         = "İnternet'te arama yapan, çeşitli siteleri gezen veya video içerikleri tüketen müşterilere etkili bir şekilde ulaşın."
            //Element
            Three.innerHTML       = "YouTube, müşterilerin işletmenizi tercih etme kararlarını kolaylaştırarak daha fazla satış yapmanıza ve abone olan, kullanıcı sayısını artırmanıza yardımcı olur."
            //Element
            Description.innerHTML = "YouTube'da video izleyen önemli müşterilere hızla ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "YouTube Ads, Google verilerini kullanarak reklamlarınızı doğru zamanda doğru kişilerle buluşturur."
            //Element
            Two.innerHTML         = "İnternet'te arama yapan çeşitli siteleri gezen veya video içerikleri tüketen önemli müşterilere daha etkili bir şekilde ulaşın."
            //Element
            Three.innerHTML       = "YouTube, müşterilerin işletmenizi tercih etmelerini daha da kolaylaştırır. Daha fazla satış yapın, abone olan ve web sitenizi ziyaret eden kullanıcı sayısını artırın."
            //Element
            Description.innerHTML = "Yeni müşteriler YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "YouTube Ads : Google verilerini kullanarak reklamlarınızı doğru zamanda doğru kişilerle buluşturur."
            //Element
            Two.innerHTML         = "İnternet'te arama yapan çeşitli siteleri gezen veya video içerikleri tüketen önemli müşterilere daha etkili bir şekilde ulaşın."
            //Element
            Three.innerHTML       = "YouTube : Müşterilerin işletmenizi tercih etmelerini daha da kolaylaştırır. Daha fazla satış yapın, abone olan ve web sitenizi ziyaret eden kullanıcı sayısını artırın."
            //Element
            Description.innerHTML = "Yeni müşteriler, YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "YouTube Ads : Google verilerini kullanarak reklamlarınızı doğru zamanda doğru kişilerle buluşturur."
            //Element
            Two.innerHTML         = "İnternet'te arama yapan çeşitli siteleri gezen veya video içerikleri tüketen önemli müşterilere daha etkili bir şekilde ulaşın."
            //Element
            Three.innerHTML       = "YouTube : Müşterilerin işletmenizi tercih etmelerini daha da kolaylaştırır. Daha fazla satış yapın, abone olan ve web sitenizi ziyaret eden kullanıcı sayısını artırın."
            //Element
            Description.innerHTML = "Yeni müşteriler, YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "YouTube Ads : Google verilerini kullanarak, reklamlarınızı doğru zamanda doğru kişilerle buluşturur."
            //Element
            Two.innerHTML         = "İnternet'te arama yapan, çeşitli siteleri gezen veya video içerikleri tüketen önemli müşterilere daha etkili bir şekilde ulaşın."
            //Element
            Three.innerHTML       = "YouTube : Müşterilerin işletmenizi tercih etmelerini daha da kolaylaştırır. Daha fazla satış yapın, abone olan ve web sitenizi ziyaret eden kullanıcı sayısını artırın."
            //Element
            Description.innerHTML = "Yeni müşteriler, YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Öne çıkın, markanızı milyonlara tanıtın! YouTube reklamları ile hedef kitlenizin zihninde iz bırakın."
            //Element
            Two.innerHTML         = "Öne çıkın hedef kitlenizin dikkatini çekin. YouTube reklamı ile markanızı özel bir deneyimle tanıtın!"
            //Element
            Three.innerHTML       = "YouTube reklamları ile mağaza satışlarınızı artırın! Müşteri kitlenizi etkileyen reklamlarla işinizi öne çıkarın."
            //Element
            Description.innerHTML = "Potansiyel müşterilere YouTube videoları ile ulaşın. Hemen başlayın ve etkili sonuçlar elde edin!"
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Öne çıkın, markanızı milyonlara tanıtın! YouTube reklamları ile hedef kitlenizin zihninde iz bırakın."
            //Element
            Two.innerHTML         = "Öne çıkın hedef kitlenizin dikkatini çekin. YouTube reklamı ile markanızı özel bir deneyimle tanıtın!"
            //Element
            Three.innerHTML       = "YouTube reklamları ile mağaza satışlarınızı artırın! Hedef kitlenizi etkileyen reklamlarla işinizi öne çıkarın."
            //Element
            Description.innerHTML = "Potansiyel müşterilere YouTube videoları ile ulaşın. Hemen başlayın ve etkili sonuçlar elde edin!"
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Öne çıkın, markanızı milyonlara tanıtın! YouTube reklamları ile hedef kitlenizin zihninde iz bırakın."
           //Element
           Two.innerHTML         = "Öne çıkın, hedef kitlenizin dikkatini çekin. YouTube reklamları ile markanızı özel bir deneyimle tanıtın!"
           //Element
           Three.innerHTML       = "Mağaza satışlarınızı artırın, YouTube'da güçlü bir etki yaratın! Reklamlarınızla hedef kitlenizi etkileyin ve işinizi öne çıkarın."
           //Element
           Description.innerHTML = "Yeni müşterilere YouTube videoları ile ulaşın. Hemen başlayın ve etkili sonuçlar elde edin!"
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Markanızı unutulmaz kılın! YouTube reklamlarıyla hedef kitlenizin önemli anlarında yer alın. Etkileyici içeriklerle fark yaratın, potansiyel müşterilerinizi etkileyin!"
            //Element
            Two.innerHTML         = "Markanızı ön plana çıkarmak için en etkili yöntemlerden biri YouTube reklamlarıdır. Etkileyici içeriklerle hedef kitlenizin dikkatini çekerek markanızı önemli müşterilere duyurun."
            //Element
            Three.innerHTML       = "Mağaza satışlarınızı zirveye taşımanın en etkili yolu: YouTube reklamlarıyla! Ürünlerinizi ilgi çekici videolarla tanıtarak geniş kitlelere ulaşın, satışlarınızı hızla artırın!"
            //Element
            Description.innerHTML = "Potansiyel müşterilere YouTube videolarıyla ulaşın. Hemen başlayın ve etkili sonuçlar elde edin!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Markanızı unutulmaz kılın! YouTube reklamları ile önemli anlarda hedef kitlenizin karşısında olun. Etkileyici içeriklerle fark yaratın, potansiyel müşterilerinizi önemli anlarda etkileyin!"
            //Element
            Two.innerHTML         = "Markanızı önemli müşterilere duyurmanın en güçlü yolu: YouTube reklamları! Etkileyici içeriklerle öne çıkın, hedef kitlenizin dikkatini çekin ve işinizi değerli müşterilere tanıtarak büyütün!"
            //Element
            Three.innerHTML       = "Mağaza satışlarınızı zirveye taşımanın en etkili yolu: YouTube reklamları! Ürünlerinizi dinamik ve ilgi çekici videolarla tanıtarak geniş kitlelere ulaşın, satışlarınızı hızla artırın!"
            //Element
            Description.innerHTML = "Potansiyel müşteri kitlenize YouTube'da video izlerken ulaşın. Hemen başlayın ve etkili sonuçlar elde edin."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Youtube Ads : YouTube Reklamları İle İşinizi Büyütün - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Önemli müşteriler YouTube'da video izlerken onlara ulaşın. Hemen işe koyulun, sonuç almaya başlayın."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "YouTube Reklamları İle İşinizi Büyütün.", itemProp: "name headline" },
                                    //Element
                                    "YouTube Reklamları İle ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "İşinizi"
                                    ),
                                    //Element
                                    " Büyütün."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Önemli müşteriler YouTube'da video izlerken onlara ulaşın. Hemen",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "işe koyulun, sonuç almaya başlayın."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "YouTube Reklamları İle İşinizi Büyütün.", title: "YouTube Reklamları İle İşinizi Büyütün.", src: "https://cdn.benozgurtosun.com/v1/business/726746.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Önemli Anlarda Görünün.", Summary: "YouTube Reklamlar, Google verilerini kullanarak reklamlarınızı doğru zamanda doğru kişilerle buluşturur.", Photo: "644311.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Önemli Müşterilere Görünün.", Summary: "İnternet üzerinde arama yapan, çeşitli siteleri gezen ve video içerikleri tüketen önemli müşterilere daha etkili bir şekilde ulaşarak, markanızın görünürlüğünü genişletin.", Photo: "229197.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Mağaza Satışlarını Artırın.", Summary: "YouTube reklamları, müşterilerin işletmenizi tercih etmesini kolaylaştırır. Daha fazla satış yapın, abone olan ve web sitenizi ziyaret eden kullanıcı sayısını artırın.", Photo: "832338.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Youtube;