//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Facebook } from "../../Media/Social/001.svg";
//Install
import { ReactComponent as Twitter } from "../../Media/Social/002.svg";
//Install
import { ReactComponent as Instagram } from "../../Media/Social/003.svg";
//Install
import { ReactComponent as Youtube } from "../../Media/Social/004.svg";
//Install
import { ReactComponent as GitHub } from "../../Media/Social/005.svg";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 s955un" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 rounded-circle", href: "/", role: "dialog", "aria-label": "Facebook", title: "Facebook", target: "_blank", },
                    //Element
                    React.createElement( Facebook, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-4 rounded-circle", href: "/", role: "dialog", "aria-label": "Twitter", title: "Twitter", target: "_blank" },
                    //Element
                    React.createElement( Twitter, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-4 rounded-circle", href: "/", role: "dialog", "aria-label": "Instagram", title: "Instagram", target: "_blank", },
                    //Element
                    React.createElement( Instagram, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-4 rounded-circle", href: "/", role: "dialog", "aria-label": "YouTube", title: "YouTube", target: "_blank", },
                    //Element
                    React.createElement( Youtube, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-4 rounded-circle", href: "/", role: "dialog", "aria-label": "GitHub", title: "GitHub", target: "_blank", },
                    //Element
                    React.createElement( GitHub, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Social;