//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Mark } from "../../Media/Icon/003.svg";

//Element
class Sub extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 h4s606" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 mt-4 po7670" },
                //Element
                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 bg-transparent border-0 q35h02", title: "Müşteriler İle Daha Güçlü Bağlantılar Kurun.", itemProp: "name alternateName" },
                    //Element
                    "Müşteriler İle Daha Güçlü ",
                    //Element
                    React.createElement( "strong", {},
                        //Element
                        "Bağlantılar"
                    ),
                    //Element
                    " Kurun."
                ),
                //Element
                React.createElement( "p", { id: "React_S97S23", className: "float-start w-auto p-0 m-0 mt-3 w000w8", itemProp: "description" },
                    //Element
                    "İnternette varlık oluşturmanız, işletmenizin bilinirliğini artırmanıza, daha fazla ",
                    //Element
                    React.createElement( "br", {} ),
                    //Element
                    "müşteri bulmanıza ve hem internetten satışları hem de yerel satışları ",
                    //Element
                    React.createElement( "br", {} ),
                    //Element
                    "artırmanıza yardımcı olabilir."
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 g729kz" },
                    //Element
                    React.createElement( "ul", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 y15u5Q" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "span", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 mt-4 mb-1", title: ".COM Domain İle İnternette Profesyonel Görünün" },
                                //Element
                                React.createElement( "i", { className: "d-flex justify-content-center align-items-center p-0 m-0" },
                                    //Element
                                    React.createElement( Mark, {} )
                                ),
                                //Element
                                React.createElement( "strong", { className: "float-start w-auto p-0 ps-1 m-0 ms-2", itemProp: "item" },
                                    //Element
                                    ".COM Domain İle İnternette Profesyonel Görünün"
                                )
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "span", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 mt-3 mb-1", title: "Linux ve Windows Tabanlı Bedava Hosting" },
                                //Element
                                React.createElement( "i", { className: "d-flex justify-content-center align-items-center p-0 m-0" },
                                    //Element
                                    React.createElement( Mark, {} )
                                ),
                                //Element
                                React.createElement( "strong", { className: "float-start w-auto p-0 ps-1 m-0 ms-2", itemProp: "item" },
                                    //Element
                                    "Linux ve Windows Tabanlı Bedava Hosting"
                                )
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "span", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 mt-3", title: "Profesyoneller İçin e-Posta - @sirketiniz.com" },
                                //Element
                                React.createElement( "i", { className: "d-flex justify-content-center align-items-center p-0 m-0" },
                                    //Element
                                    React.createElement( Mark, {} )
                                ),
                                //Element
                                React.createElement( "strong", { className: "float-start w-auto p-0 ps-1 m-0 ms-2", itemProp: "item" },
                                    //Element
                                    "Profesyoneller İçin e-Posta - @sirketiniz.com"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Sub;