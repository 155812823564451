//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Phone } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as Email } from "../../Media/Icon/002.svg";

//Element
class Information extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 r46f3s" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o9l44l" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 w84z88", title: "Kurumsal Bilgilendirme" },
                    //Element
                    "Kurumsal Bilgilendirme"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 w8M19n" } ),
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 n959k8", href: "tel:+905349204144" },
                    //Element
                    React.createElement( Phone, {} ),
                    //Element
                    "+90 534 920 41 44"
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o9l44l" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 w84z88", title: "Hemen Bilgi Edinin" },
                    //Element
                    "Hemen Bilgi Edinin"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 q317to" } ),
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 m176ye", href: "mailto:info@benozgurtosun.com" },
                    //Element
                    React.createElement( Email, {} ),
                    //Element
                    "info@benozgurtosun.com"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Information;