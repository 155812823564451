//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class New extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Yeni müşteriler edinin - Benozgurtosun.com"
        //Element
        Description.content = "Çevrimiçi bir işletme ile yeni müşteriler edinin, müşteri tabanınızı genişletin ve önemli müşteri ilişkileri kurun."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/isletme-rehberligi/yeni-musteriler-edinin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Yeni müşteriler edinin - Benozgurtosun.com"
        //Element
        Description.content = "Çevrimiçi bir işletme ile yeni müşteriler edinin, müşteri tabanınızı genişletin ve önemli müşteri ilişkileri kurun."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/isletme-rehberligi/yeni-musteriler-edinin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Yeni müşteriler edinin - Benozgurtosun.com"
        //Element
        Description.content = "Çevrimiçi bir işletme ile yeni müşteriler edinin, müşteri tabanınızı genişletin ve önemli müşteri ilişkileri kurun."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "İlk izlenim önemlidir. Profesyonel bir web tasarım, işletmenizi güvenilir ve kaliteli kılar."
            //Element
            Two.innerHTML         = "Çoğu kişi mobil cihazlarla internete erişiyor. Mobil uyumlu bir site, müşterilere her yerden erişim sağlar."
            //Element
            Three.innerHTML       = "İyi bir web tasarım işletmenizi rakiplerinizden öne çıkarabilir. Modern bir tasarım ile müşterilerin zihinlerinde yer edinebilirsiniz."
            //Element
            Description.innerHTML = "Benzersiz bir web tasarımı ile, önemli müşterilere ulaşın ve markanızı güçlendirin."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "İlk izlenim önemlidir. Profesyonel bir web tasarım, işletmenizi güvenilir ve kaliteli kılar."
            //Element
            Two.innerHTML         = "Çoğu kişi mobil cihazlarla internete erişiyor. Mobil uyumlu bir site, müşterilere her yerden erişim sağlar."
            //Element
            Three.innerHTML       = "İyi bir web tasarım işletmenizi rakiplerinizden öne çıkarabilir. Modern bir tasarım ile müşterilerin zihinlerinde yer edinebilirsiniz."
            //Element
            Description.innerHTML = "Benzersiz bir web tasarımı ile, önemli müşterilere ulaşın ve markanızı güçlendirin."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "İlk izlenimler kalıcıdır. Profesyonel bir web tasarımı ile işletmenizin dijital yüzünü güçlendirin ve markanızı unutulmaz kılın."
            //Element
            Two.innerHTML         = "Mobil dostu bir tasarım ile herkesin cebinde olun. Hızlı ve kullanıcı dostu bir web tasarım ile işletmenizi her an ve her yerde gösterin."
            //Element
            Three.innerHTML       = "Rakiplerinizi geride bırakın ve öne çıkın! Benzersiz ve etkileyici bir web tasarım ile çevrimiçi varlığınızı güçlendirin."
            //Element
            Description.innerHTML = "Dijital bir işletmeyle önemli müşteriler çekin, müşteri tabanını genişletin ve müşteri ilişkileri kurun."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "İlk izlenimler kalıcıdır. Profesyonel bir web tasarımı ile işletmenizin dijital yüzünü güçlendirin ve markanızı unutulmaz kılın."
            //Element
            Two.innerHTML         = "Mobil dostu bir tasarım ile herkesin cebinde olun. Hızlı ve kullanıcı dostu bir web tasarım ile işletmenizi her an ve her yerde gösterin."
            //Element
            Three.innerHTML       = "Rakiplerinizi geride bırakın ve öne çıkın! Benzersiz ve etkileyici bir web tasarım ile çevrimiçi varlığınızı güçlendirin."
            //Element
            Description.innerHTML = "Dijital bir işletmeyle önemli müşteriler çekin, müşteri tabanını genişletin ve müşteri ilişkileri kurun."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "İlk kez giren müşterilere iyi bir izlenim vermek önemlidir. Kurumsal ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir."
            //Element
            Two.innerHTML         = "Bugün, birçok insan internete mobil cihazları kullanarak bağlanmaktadır. Sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Profesyonel bir web tasarımı, işletmenizi rakiplerinizden öne çıkarabilir. Kurumsal bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur."
            //Element
            Description.innerHTML = "Çevrimiçi bir işletme aracılığıyla, değerli müşteriler elde edin, müşteri tabanını genişletin ve müşteri ilişkileri kurun."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "İlk kez giren müşterilere iyi bir izlenim vermek önemlidir. Kurumsal ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir."
            //Element
            Two.innerHTML         = "Bugün, bir çok insan internete mobil cihazları kullanarak bağlanmaktadır. Sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Profesyonel bir web tasarımı, işletmenizi rakiplerinizden öne çıkarabilir. Kurumsal bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur."
            //Element
            Description.innerHTML = "Dijital bir işletme aracılığıyla önemli müşteriler elde edin müşteri tabanını genişletin ve ilişkiler kurun."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "İlk kez giren müşterilere iyi bir izlenim vermek önemlidir. Kurumsal ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir."
            //Element
            Two.innerHTML         = "Bugün, bir çok insan internete mobil cihazları kullanarak bağlanmaktadır. Sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Profesyonel bir web tasarımı, işletmenizi rakiplerinizden öne çıkarabilir. Kurumsal bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur."
            //Element
            Description.innerHTML = "Dijital bir işletme aracılığıyla önemli müşteriler elde edin, müşteri tabanını genişletin ve ilişkiler kurun."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "İlk kez giren müşterilere iyi bir izlenim vermek önemlidir. Kurumsal ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir."
            //Element
            Two.innerHTML         = "Bugün, bir çok insan internete mobil cihazları kullanarak bağlanmaktadır. Sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Profesyonel bir web tasarımı, işletmenizi rakiplerinizden öne çıkarabilir. Kurumsal bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur."
            //Element
            Description.innerHTML = "Dijital bir işletme aracılığıyla önemli müşteriler elde edin, müşteri tabanını genişletin ve ilişkiler kurun."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "İlk kez giren ziyaretçilere, iyi bir izlenim vermek önemlidir. Profesyonel ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir."
            //Element
            Two.innerHTML         = "Günümüzde birçok kişi internete, mobil cihazlar üzerinden erişim sağlamaktadır. Web sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Benzersiz bir web tasarım, işletmenizi rakiplerinizden öne çıkarabilir. Benzersiz ve çekici bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur."
            //Element
            Description.innerHTML = "Benzersiz bir web tasarım ile önemli müşterilere ulaşın ve markanızı güçlendirin. Dijital varlığınızı etkili bir şekilde yöneterek, işinizi dijital dünyada öne çıkarın."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "İlk kez giren ziyaretçilere, iyi bir izlenim vermek önemlidir. Profesyonel ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir."
            //Element
            Two.innerHTML         = "Bugün, pek çok birey mobil cihazları aracılığıyla internete bağlanmaktadır. Web sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Etkileyici bir web tasarım, işletmenizi rakiplerinizden öne çıkarabilir. Benzersiz ve çekici bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur."
            //Element
            Description.innerHTML = "Benzersiz bir web site tasarımı ile önemli müşterilere ulaşın ve markanızı güçlendirin. Dijital varlığınızı etkili bir şekilde yöneterek, işinizi dijital dünyada öne çıkarın."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "İlk kez giren ziyaretçilere, harika bir izlenim vermek önemlidir. Profesyonel ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir."
           //Element
           Two.innerHTML         = "Çağımızda, pek çok birey mobil cihazları aracılığıyla internete bağlanmaktadır. Web sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar."
           //Element
           Three.innerHTML       = "İyi bir web tasarımı, işletmenizi rakiplerinizden öne çıkarabilir. Benzersiz ve çekici bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur."
           //Element
           Description.innerHTML = "Profesyonel bir web site tasarımı ile önemli müşterilere ulaşın ve markanızı güçlendirin. Dijital varlığınızı etkili bir şekilde yöneterek, işinizi dijital dünyada öne çıkarın."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "İlk izlenimi güçlendirmek için profesyonel web tasarımıyla markanıza etkileyici bir çevrimiçi varlık kazandırın!"
            //Element
            Two.innerHTML         = "İnsanlar mobil cihazlarla internete bağlanıyor. Mobil uyumlu internet sitenizle her yerden potansiyel müşterilere ulaşın!"
            //Element
            Three.innerHTML       = "Profesyonel web tasarımı, işletmenizi öne çıkarır. Özgün ve ilgi çekici tasarım, müşterilerin aklında kalmanıza yardımcı olur, rekabet avantajınızı güçlendirir."
            //Element
            Description.innerHTML = "Müşterilerinizi çekmek ve markanızı güçlendirmek için web tasarım hizmetimizle etkileyici bir çevrimiçi varlık oluşturalım!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "İlk izlenimi güçlendirmek için profesyonel bir web tasarım ile markanıza etkileyici bir çevrimiçi varlık kazandırın!"
            //Element
            Two.innerHTML         = "Günümüzde insanlar, mobil cihazları kullanarak internete bağlanmaktadır. Web sitenizin mobil uyumlu olması, potansiyel müşterilere herhangi bir yerden erişim sağlayarak önemli bir avantaj sunar."
            //Element
            Three.innerHTML       = "Kaliteli bir web tasarımı, işletmenizi rakiplerinizden ayırt edebilir. Özgün ve ilgi çekici bir tasarım, müşterilerin akıllarında kalmanıza katkıda bulunarak rekabet avantajınızı güçlendirebilir."
            //Element
            Description.innerHTML = "Müşterilerinizi çekmek ve markanızı güçlendirmek için özel web tasarım hizmetimizle etkileyici bir çevrimiçi varlık oluşturalım!"
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Yeni müşteriler edinin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Çevrimiçi bir işletme ile yeni müşteriler edinin, müşteri tabanınızı genişletin ve önemli müşteri ilişkileri kurun."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Yeni müşteriler edinin.", itemProp: "name headline" },
                                    //Element
                                    "Yeni ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "müşteriler"
                                    ),
                                    //Element
                                    " edinin."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 text-center u04m7q", itemProp: "description" },
                                    //Element
                                    "Çevrimiçi bir işletme ile yeni müşteriler edinin, müşteri tabanınızı genişletin ve",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "önemli müşteri ilişkileri kurun."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Yeni müşteriler edinin.", title: "Yeni müşteriler edinin.", src: "https://cdn.benozgurtosun.com/v1/business/296131.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "İlk İzlenim Önemlidir", Summary: "İlk kez giren ziyaretçilere harika bir izlenim vermek önemlidir. Profesyonel ve çekici bir tasarım, işletmenizin güvenilir ve kaliteli olduğu izlenimini oluşturabilir.", Photo: "451249.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Mobil Dostu Tasarım", Summary: "Çağımızda, pek çok birey mobil cihazları aracılığıyla internete bağlanmaktadır. Web sitenizin mobil uyumlu olması, önemli müşterilere her yerden erişim imkanı sunar.", Photo: "961454.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Rekabet Avantajı", Summary: "Profesyonel bir web tasarımı, işletmenizi rakiplerinizden öne çıkarabilir. Benzersiz ve çekici bir tasarım, müşterilerin zihinlerinde yer edinmenize yardımcı olur.", Photo: "726494.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default New;