//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Check } from "../../../Media/Icon/006.svg";
//Install
import propTypes from "prop-types";

//Element
class Standart extends Component {
    //Element
    Target = () => {
        //Element
        window.location.href = "/on-bilgilendirme-talebi"
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Price, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-center p-0 pb-5 m-0 d540uf", itemScope: "itemScope", itemType: "https://schema.org/Product" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-5 pb-0 m-0 d01z5h" },
                //Element
                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 mt-2 v406f3", itemProp: "name" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 mb-1 text-center r74wm6", itemProp: "description" },
                    //Element
                    Summary
                ),
                //Element
                React.createElement( "meta", { itemProp: "image", content: "https://cdn.benozgurtosun.com/brand/000.svg" } ),
                //Element
                React.createElement( "div", { itemProp: "aggregateRating", itemScope: "itemScope", itemType: "https://schema.org/AggregateRating" },
                    //Element
                    React.createElement( "meta", { itemProp: "reviewCount", content: "1383" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "ratingValue", content: "5.0" } ),
                ),
                //Element
                React.createElement( "div", { itemProp: "review", itemScope: "itemScope", itemType: "https://schema.org/Review" },
                    //Element
                    React.createElement( "div", { itemProp: "author", itemScope: "itemScope", itemType: "https://schema.org/Person" },
                        //Element
                        React.createElement( "meta", { itemProp: "name", content: "Özgür Tosun" } )
                    )
                ),
                //Element
                React.createElement( "div", { itemProp: "offers", itemScope: "itemScope", itemType: "https://schema.org/Offer" },
                    //Element
                    React.createElement( "meta", { itemProp: "url", content: "https://benozgurtosun.com/ucretlendirme" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "availability", content: "https://schema.org/InStock" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "priceCurrency", content: "TRY" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "itemCondition", content: "https://schema.org/UsedCondition" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "price", content: "8.000" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "priceValidUntil", content: "2030-01-01" } )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-2 mb-1 text-center w432xg" },
                    //Element
                    Price
                ),
                //Element
                React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-3 w67h3x", type: "button", onClick: this.Target },
                    //Element
                    "Hemen Bilgi Edin"
                )
            ),
            //Element
            React.createElement( "ul", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-5 pb-0 m-0 u4025u" },
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "Bedava Domain"
                    )
                ),
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "Bedava Hosting"
                    )
                ),
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "Bedava SSL Sertifikası"
                    )
                ),
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "Mobil Optimizasyon"
                    )
                ),
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "Google Analytics"
                    )
                ),
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "Ücretsiz Temel SEO"
                    )
                ),
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "7/24 Müşteri Desteği"
                    )
                ),
                //Element
                React.createElement( "li", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-1" },
                    //Element
                    React.createElement( "label", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0" },
                        //Element
                        React.createElement( Check, {} )
                    ),
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" },
                        //Element
                        "Profesyonel Posta Kutusu"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Standart.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Price   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Standart;