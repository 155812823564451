//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    Target = () => {
        //Element
        window.location.href = "/on-bilgilendirme-talebi"
    }
    //Element
    Responsive = () => {
        //Element
        let Summary = document.querySelector( "#React_L3C08Z" )
        //Element
        if( window.innerWidth >= "1366" && window.innerWidth <= "1367" ){
            //Element
            Summary.innerHTML = "Neye ihtiyacınız olduğundan hâlâ emin değil misiniz? Hemen arayın. Müşterim olmasanız bile yardımcı olurum."
        //Element
        } else if(  window.innerWidth >= "1280" && window.innerWidth <= "1281" ){
            //Element
            Summary.innerHTML = "Neye ihtiyacınız olduğundan hâlâ emin değil misiniz? Hemen arayın. Müşterim olmasanız bile yardımcı olurum."
        //Element
        } else if(  window.innerWidth >= "801" && window.innerWidth <= "991.2" ){
            //Element
            Summary.innerHTML = "Neye ihtiyacınız olduğundan hâlâ emin değil misiniz? Hemen arayın. Müşterim olmasanız bile yardımcı olurum."
        //Element
        } else if(  window.innerWidth >= "768" && window.innerWidth <= "800" ){
            //Element
            Summary.innerHTML = "Neye ihtiyacınız olduğundan hâlâ emin değil misiniz? Hemen arayın. Müşterim olmasanız bile yardımcı olurum."
        }
    }
    //Element
    componentDidMount(){
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary } = this.props
        //Element
        let Source = "https://benozgurtosun.s3.eu-north-1.amazonaws.com/video/000.mp4"
        //Element
        const Content = React.createElement( Container, {},
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 q35322", itemScope: "itemScope", itemType: "https://schema.org/VideoObject" },
                        //Element
                        React.createElement( "div", { className: "d-flex flex-row-reverse align-items-center p796s4" },
                            //Element
                            React.createElement( "div", { className: "d-inline-block position-relative overflow-hidden u61409" },
                                //Element
                                React.createElement( "video", { playsInline: "playsinline", className: "float-start w-100 h-auto", src: Source, controls: "controls", loop: true, autoPlay: true, muted: "muted", itemProp: "video" } )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex flex-column justify-content-center align-items-start position-relative q76j70" },
                                //Element
                                React.createElement( "h6", { id: "React_A294VI", className: "float-start w-auto p-0 m-0 mb-1 q20g3c", itemProp: "name alternateName" },
                                    //Element
                                    Title
                                ),
                                //Element
                                React.createElement( "p", { id: "React_L3C08Z", className: "float-start w-auto p-0 m-0 mt-2 mb-1 d624lk", itemProp: "description" },
                                    //Element
                                    Summary
                                ),
                                //Element
                                React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 mt-3 d7fv32", type: "button", onClick: this.Target },
                                    //Element
                                    "Ön Bilgilendirme Talebi"
                                ),
                                //Element
                                React.createElement( "meta", { itemProp: "url", content: "https://benozgurtosun.com/on-bilgilendirme-talebi" } )
                            )
                        ),
                        //Element
                        React.createElement( "meta", { itemProp: "uploadDate", content: "2024-01-01T08:00:00+08:00" } ),
                        //Element
                        React.createElement( "meta", { itemProp: "thumbnailUrl", content: "https://cdn.benozgurtosun.com/thumbnailUrl/002.png" } ),
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired
}
//Element
export default Default;