//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Google extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Google Ads : Google Reklamları İle Yeni Müşteriler Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Daha fazla satış, trafik ve marka bilinirliği için Google hedeflerinize yardımcı olur. Potansiyel müşterilere Google Arama sonuçlarının en üstünde görünün."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/google"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Google Ads : Google Reklamları İle Yeni Müşteriler Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Daha fazla satış, trafik ve marka bilinirliği için Google hedeflerinize yardımcı olur. Potansiyel müşterilere Google Arama sonuçlarının en üstünde görünün."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/google"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Google Ads : Google Reklamları İle Yeni Müşteriler Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Daha fazla satış, trafik ve marka bilinirliği için Google hedeflerinize yardımcı olur. Potansiyel müşterilere Google Arama sonuçlarının en üstünde görünün."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Önemli müşterilere etkili bir şekilde ulaşın! Şimdi reklam verin ve markanızı Google'da parlatın!"
            //Element
            Two.innerHTML         = "Google ile işletmenizi bulunabilir hale getirin! Yeni müşterilere kolayca ulaşmak ve markanızı ön plana çıkarmak için şimdi reklam verin."
            //Element
            Three.innerHTML       = "Google ile, satışlarınızı artırın! Önemli müşterilere etkili bir şekilde ulaşın ve işletmenizi büyütün."
            //Element
            Description.innerHTML = "Google'da öne çıkarak işinizi güçlendirin, satışları artırın ve internet sitenizin trafiğini arttırın!"
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Önemli müşterilere etkili bir şekilde ulaşın! Bugün reklam verin ve markanızı parlatın!"
            //Element
            Two.innerHTML         = "Google ile işletmenizi bulunabilir hale getirin. Yeni müşterilere kolayca ulaşmak şimdi reklam verin."
            //Element
            Three.innerHTML       = "Google kullanarak satışları artırın yeni müşterilere etkili bir şekilde ulaşın ve işletmenizi büyütün."
            //Element
            Description.innerHTML = "Google'da öne çıkarak işinizi güçlendirin, satışları artırın ve internet sitenizin trafiğini arttırın!"
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Google Ads ile belirli anahtar kelimeler ile hedeflenmiş reklamlar oluşturarak çevrimiçi görünürlüğünüzü artırın."
            //Element
            Two.innerHTML         = "Google Ads ile önemli anahtar kelimelere odaklanarak reklamlarınızın kullanıcı ilgisini çekmesini sağlayın."
            //Element
            Three.innerHTML       = "Hedefli reklamlar aracılığıyla Google, müşterilere etkili bir şekilde ulaşma ve satışları artırma fırsatı sağlar."
            //Element
            Description.innerHTML = "Ürün ve hizmetlerinizi Google'da arayan müşterilere direkt olarak ulaşarak, satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Google Ads ile belirli anahtar kelimeler ile hedeflenmiş reklamlar oluşturarak çevrimiçi görünürlüğünüzü artırın."
            //Element
            Two.innerHTML         = "Google Ads ile önemli anahtar kelimelere odaklanarak reklamlarınızın kullanıcı ilgisini çekmesini sağlayın."
            //Element
            Three.innerHTML       = "Hedefli reklamlar aracılığıyla Google, müşterilere etkili bir şekilde ulaşma ve satışları artırma fırsatı sağlar."
            //Element
            Description.innerHTML = "Ürün ve hizmetlerinizi Google'da arayan müşterilere direkt olarak ulaşarak, satışlarınızı artırın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Google belirli anahtar kelimelerle hedeflenmiş reklamlar aracılığıyla dijital görünürlüğü artırma ve müşterilere etkili bir biçimde ulaşma imkanı sunar."
            //Element
            Two.innerHTML         = "Google belirli anahtar kelimelerle hedeflenmiş reklamlar ile kullanıcıların ilgisini çekerek daha geniş bir izleyici kitlesine erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Hedefli reklamlar aracılığıyla Google, önemli müşterilere etkili bir şekilde ulaşma ve satışları artırma fırsatı sağlar."
            //Element
            Description.innerHTML = "İşletmenizi sunduğunuz ürün veya hizmetleri Google'da aktif olarak arayan, kullanıcıların karşısına çıkararak satışların artmasını sağlayın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Google belirli anahtar kelimelerle hedeflenmiş reklamlar aracılığıyla dijital görünürlüğü artırma ve müşterilere etkili bir biçimde ulaşma imkanı sunar."
            //Element
            Two.innerHTML         = "Google belirli anahtar kelimelerle hedeflenmiş reklamlar ile kullanıcıların ilgisini çekerek daha geniş bir izleyici kitlesine erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Hedefli reklamlar aracılığıyla Google, önemli müşterilere etkili bir şekilde ulaşma fırsatı sağlar."
            //Element
            Description.innerHTML = "İşletmenizi, sunduğunuz ürün veya hizmetleri Google'da aktif olarak arayan, kullanıcıların karşısına çıkararak satışların artmasını sağlayın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Google, belirli anahtar kelimelerle hedeflenmiş reklamlar aracılığıyla dijital görünürlüğü artırma ve müşterilere etkili bir biçimde ulaşma imkanı sunar."
            //Element
            Two.innerHTML         = "Google, belirli anahtar kelimelerle hedeflenmiş reklamlar ile kullanıcıların ilgisini çekerek daha geniş bir izleyici kitlesine erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Hedefli reklamlar aracılığıyla Google, önemli müşterilere etkili bir şekilde ulaşma fırsatı sağlar."
            //Element
            Description.innerHTML = "İşletmenizi, sunduğunuz ürün veya hizmetleri Google'da aktif olarak arayan, kullanıcıların karşısına çıkararak satışların artmasını sağlayın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Google, belirli anahtar kelimelerle hedeflenmiş reklamlar aracılığıyla dijital görünürlüğü artırma ve müşterilere etkili bir biçimde ulaşma imkanı sunar."
            //Element
            Two.innerHTML         = "Google, belirli anahtar kelimelerle hedeflenmiş reklamlar ile kullanıcıların ilgisini çekerek daha geniş bir izleyici kitlesine erişim imkanı sunar."
            //Element
            Three.innerHTML       = "Hedefli reklamlar aracılığıyla Google, önemli müşterilere etkili bir şekilde ulaşma fırsatı sağlar."
            //Element
            Description.innerHTML = "İşletmenizi, sunduğunuz ürün veya hizmetleri Google'da aktif olarak arayan, kullanıcıların karşısına çıkararak satışların artmasını sağlayın."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Google Ads ile işletmenizi öne çıkarın, önemli müşterilere etkili bir şekilde ulaşın! Şimdi reklam verin ve markanızı Google'da parlatın!"
            //Element
            Two.innerHTML         = "Google Ads ile işletmenizi hemen bulunabilir hale getirin! Yeni müşterilere kolayca ulaşmak ve markanızı ön plana çıkarmak için şimdi reklam verin."
            //Element
            Three.innerHTML       = "Google Ads ile satışlarınızı artırın! Önemli müşterilere etkili bir şekilde ulaşın ve işletmenizi büyütün. Şimdi reklam verin, satışları artırın!"
            //Element
            Description.innerHTML = "Google'da öne çıkarak işinizi güçlendirin satışları artırın ve web sitenizin trafiğini arttırın!"
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Google Ads ile işletmenizi öne çıkarın, önemli müşterilere etkili bir şekilde ulaşın! Şimdi reklam verin ve markanızı Google'da parlatın!"
            //Element
            Two.innerHTML         = "Google Ads ile işletmenizi hemen bulunabilir hale getirin! Yeni müşterilere kolayca ulaşmak ve markanızı ön plana çıkarmak için şimdi reklam verin."
            //Element
            Three.innerHTML       = "Google Ads ile satışlarınızı artırın! Önemli müşterilere etkili bir şekilde ulaşın ve işletmenizi büyütün. Şimdi reklam verin, satışları artırın!"
            //Element
            Description.innerHTML = "Google'da öne çıkarak işinizi güçlendirin, satışları artırın ve web sitenizin trafiğini arttırın!"
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Google Ads ile işletmenizi öne çıkarın, potansiyel müşterilere etkili bir şekilde ulaşın! Şimdi reklam verin ve markanızı Google'da parlatın!"
           //Element
           Two.innerHTML         = "Google Ads ile işletmenizi hemen bulunabilir hale getirin! Yeni müşterilere kolayca ulaşmak ve markanızı ön plana çıkarmak için şimdi reklam verin."
           //Element
           Three.innerHTML       = "Google Ads ile satışlarınızı artırın! Önemli müşterilere etkili bir şekilde ulaşın ve işletmenizi büyütün. Şimdi reklam verin, satışları artırın!"
           //Element
           Description.innerHTML = "Google'da öne çıkarak işletmenizi güçlendirin, satışları artırın ve internet sitenizin trafiğini çoğaltın!"
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Online varlığınızı artırmanın anahtarı: Google Ads ile öne çıkın, hedef kitlenizi çekin, markanızı daha görünür hale getirin!"
            //Element
            Two.innerHTML         = "Google Ads ile işinizi herkesin görebileceği bir noktaya taşıyın! Hedef kitlenize anında ulaşın, işinizi Google'da öne çıkararak lider olun!"
            //Element
            Three.innerHTML       = "Google Ads kullanarak satışlarınızı zirveye taşıyın! Etkili reklamlar ile hedef kitlenizi büyütün, işinizi güçlü bir şekilde konumlandırarak satışlarınızı artırın!"
            //Element
            Description.innerHTML = "İşletmenizi Google'da öne çıkararak satışları artırın, önemli müşteri sayınızı çoğaltın ve web sitenizin trafiğini artırın!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Online varlığınızı yükseltmenin anahtarı: Google Ads ile öne çıkın! Hedef kitlenizin dikkatini çekin, markanızı Google'da daha görünür hale getirin ve işinizi başarıya taşıyın!"
            //Element
            Two.innerHTML         = "Google Ads ile işinizi herkesin görebileceği bir noktaya taşıyın! Hedef kitlenize anında ulaşın, bulunabilirliğinizi artırın ve işinizi Google'da öne çıkararak başarıya giden yolda lider olun!"
            //Element
            Three.innerHTML       = "Google Ads ile satışlarınızı zirveye taşıyın! Etkili reklamlarla hedef kitlenizi büyütün, potansiyel müşterilere ulaşın ve işinizi Google'da güçlü bir şekilde konumlandırarak satışlarınızı artırın!"
            //Element
            Description.innerHTML = "İşletmenizi, Google'da aktif olarak ürün veya hizmet arayan kullanıcılara ulaştırarak satışları artırın, potansiyel müşteri sayınızı çoğaltın ve web sitenizin trafiğini artırın."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Google Ads : Google Reklamları İle Yeni Müşteriler Edinin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Daha fazla satış, trafik ve marka bilinirliği için Google hedeflerinize yardımcı olur. Potansiyel müşterilere Google Arama sonuçlarının en üstünde görünün."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Google Reklamları İle Yeni Müşteriler Kazanın", itemProp: "name headline" },
                                    //Element
                                    "Google Reklamları İle Yeni ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Müşteriler"
                                    ),
                                    //Element
                                    " Edinin."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "İşletmenizi, sunduğunuz ürün veya hizmetleri Google'da aktif olarak arayan,",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "kullanıcıların karşısına çıkararak satışların, potansiyel müşterilerin",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "veya site trafiğinin artmasını sağlayın."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Google Reklamları İle Yeni Müşteriler Kazanın", title: "Google Reklamları İle Yeni Müşteriler Kazanın", src: "https://cdn.benozgurtosun.com/v1/business/642676.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Google Ads İle Ön Plana Çıkın.", Summary: "Belirli anahtar kelimelerle hedeflenmiş reklamlar sayesinde Google Ads, çevrimiçi görünürlüğü artırma ve potansiyel müşterilere etkili bir şekilde ulaşma fırsatı sağlar.", Photo: "443976.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Google Ads İle Bulunabilir Olun.", Summary: "Belirli anahtar kelimelerle ilişkilendirilmiş reklamlar aracılığıyla Google Ads, kullanıcıların ilgisini çekerek daha geniş bir izleyici kitlesine erişim imkanı sunar.", Photo: "841850.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Google Ads İle Satışları Artırın.", Summary: "Hedefli reklamlar aracılığıyla Google, potansiyel müşterilere etkili bir şekilde ulaşma ve satışları artırma fırsatı sağlar.", Photo: "732123.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Google;