//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class SEO extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : SEO İle Dijital İmparatorluğunuzu İnşa Edin - Benozgurtosun.com"
        //Element
        Description.content = "Dijital krallığınızı inşa etmek için SEO'nun gücünü kullanın. Web dünyasında öne çıkın, potansiyel müşterilerinizi çekin ve online başarıya ulaşın!"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/seo"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : SEO İle Dijital İmparatorluğunuzu İnşa Edin - Benozgurtosun.com"
        //Element
        Description.content = "Dijital krallığınızı inşa etmek için SEO'nun gücünü kullanın. Web dünyasında öne çıkın, potansiyel müşterilerinizi çekin ve online başarıya ulaşın!"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/seo"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : SEO İle Dijital İmparatorluğunuzu İnşa Edin - Benozgurtosun.com"
        //Element
        Description.content = "Dijital krallığınızı inşa etmek için SEO'nun gücünü kullanın. Web dünyasında öne çıkın, potansiyel müşterilerinizi çekin ve online başarıya ulaşın!"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "SEO rekabetçi bir pazarda öne çıkmak için kilit bir rol oynar. Optimize edilmiş bir web sitesi, doğru anahtar kelimelerle müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Üst sıralarda görünmek, marka bilinirliğinizi artırır. İnsanlar genellikle ilk sayfalara güvenir, bu da marka güvenilirliğini artırır."
            //Element
            Three.innerHTML       = "SEO : Web sitenizin, kullanıcı deneyimini artırır ve dönüşüm oranlarını yükseltmek için içerik oluşturmanıza yardımcı olur."
            //Element
            Description.innerHTML = "SEO : Çevrimiçi varlığınızı güçlendirerek, müşteri çekme ve rekabet üstünlüğü elde etmenin anahtarıdır."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "SEO rekabetçi bir pazarda öne çıkmak için kilit bir rol oynar. Optimize edilmiş bir web sitesi, doğru anahtar kelimelerle müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Üst sıralarda görünmek, marka bilinirliğinizi artırır. İnsanlar genellikle ilk sayfalara güvenir, bu da marka güvenilirliğini artırır."
            //Element
            Three.innerHTML       = "SEO : Web sitenizin, kullanıcı deneyimini artırır ve dönüşüm oranlarını yükseltmek için içerik oluşturmanıza yardımcı olur."
            //Element
            Description.innerHTML = "SEO : Çevrimiçi varlığınızı güçlendirerek, müşteri çekme ve rekabet üstünlüğü elde etmenin anahtarıdır."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "SEO, Rekabetçi bir pazarda öne çıkmak için kilit bir rol oynar. Optimize edilmiş bir web sitesi, doğru anahtar kelimelerle müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Üst sıralarda görünmek, marka bilinirliğinizi yükseltir. İlk sayfalara çıkmanın getirdiği güven, markanızın sağlamlığına olan inancı pekiştirir."
            //Element
            Three.innerHTML       = "SEO: İnternet sitenizin kullanıcı deneyimini iyileştirir ve dönüşüm oranlarınızı artırmak için içerik oluşturmanıza destek sağlar."
            //Element
            Description.innerHTML = "SEO : Dijital varlığınızı güçlendirerek, müşteri çekme ve rekabet üstünlüğü elde etmenin anahtarıdır."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "SEO, Rekabetçi bir pazarda öne çıkmak için kilit bir rol oynar. Optimize edilmiş bir web sitesi, doğru anahtar kelimelerle müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Üst sıralarda görünmek, marka bilinirliğinizi yükseltir. İlk sayfalara çıkmanın getirdiği güven, markanızın sağlamlığına olan inancı pekiştirir."
            //Element
            Three.innerHTML       = "SEO: İnternet sitenizin kullanıcı deneyimini iyileştirir ve dönüşüm oranlarınızı artırmak için içerik oluşturmanıza destek sağlar."
            //Element
            Description.innerHTML = "SEO : Dijital varlığınızı güçlendirerek, müşteri çekme ve rekabet üstünlüğü elde etmenin anahtarıdır."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "SEO, rekabetçi bir pazarda öne çıkmanıza yardımcı olur. Doğru anahtar kelimelerle optimize edilmiş bir internet sitesi müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Sıralamada üstte bulunmak, marka bilinirliğini artırabilir. Kullanıcılar genellikle ilk sayfalara güvenir, bu SEO ile marka güvenilirliği ve tanınırlığını artırır."
            //Element
            Three.innerHTML       = "SEO internet sitenizin kullanıcı deneyimini iyileştirmenin yanı sıra anahtar kelimelerle içerik oluşturarak dönüşüm oranlarınızı artırmanıza olanak tanır."
            //Element
            Description.innerHTML = "SEO potansiyelini kullanarak dijital krallığınızı oluşturun. Web dünyasında öne çıkın, yeni müşterileri çekin ve dijital başarıya ulaşın!"
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "SEO, rekabetçi bir pazarda öne çıkmanıza yardımcı olur. Doğru anahtar kelimelerle optimize edilmiş bir internet sitesi müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Sıralamada üstte bulunmak, marka bilinirliğini artırabilir. Kullanıcılar genellikle ilk sayfalara güvenir, bu SEO ile marka güvenilirliği ve tanınırlığını artırır."
            //Element
            Three.innerHTML       = "SEO internet sitenizin kullanıcı deneyimini iyileştirmenin yanı sıra anahtar kelimelerle içerik oluşturarak dönüşüm oranlarınızı artırmanıza olanak tanır."
            //Element
            Description.innerHTML = "SEO, potansiyelini kullanarak dijital krallığınızı oluşturun. Web dünyasında öne çıkın, yeni müşterileri çekin ve dijital başarıya ulaşın!"
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "SEO, rekabetçi bir pazarda öne çıkmanıza yardımcı olur. Doğru anahtar kelimelerle optimize edilmiş bir internet sitesi müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Sıralamada üstte bulunmak, marka bilinirliğini artırabilir. Kullanıcılar genellikle ilk sayfalara güvenir, bu SEO ile marka güvenilirliği ve tanınırlığını artırır."
            //Element
            Three.innerHTML       = "SEO internet sitenizin kullanıcı deneyimini iyileştirmenin yanı sıra anahtar kelimelerle içerik oluşturarak dönüşüm oranlarınızı artırmanıza olanak tanır."
            //Element
            Description.innerHTML = "SEO, potansiyelini kullanarak dijital krallığınızı oluşturun. Web dünyasında öne çıkın, yeni müşterileri çekin ve dijital başarıya ulaşın!"
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "SEO: Rekabetçi bir pazarda öne çıkmanıza yardımcı olur. Doğru anahtar kelimelerle optimize edilmiş bir internet sitesi müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Sıralamada üstte bulunmak, marka bilinirliğini artırabilir. Kullanıcılar genellikle ilk sayfalara güvenir, bu SEO ile marka güvenilirliği ve tanınırlığını artırır."
            //Element
            Three.innerHTML       = "SEO internet sitenizin kullanıcı deneyimini iyileştirmenin yanı sıra anahtar kelimelerle içerik oluşturarak dönüşüm oranlarınızı artırmanıza olanak tanır."
            //Element
            Description.innerHTML = "SEO : Potansiyelini kullanarak dijital krallığınızı oluşturun. Web dünyasında öne çıkın, yeni müşterileri çekin ve dijital başarıya ulaşın!"
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "SEO, rekabetçi bir pazarda fark yaratmanıza yardımcı olur. Doğru anahtar kelimelerle optimize edilmiş bir web sitesi, rakiplerin önüne geçerek müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Üst sıralarda görünmek, marka bilinirliğinizi artırır. İnsanlar genellikle ilk sayfalara güvenir, bu da SEO ile marka güvenilirliği ve tanınırlığını artırır."
            //Element
            Three.innerHTML       = "SEO, web sitenizin kullanıcı deneyimini artırır ve dönüşüm oranlarını yükseltmek için hedeflenen anahtar kelimelerle içerik oluşturmanıza olanak tanır."
            //Element
            Description.innerHTML = "SEO: çevrimiçi varlığınızı güçlendirerek, müşteri çekme ve rekabet üstünlüğü sağlamanın kilit noktasıdır."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "SEO, rekabetçi bir pazarda fark yaratmanıza yardımcı olur. Doğru anahtar kelimelerle optimize edilmiş bir web sitesi, rakiplerin önüne geçerek müşteri ilgisini çekebilir."
            //Element
            Two.innerHTML         = "Üst sıralarda görünmek, marka bilinirliğinizi artırır. İnsanlar genellikle ilk sayfalara güvenir, bu da SEO ile marka güvenilirliği ve tanınırlığını artırır."
            //Element
            Three.innerHTML       = "SEO, web sitenizin kullanıcı deneyimini artırır ve dönüşüm oranlarını yükseltmek için hedeflenen anahtar kelimelerle içerik oluşturmanıza olanak tanır."
            //Element
            Description.innerHTML = "SEO: çevrimiçi varlığınızı güçlendirerek, müşteri çekme ve rekabet üstünlüğü sağlamanın kilit noktasıdır."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "SEO, rekabetçi bir pazarda öne çıkmanıza katkı sağlar. Doğru anahtar kelimelerle optimize edilmiş bir web sitesi, rakiplerin önüne geçerek müşteri ilgisini çekebilir."
           //Element
           Two.innerHTML         = "Üst sıralarda görünmek, marka bilinirliğinizi artırır. Müşteriler genellikle ilk sayfalara güvenir, bu da SEO ile marka güvenilirliği ve tanınırlığını artırır."
           //Element
           Three.innerHTML       = "SEO, internet sitenizin kullanıcı deneyimini artırır ve dönüşüm oranlarını yükseltmek için hedeflenen anahtar kelimelerle içerik oluşturmanıza olanak tanır."
           //Element
           Description.innerHTML = "SEO, çevrimiçi görünürlüğünüzü artırarak müşteri çekme ve rekabet avantajı kazanmanın anahtarıdır."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "SEO, rakiplerin önüne geçmek için arama sonuçlarında öne çıkmanızı sağlar. İlgili anahtar kelimelerde üst sıralarda yer alarak müşteri dikkatini çekersiniz."
            //Element
            Two.innerHTML         = "SEO ile web sitenizi üst sıralara taşıyarak marka bilinirliğinizi artırın, daha fazla insanın sizi keşfetmesini sağlayın!"
            //Element
            Three.innerHTML       = "SEO ile web sitenizi yukarı çıkartarak marka bilinirliğinizi artırın. Daha çok ziyaretçi çekerek dönüşüm oranlarınızı yükseltin!"
            //Element
            Description.innerHTML = "SEO, çevrimiçi görünürlüğünüzü artırarak müşteri çekme ve rekabet avantajı kazanma stratejisidir."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Rakiplerinizin önünde olmak için SEO, arama sonuçlarında öne çıkmanıza yardımcı olur. İlgili anahtar kelimelerde üst sıralarda yer alarak müşterilerin dikkatini çekersiniz."
            //Element
            Two.innerHTML         = "SEO ile web sitenizi üst sıralara taşıyarak marka bilinirliğinizi artırın. Daha fazla insanın sizi keşfetmesini sağlayarak markanızı güçlendirin!"
            //Element
            Three.innerHTML       = "SEO ile web sitenizi yukarı çıkartarak marka bilinirliğinizi artırın. Daha çok ziyaretçi çekerek dönüşüm oranlarınızı yükseltin!"
            //Element
            Description.innerHTML = "SEO, işletmenizin çevrimiçi görünürlüğünü artırarak daha fazla müşteri çekmenizi sağlar. Bu önemli strateji, rekabet avantajı kazanmanıza yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : SEO İle Dijital İmparatorluğunuzu İnşa Edin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Dijital krallığınızı inşa etmek için SEO'nun gücünü kullanın. Web dünyasında öne çıkın, potansiyel müşterilerinizi çekin ve online başarıya ulaşın!"
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "SEO İle Dijital İmparatorluğunuzu İnşa Edin!", itemProp: "name headline" },
                                    //Element
                                    "SEO İle ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Dijital"
                                    ),
                                    //Element
                                    " İmparatorluğunuzu İnşa Edin!"
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Dijital krallığınızı inşa etmek için SEO'nun gücünü kullanın. Web dünyasında ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "öne çıkın, potansiyel müşterilerinizi çekin ve online başarıya ulaşın! "
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "SEO İle Dijital İmparatorluğunuzu İnşa Edin!", title: "SEO İle Dijital İmparatorluğunuzu İnşa Edin!", src: "https://cdn.benozgurtosun.com/v1/business/105656.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Rekabet Üstünlüğü Sağlama", Summary: "SEO, rekabetçi bir pazarda öne çıkmanıza katkı sağlar. Doğru anahtar kelimelerle optimize edilmiş bir web sitesi, rakiplerin önüne geçerek müşteri ilgisini çekebilir.", Photo: "834858.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Marka Bilinirliği Artırma", Summary: "Üst sıralarda görünmek, marka bilinirliğinizi artırır. Kullanıcılar genellikle ilk sayfalara güvenir, bu da SEO ile marka güvenilirliği ve tanınırlığını artırır.", Photo: "346442.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Dönüşüm Oranlarını Artırma", Summary: "SEO, internet sitenizin kullanıcı deneyimini artırır ve dönüşüm oranlarını yükseltmek için hedeflenen anahtar kelimelerle içerik oluşturmanıza olanak tanır.", Photo: "320527.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SEO;