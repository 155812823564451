//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/003.svg";
//Install
import propTypes from "prop-types";

//Element
class Mark extends Component {
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 t03rw5" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 o091hh" },
                //Element
                React.createElement( "label", { className: "float-start w-auto p-0 m-0 text-start lh-1 j0z12w" },
                    //Element
                    React.createElement( Icon, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-start lh-1 z9y50r" },
                    //Element
                    Title
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Mark.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired
}
//Element
export default Mark;