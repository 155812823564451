//Install
import React, { Component } from "react";

//Element
class Menu extends Component {
    //Element
    Prevent = (e) => {
        //Element
        e.preventDefault()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 rP69J9", role: "navigation", itemScope: "itemScope", itemType: "http://schema.org/SiteNavigationElement" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto", title: "Başlangıç", href: "/", itemProp: "url" },
                    //Element
                    React.createElement( "span", { itemProp: "name" },
                        //Element
                        "Başlangıç"
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto", title: "Kurumsal", href: "/kurumsal", itemProp: "url" },
                    //Element
                    React.createElement( "span", { itemProp: "name" },
                        //Element
                        "Kurumsal"
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto", title: "Sunulan Hizmetler", href: "/", itemProp: "url", onClick: this.Prevent },
                    //Element
                    React.createElement( "span", { itemProp: "name" },
                        //Element
                        "Sunulan Hizmetler"
                    )
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-4 pb-3 m-0 position-absolute" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "float-start w-100", title: "Web Tasarım", href: "/hemen-ulasin", itemProp: "url" },
                            //Element
                            React.createElement( "span", { itemProp: "name" },
                                //Element
                                "Web Tasarım"
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "float-start w-100", title: "Domain", href: "/e-isletme/domain", itemProp: "url" },
                            //Element
                            React.createElement( "span", { itemProp: "name" },
                                //Element
                                "Domain"
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "float-start w-100", title: "Hosting", href: "/e-isletme/hosting", itemProp: "url" },
                            //Element
                            React.createElement( "span", { itemProp: "name" },
                                //Element
                                "Hosting"
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "float-start w-100", title: "SSL Sertifikaları", href: "/e-isletme/ssl", itemProp: "url" },
                            //Element
                            React.createElement( "span", { itemProp: "name" },
                                //Element
                                "SSL Sertifikaları"
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "float-start w-100", title: "Kurumsal E-posta", href: "/e-isletme/e-posta", itemProp: "url" },
                            //Element
                            React.createElement( "span", { itemProp: "name" },
                                //Element
                                "Kurumsal E-posta"
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "float-start w-100", title: "Search Engine Optimization", href: "/e-isletme/seo", itemProp: "url" },
                            //Element
                            React.createElement( "span", { itemProp: "name" },
                                //Element
                                "Search Engine Optimization"
                            )
                        )
                    ),
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto", title: "Referanslar", href: "/referanslar", itemProp: "url" },
                    //Element
                    React.createElement( "span", { itemProp: "name" },
                        //Element
                        "Referanslar"
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto", title: "Rekabet Programı", href: "/rekabet-programi", itemProp: "url" },
                    //Element
                    React.createElement( "span", { itemProp: "name" },
                        //Element
                        "Rekabet Programı"
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto", title: "Ücretlendirme", href: "/ucretlendirme", itemProp: "url" },
                    //Element
                    React.createElement( "span", { itemProp: "name" },
                        //Element
                        "Ücretlendirme"
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto", title: "Hemen Ulaşın", href: "/hemen-ulasin", itemProp: "url" },
                    //Element
                    React.createElement( "span", { itemProp: "name" },
                        //Element
                        "Hemen Ulaşın"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;