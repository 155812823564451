//Install
import React, { Component } from "react";

//Element
class SET extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 c37034", itemProp: "potentialAction", itemScope: "itemScope", itemType: "http://schema.org/CommunicateAction" },
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 text-center f031o3", title: "Bilgilendirme Talebi", href: "/on-bilgilendirme-talebi", itemProp: "url" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;