//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Brand extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/features/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mb-4 i1888e" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 m24ho4", itemScope: "itemScope", itemType: "https://schema.org/ImageObject" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "30", height: "30", itemProp: "contentUrl" } ),
                //Element
                React.createElement( "meta", { itemProp: "name", content: Title } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Brand.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Brand;