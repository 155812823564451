//Install
import React, { Component } from "react";

//Element
class Headline extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
            //Element
            React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir", itemProp: "name alternateName" },
                //Element
                "Daha Kaliteli Bir ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Gelecek,"
                ),
                //Element
                " Hâlâ Elde Edilebilir"
            ),
            //Element
            React.createElement( "p", { id: "React_I5963A", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                //Element
                "BM Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi, Çevre'nin ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "ve Barışı Hedefliyor."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Headline;