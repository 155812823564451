//Install
import React, { Component } from "react";

//Element
class Hope extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 z93q7k" },
            //Element
            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 lh-1 c28g97", title: "İnternet: Yeni Müşteriler Edinme Fırsatı Sunar" },
                //Element
                "İnternet: Yeni Müşteriler Edinme ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Fırsatı"
                ),
                //Element
                " Sunar"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 qo3320" },
                //Element
                "Profesyonel bir web tasarım ile müşteri tabanınızı genişleterek dijital varlığınızı ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "güçlendirin ve müşteri kitlenizi genişletin."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Hope;