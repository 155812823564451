//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class User extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/user/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-4 b98w7p", itemScope: "itemScope", itemType: "https://schema.org/ImageObject" },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 p72qx6" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "96", height: "96", itemProp: "contentUrl" } )
            ),
            //Element
            React.createElement( "meta", { itemProp: "name", content: Title } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
User.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default User;