//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Slogan extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/icon/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { id: ID, className: "float-start w-100 p-0 m-0 bg-transparent border-0 h5l5y9", itemScope: "itemScope", itemType: "https://schema.org/Guide" },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mb-3 se5658" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "416", height: "224", itemProp: "image" } )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-center p-0 m-0 g4zf86" },
                //Element
                React.createElement( "h4", { className: "float-start w-auto p-0 m-0 mt-2 e99v68", itemProp: "name", title: Title },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 ps-4 pe-4 m-0 mt-2 text-center t77am5", itemProp: "description" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Slogan.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Slogan;