//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Headline from "../../Shortcode/Corporation/Headline";
//Install
import Count from "../../Shortcode/Corporation/Count";
//Install
import Icon from "../../Shortcode/Corporation/Icon";
//Install
import Sub from "../../Shortcode/Corporation/Sub";
//Install
import Company from "../../Shortcode/Corporation/Company";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        Description.content = "Merhaba Ben Özgür Tosun; 7 Bölge'de, 68 Farklı Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/kurumsal"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        Description.content = "Merhaba Ben Özgür Tosun; 7 Bölge'de, 68 Farklı Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/kurumsal"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        Description.content = "Merhaba Ben Özgür Tosun; 7 Bölge'de, 68 Farklı Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Resposive = () => {
        //Element
        let Description = document.querySelector( "#React_023OGK" )
        //Element
        let Sub         = document.querySelector( "#React_T922P1" )
        //Element
        let Heading     = document.querySelector( ".t4q297" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Heading.innerHTML     = "Müşteriler İle Daha Güçlü Bağlantılar Kurun."
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "İşinizi dijital dünyada öne çıkarmak ve müşterilerle güçlü bağlantılar kurmak için kurumsal web tasarım ile tanışın!"
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Heading.innerHTML     = "Müşteriler İle Daha Güçlü Bağlantılar Kurun."
            //Element
            Description.innerHTML = "Ben Özgür Tosun 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "İşinizi dijital dünya'da öne çıkarmak ve müşteriler ile güçlü bağlantılar kurmak için, web tasarım ile tanışın!"
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Heading.innerHTML     = "Müşteriler İle Daha Güçlü Bağlantılar Kurun."
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Müşterilerinizle daha güçlü bağlantılar kurun! İyi bir web tasarım ile dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Gururu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Müşterilerinizle daha güçlü bağlantılar kurun! Etkileyici bir web tasarım ile dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Müşteriler ile daha güçlü bağlantılar kurun! Kurumsal bir web tasarım ile dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Müşterilerle daha sağlam bağlar kurmak için kurumsal bir web tasarımı kullanın! Dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Müşterilerle daha sağlam bağlar kurmak için kurumsal bir web tasarımı kullanın! Dijital varlığınızı güçlendirin ve işletmenizi öne çıkarın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Farklı Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Dijital varlık oluşturarak, işletmenizi öne çıkarabilir, önemli müşterilere ulaşabilir ve satışlarınızı artırabilirsiniz."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Online varlık oluşturarak, işletmenizi görünür kılabilir, önemli müşterilere ulaşabilir ve hem online hem de yerel satışları artırabilirsiniz."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Online varlık oluşturarak, işletmenizi görünür kılabilir, önemli müşterilere ulaşabilir ve hem online hem de yerel satışları artırabilirsiniz."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           Description.innerHTML = "Ben Özgür Tosun, 7 Bölge'de, 68 Şehir'de, 5.000 İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
           //Element
           Sub.innerHTML         = "Dijital varlık oluşturarak işletmenizi daha görünür kılabilir, önemli müşterilere erişim sağlayabilir ve hem online hem de yerel satışlarınızı artırabilirsiniz."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun; 7 Bölge'de, 68 Şehir'de, 5.000 İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "Çevrimiçi varlık oluşturmak, işletmenizin tanınırlığını artırmanın yanı sıra geniş bir müşteri kitlesini çekip hem online hem de yerel satışları artırmanın etkili bir stratejisi olabilir."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Description.innerHTML = "Merhaba, Ben Özgür Tosun 7 Bölge'de, 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Gururu İle Sektörde 12. Yılımı Geride Bıraktım."
            //Element
            Sub.innerHTML         = "İnternette varlık oluşturmanız işletmenizin bilinirliğini artırmanıza, daha fazla müşteri bulmanıza ve hem internetten satışları hem de yerel satışları artırmanıza yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Merhaba Ben Özgür Tosun; 7 Bölge'de, 68 Farklı Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        let Space = document.getElementsByClassName( "hv89h7" )[0]
        //Element
        Space.classList.remove( "mt-5" )
        //Element
        Space.classList.add( "mt-4" )
        //Element
        this.Resposive()
    }
    //Element
    render() {
        //Element
        const References = {
            //Element
            spaceBetween    : "20.25",
            //Element
            slidesPerView   : "9",
            //Element
            autoplay        : {
                //Element
                delay       : 2000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                390:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                393:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                400:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                411:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                432:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                720:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 8
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 8
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 9
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        let Source = "https://benozgurtosun.s3.eu-north-1.amazonaws.com/video/005.mp4"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/AboutPage" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Headline, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 b0e620" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 v8128v" },
                                //Element
                                React.createElement( "video", { className: "float-start w-100 h-auto", playsInline: "playsinline", src: Source, controls: "controls", loop: true, autoPlay: true, muted: "muted", itemProp: "video" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 b0e620" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "7", Summary: "Farklı Bölge'de", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "68", Summary: "Farklı Şehir'de", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "2.900", Summary: "Yeni Kurulan İşletme", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "1.800", Summary: "Küçük Ölçekli İşletme", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "1.300", Summary: "Orta Ölçekli İşletme", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Count, { ID: "0", Title: "99.8%", Summary: "İşletme Başarı Oranları", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 zx1987" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 a65254" },
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "HTML5", Photo: "001.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "CSS3", Photo: "002.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "JavaScript", Photo: "003.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "jQuery", Photo: "004.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Sass", Photo: "005.svg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 k791vc" },
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 n97qf5" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 a65254" },
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "React", Photo: "006.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Node.JS", Photo: "007.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "CodeceptJS", Photo: "021.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Vue.js", Photo: "009.svg", Target: "/" } ),
                                //Element
                                React.createElement( Icon, { ID: "0", Title: "Angular", Photo: "010.svg", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Sub, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 a69q9l" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...References },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Ceyda Alev", Photo: "001.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Ataer Emlak", Photo: "002.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Demircioğlu İnşaat", Photo: "003.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Demirler Hukuk", Photo: "004.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Melekler Elektrik", Photo: "005.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Deryalar", Photo: "006.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Fabrika İnşaat", Photo: "007.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Günal Lojistik", Photo: "008.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Mine Tekstil", Photo: "010.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Türker İlaç", Photo: "009.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Baks Motor", Photo: "011.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "NOTA", Photo: "012.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Emin Emlak", Photo: "013.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Akallar Kırtasiye", Photo: "014.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Filizler", Photo: "015.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Nispetiye Hukuk", Photo: "016.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Hayla Grup", Photo: "017.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "MSGR", Photo: "018.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "MakineX", Photo: "019.webp", Target: "/referanslar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Company, { ID: "0", Title: "Sul Yapı", Photo: "020.webp", Target: "/referanslar" } )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;