//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../Media/Brand/000.svg";

//Element
class Brand extends Component {
    //Element
    Target = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { id: "React_E2LL19", name: "React_E2LL19", role: "dialog", "aria-label": "Başlangıç", title: "Başlangıç", className: "d-flex w-100 justify-content-start align-items-center bg-transparent border-0 p-0 m-0 s5c45j", type: "button", onClick: this.Target },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 position-relative rounded-circle x0b837" },
                //Element
                React.createElement( Icon, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Brand;