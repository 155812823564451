//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Featured extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/case/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 g430eq", itemProp: "url", title: Title, href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v4075g" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "424", height: "202", itemProp: "image" } )
            ),
            //Element
            React.createElement( "meta", { itemProp: "name", content: Title } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Featured.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Featured;