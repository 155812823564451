//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Facebook extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Meta : Müşterilerinizi Facebook Reklamları İle Bulun - Benozgurtosun.com"
        //Element
        Description.content = "Facebook'da pazarlama ile ürünlerinizin tanıtımını yapın marka bilinirliğini artırın ve yaratıcılığınızı ortaya çıkarın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/facebook"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Meta : Müşterilerinizi Facebook Reklamları İle Bulun - Benozgurtosun.com"
        //Element
        Description.content = "Facebook'da pazarlama ile ürünlerinizin tanıtımını yapın marka bilinirliğini artırın ve yaratıcılığınızı ortaya çıkarın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/facebook"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Meta : Müşterilerinizi Facebook Reklamları İle Bulun - Benozgurtosun.com"
        //Element
        Description.content = "Facebook'da pazarlama ile ürünlerinizin tanıtımını yapın marka bilinirliğini artırın ve yaratıcılığınızı ortaya çıkarın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Facebook ile işletmenizi unutulmaz kılın ve uzun vadeli takipçilere ulaşın."
            //Element
            Two.innerHTML         = "Facebook işletmenizin unutulmaz olmasını sağlar ve uzun vadeli takipçilere ulaşma şansını artırabilir."
            //Element
            Three.innerHTML       = "Önemli müşterilere ulaşmak için çeşitli yöntemleri kullanın, müşteri ağınızı keşfedin ve iş süreçlerini optimize edin."
            //Element
            Description.innerHTML = "Facebook reklamları işinizi binlere duyurmanın en etkili yolu! Müşteri etkileşimini artırarak görünürlüğünüzü yükseltin!"
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Facebook'u kullanarak işletmenizi unutulmaz kılın ve uzun vadeli takipçilere ulaşın."
            //Element
            Two.innerHTML         = "Facebook, işletmenizin unutulmaz olmasını sağlar ve uzun vadeli takipçilere ulaşma şansını artırabilir."
            //Element
            Three.innerHTML       = "Önemli müşterilere ulaşmak için çeşitli yöntemleri kullanın, müşteri ağınızı keşfedin ve iş süreçlerini optimize edin."
            //Element
            Description.innerHTML = "Facebook reklamları işinizi binlere duyurmanın en etkili yolu! Müşteri etkileşimini artırarak görünürlüğünüzü yükseltin!"
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Facebook reklamları, işletmenizin akılda kalıcı olmasını sağlayarak uzun vadeli takipçilere ulaşma şansını artırabilir."
            //Element
            Two.innerHTML         = "Facebook reklamları aracılığıyla, işletmenizi hatırlatın v uzun vadeli takipçilere dönüşen önemli müşterilere erişim sağlayın."
            //Element
            Three.innerHTML       = "Facebook reklamlarıyla, işletmenize önemli müşteriler kazandırın! Hemen başlayın, potansiyel müşterileri etkileyin."
            //Element
            Description.innerHTML = "Facebook reklamları ile ürünlerinizin tanıtımını yapın, marka bilinirliğini artırın ve yaratıcılığınızı ortaya çıkarın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Facebook aracılığıyla işletmenizin akılda kalıcı olmasını v uzun vadeli takipçilere ulaşma şansını artırabilirsiniz."
            //Element
            Two.innerHTML         = "Facebook aracılığıyla, işletmenizi hatırlamalarını ve uzun süreli takipçiniz olmalarını sağlayabilecek potansiyel kişilere erişim sağlayın."
            //Element
            Three.innerHTML       = "Önemli müşterilere erişim sağlamak için çeşitli stratejileri kullanın, müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın."
            //Element
            Description.innerHTML = "Facebook aracılığıyla ürünlerinizin tanıtımını yapın marka bilinirliğini artırın ve yaratıcılığınızı ortaya çıkarın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Facebook Ads, aracılığıyla işletmenizin akılda kalıcılığını sağlayabilir ve uzun vadeli takipçilere ulaşma şansını artırabilirsiniz."
            //Element
            Two.innerHTML         = "Facebook reklamlarını kullanarak, işletmenizi hatırlatma ve uzun süreli takipçiler kazanma potansiyeline sahip kişilere etkili bir şekilde ulaşım sağlayabilirsiniz."
            //Element
            Three.innerHTML       = "Yeni müşterilere ulaşmak için çeşitli yöntemleri kullanın, müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın."
            //Element
            Description.innerHTML = "Facebook'da pazarlama ile ürünlerinizin tanıtımını yapın marka bilinirliğini artırın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Facebook Ads, aracılığıyla işletmenizin akılda kalıcılığını sağlayabilir ve uzun vadeli takipçilere ulaşma şansını artırabilirsiniz."
            //Element
            Two.innerHTML         = "Facebook reklamlarını kullanarak, işletmenizi hatırlatma ve uzun süreli takipçiler kazanma potansiyeline sahip kişilere etkili bir şekilde ulaşım sağlayabilirsiniz."
            //Element
            Three.innerHTML       = "Yeni müşterilere ulaşmak için çeşitli yöntemleri kullanın, müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın."
            //Element
            Description.innerHTML = "Hedef kitlenizi geniş kitlelere etkili bir şekilde, tanıtmak ve işletmenizi daha fazla kişiye tanıtmak için hemen Facebook Reklamları kullanın!"
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Facebook Ads, aracılığıyla işletmenizin akılda kalıcılığını sağlayabilir ve uzun vadeli takipçilere ulaşma şansını artırabilirsiniz."
            //Element
            Two.innerHTML         = "Facebook reklamlarını kullanarak, işletmenizi hatırlatma ve uzun süreli takipçiler kazanma potansiyeline sahip kişilere etkili bir şekilde ulaşım sağlayabilirsiniz."
            //Element
            Three.innerHTML       = "Yeni müşterilere ulaşmak için çeşitli yöntemleri kullanın, müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın."
            //Element
            Description.innerHTML = "Hedef kitlenizi geniş kitlelere etkili bir şekilde ulaştırmak ve işletmenizi daha fazla kişiye tanıtmak için hemen Facebook Reklamları kullanın!"
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Facebook Ads, aracılığıyla işletmenizin akılda kalıcılığını sağlayabilir ve uzun vadeli takipçilere ulaşma şansını artırabilirsiniz."
            //Element
            Two.innerHTML         = "Facebook reklamlarını kullanarak, işletmenizi hatırlatma ve uzun süreli takipçiler kazanma potansiyeline sahip kişilere etkili bir şekilde ulaşım sağlayabilirsiniz."
            //Element
            Three.innerHTML       = "Yeni müşterilere ulaşmak için çeşitli yöntemleri kullanın, müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın."
            //Element
            Description.innerHTML = "Hedef kitlenizi geniş kitlelere etkili bir şekilde ulaştırmak ve işletmenizi daha fazla kişiye tanıtmak için hemen Facebook Reklamları kullanın!"
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Facebook reklamlarını kullanarak, işletmenizin akılda kalıcı olmasını ve uzun vadeli takipçilere ulaşma şansını artırabilirsiniz."
            //Element
            Two.innerHTML         = "Facebook reklamları aracılığıyla, işletmenizi hatırlamalarını ve uzun vadeli takipçiniz olmalarını sağlayacak potansiyel müşterilere ulaşın."
            //Element
            Three.innerHTML       = "Önemli müşterilere ulaşmak için çeşitli yöntemleri kullanın, geniş potansiyel müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın."
            //Element
            Description.innerHTML = "Facebook reklamları, işinizi daha geniş kitlelere tanıtmanın en güçlü aracı! Müşteriler ile etkileşimi maksimumda tutarak görünürlüğünüzü artırın!"
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Facebook reklamlarını kullanarak, işletmenizin akılda kalıcı olmasını ve uzun vadeli takipçilere ulaşma şansını artırabilirsiniz."
            //Element
            Two.innerHTML         = "Facebook reklamları aracılığıyla, işletmenizi hatırlamalarını ve uzun vadeli takipçiniz olmalarını sağlayacak potansiyel müşterilere ulaşın."
            //Element
            Three.innerHTML       = "Önemli müşterilere ulaşmak için çeşitli yöntemleri kullanın, geniş potansiyel müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın."
            //Element
            Description.innerHTML = "Facebook reklamları, işinizi daha geniş kitlelere tanıtmanın en güçlü aracı! Müşteriler ile etkileşimi maksimumda tutarak görünürlüğünüzü artırın!"
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Hedef kitlenize ulaşın, satışlarınızı artırın! Facebook reklamları ile şimdi başlayın."
           //Element
           Two.innerHTML         = "Facebook reklamları ile çevrimiçi görünürlüğünüzü artırın!"
           //Element
           Three.innerHTML       = "Facebook reklamları ile müşteri tabanınızı genişletin, başarıyı yakalamak için şimdi harekete geçin!"
           //Element
           Description.innerHTML = "Facebook reklamları, işinizi daha geniş kitlelere tanıtmanın en güçlü aracı! Müşteriler ile etkileşimi maksimumda tutarak görünürlüğünüzü artırın!"
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Facebook reklamları ile hemen büyüyün, hedef kitlenize ulaşın!"
            //Element
            Two.innerHTML         = "İnternet bilinirliğinizi zirveye taşımanın güçlü yolu: Facebook! Hemen başlayın, markanızı milyonlarca kullanıcıyla buluşturun!"
            //Element
            Three.innerHTML       = "Daha fazla müşteri edinmenin etkili yolu: Facebook reklamları! Hedef kitlenizi genişletin, işinizi büyütün, önemli müşterilere hemen ulaşın!"
            //Element
            Description.innerHTML = "Facebook reklamları, işinizi geniş bir kitleye tanıtmanın en etkili yolu! Hedef kitlenize doğrudan ulaşın ve görünürlüğünüzü artırın!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Markanızı parlatmanın en hızlı yolu: Facebook reklamları! Hedef kitlenize anında ulaşın, işinizi büyütün."
            //Element
            Two.innerHTML         = "İnternet bilinirliğinizi zirveye taşımanın en güçlü yolu: Facebook Hemen başlayın, markanızı milyonlarca kullanıcıyla buluşturun!"
            //Element
            Three.innerHTML       = "Daha fazla müşteri edinmenin en etkili yolu: Facebook reklamları! Hedef kitlenizi genişletin, işinizi büyütün ve önemli müşterilere hemen ulaşın!"
            //Element
            Description.innerHTML = "Facebook reklamları, işinizi geniş bir kitleye tanıtmanın en etkili yoludur. Hedef kitlenize direkt ulaşın ve görünürlüğünüzü artırın!"
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Meta : Müşterilerinizi Facebook Reklamları İle Bulun - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Facebook'da pazarlama ile ürünlerinizin tanıtımını yapın marka bilinirliğini artırın ve yaratıcılığınızı ortaya çıkarın."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Müşterilerinizi Facebook Reklamları İle Bulun.", itemProp: "name headline" },
                                    //Element
                                    "Müşterilerinizi ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Facebook"
                                    ),
                                    //Element
                                    " Reklamları İle Bulun!"
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Facebook'da pazarlama ile ürünlerinizin tanıtımını yapın marka bilinirliğini artırın",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "ve yaratıcılığınızı ortaya çıkarın."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Müşterilerinizi Facebook Reklamları İle Bulun!", title: "Müşterilerinizi Facebook Reklamları İle Bulun!", src: "https://cdn.benozgurtosun.com/v1/business/413985.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Markanızın Reklamını Yapın.", Summary: "Facebook reklamlarını kullanarak, işletmenizin akılda kalıcı olmasını ve uzun vadeli takipçilere ulaşma şansını artırabilirsiniz.", Photo: "696504.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "İnternette Bilinirliği Artırın.", Summary: "Facebook reklamlarını kullanarak, işletmenizi hatırlamalarını ve uzun süreli takipçiniz olmalarını sağlayabilecek potansiyel kişilere erişim sağlayın.", Photo: "446888.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Daha Fazla Müşteri Edinin.", Summary: "Önemli müşterilere ulaşmak için çeşitli yöntemleri kullanın, geniş potansiyel müşteri ağını keşfedin ve iş süreçlerinizi optimize ederek entegrasyon sağlayın.", Photo: "421900.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Facebook;