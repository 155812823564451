//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Bilgi Bulunamadı - Benozgurtosun.com"
        //Element
        Description.content = "Bu Konuda Herhangi Bir Bilgi Bulunamadı!"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/404"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "404 : Bilgi Bulunamadı - Benozgurtosun.com"
        //Element
        Description.content = "Web Tasarım : Bilgi Bulunamadı - Benozgurtosun.com"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/404"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Bilgi Bulunamadı - Benozgurtosun.com"
        //Element
        Description.content = "Bu Konuda Herhangi Bir Bilgi Bulunamadı!"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Info = () => {
        //Element
        let Title             = document.querySelector( "#React_A294VI" )
        //Element
        let Description       = document.querySelector( "#React_L3C08Z" )
        //Element
        if( window.innerWidth == "320" ){
            //Element
        //Element
        } else if( window.innerWidth == "400" ){
            //Element
            Title.innerHTML       = "Memnuniyetle Yardımcı Olurum, Gerçekten."
            //Element
            Description.innerHTML = "Desteğe ihtiyacınız olduğunda her daim burada olacağım. Bana herhangi bir zamanda ulaşabilirsin."
        //Element
        } else if( window.innerWidth == "480" ){
            //Element
            Title.innerHTML       = "Her zaman buradayım, ihtiyacın olursa konuşabiliriz."
            //Element
            Description.innerHTML = "Hâlâ ihtiyacınız ne olduğundan emin değil misiniz? Yardıma her zaman hazırım, müşteri olmasanız bile."
        //Element
        } else if( window.innerWidth == "504" ){
            //Element
            Title.innerHTML       = "Her zaman buradayım, bilgiye ihtiyacın olursa konuşabiliriz."
            //Element
            Description.innerHTML = "Hâlâ neye ihtiyacınız olduğundan emin değil misiniz? Yardıma her zaman hazırım, müşteri olmasanız bile."
        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Bilgi Bulunamadı - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Bu Konuda Herhangi Bir Bilgi Bulunamadı!"
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Info()
        //Element
        let Robots = document.querySelector( "meta[name='robots']" )
        //Element
        Robots.content = "noindex, follow, noimageindex"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x4i0g2" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center lh-1 m97p51", title: "404", itemProp: "name alternateName" },
                                    //Element
                                    "4",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "0"
                                    ),
                                    //Element
                                    "4"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center lh-1 pea034", itemProp: "description" },
                                    //Element
                                    "Bu Konuda Herhangi Bir Bilgi Bulunamadı!"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;