//Install
import React, { Component } from "react";

//Element
class Internet extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 z93q7k" },
            //Element
            React.createElement( "h5", { className: "float-start w-auto p-0 m-0 c28g97", title: "İnternet'e Değer Katan Küresel İş Ortaklarım" },
                //Element
                "İnternet'e Değer Katan Küresel ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "İş Ortaklarım"
                )
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 i31t7q" },
                //Element
                "Benimle çalışan işletmelere; değer katan küresel markalar, küçük işletmelere ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "başarılı olabilmeleri için gereken desteği ve araçları sunar."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Internet;