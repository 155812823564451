//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Search from "../Shortcode/Mobile/Search";
//Install
import Menu from "../Shortcode/Mobile/Menu";
//Install
import Sub from "../Shortcode/Mobile/Sub";
//Install
import Connect from "../Shortcode/Mobile/Connect";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 t328w9" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Search, {} )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 j4d4t5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 pt-2 m-0 o8x75e" },
                                //Element
                                React.createElement( Menu, {} ),
                                //Element
                                React.createElement( Sub, {} ),
                                //Element
                                React.createElement( Connect, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Submenu;