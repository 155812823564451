//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Hosting extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Birinci Sınıf Ücretsiz Web Hosting Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Ücretsiz ve güvenilir bir web hosting hizmeti ile hayalinizdeki, internet sitesini hayata geçirerek dijital dünyada kolayca varlık göstermeye başlayabilirsiniz."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/hosting"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Birinci Sınıf Ücretsiz Web Hosting Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Ücretsiz ve güvenilir bir web hosting hizmeti ile hayalinizdeki, internet sitesini hayata geçirerek dijital dünyada kolayca varlık göstermeye başlayabilirsiniz."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/hosting"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Birinci Sınıf Ücretsiz Web Hosting Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Ücretsiz ve güvenilir bir web hosting hizmeti ile hayalinizdeki, internet sitesini hayata geçirerek dijital dünyada kolayca varlık göstermeye başlayabilirsiniz."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "İyi bir hosting hizmeti, yeni işletmeler için maliyet avantajı sağlar. Bu enerji, bakım ve diğer masrafları azaltır."
            //Element
            Two.innerHTML         = "SEO, için önemli olan hızlı yükleme, düşük çalışma süreleri güçlü bir hosting hizmeti ile artırılabilir."
            //Element
            Three.innerHTML       = "Etkili bir hosting hizmeti, yüksek uptime garantisi sunarak web sitenizin sürekli olarak çevrimiçi olmasını sağlar."
            //Element
            Description.innerHTML = "Hayalinizdeki web sitesini ücretsiz ve güvenilir bir hosting hizmeti ile gerçeğe dönüştürün."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "İyi bir hosting hizmeti, yeni işletmeler için maliyet avantajı sağlar. Bu enerji, bakım ve diğer masrafları azaltır."
            //Element
            Two.innerHTML         = "SEO : İçin kritik olan hızlı yükleme, düşük çalışma süreleri, etkili bir hosting hizmeti ile artırılabilir."
            //Element
            Three.innerHTML       = "Etkili bir hosting hizmeti, yüksek uptime garantisi sunarak web sitenizin sürekli olarak çevrimiçi olmasını sağlar."
            //Element
            Description.innerHTML = "Hayalinizdeki web sitesini ücretsiz ve güvenilir bir hosting hizmeti ile gerçeğe dönüştürün."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Sağlam bir hosting hizmeti, yeni işletmeler için maliyet avantajı sunar,  böylece bütçenizi koruyarak çevrimiçi varlığınızı güçlendirebilirsiniz."
            //Element
            Two.innerHTML         = "SEO başarısı için kritik olan hızlı yükleme süreleri, iyi bir hosting hizmeti ile artırılabilir."
            //Element
            Three.innerHTML       = "Etkili hosting hizmeti, yüksek uptime garantisi sunarak web sitenizin sürekli olarak çevrimiçi olmasını sağlar."
            //Element
            Description.innerHTML = "Birinci sınıf bir SSD Hosting ile, işletmenizi çevrimiçi dünyada öne çıkarın!"
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Sağlam bir hosting hizmeti, yeni işletmeler için maliyet avantajı sunar,  böylece bütçenizi koruyarak çevrimiçi varlığınızı güçlendirebilirsiniz."
            //Element
            Two.innerHTML         = "SEO başarısı için kritik olan hızlı yükleme süreleri, iyi bir hosting hizmeti ile artırılabilir."
            //Element
            Three.innerHTML       = "Etkili hosting hizmeti, yüksek uptime garantisi sunarak web sitenizin sürekli olarak çevrimiçi olmasını sağlar."
            //Element
            Description.innerHTML = "Birinci sınıf bir SSD Hosting ile, işletmenizi çevrimiçi dünyada öne çıkarın!"
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Kaliteli bir hosting hizmeti, enerji ve bakım maliyetlerini düşürerek küçük işletmelere maliyet avantajı sağlayabilir."
            //Element
            Two.innerHTML         = "Hızlı yükleme süreleri ve düşük çalışma süreleri etkili bir hosting hizmeti ile optimize edilebilir. Böylece web siteniz daha üst sıralara çıkabilir."
            //Element
            Three.innerHTML       = "Kaliteli bir hosting yüksek uptime garantisi sunarak web sitenizin sürekli çevrimiçi olmasını sağlar, bu müşteri memnuniyetini artırır ve iş sürekliliğini korur."
            //Element
            Description.innerHTML = "Ücretsiz ve güvenilir web hosting hizmeti ile, istediğiniz internet sitesini oluşturarak dijital dünyada hızla varlık göstermeye başlayabilirsiniz."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Kaliteli bir hosting hizmeti, enerji ve bakım maliyetlerini düşürerek küçük işletmelere maliyet avantajı sağlayabilir."
            //Element
            Two.innerHTML         = "Hızlı yükleme süreleri ve düşük çalışma süreleri etkili bir hosting hizmeti ile optimize edilebilir. Böylece web siteniz daha üst sıralara çıkabilir."
            //Element
            Three.innerHTML       = "Kaliteli bir hosting yüksek uptime garantisi sunarak web sitenizin sürekli çevrimiçi olmasını sağlar, bu müşteri memnuniyetini artırır ve iş sürekliliğini korur."
            //Element
            Description.innerHTML = "Ücretsiz ve güvenilir web hosting hizmeti ile, istediğiniz internet sitesini oluşturarak dijital dünyada hızla varlık göstermeye başlayabilirsiniz."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Kaliteli bir hosting hizmeti, enerji ve bakım maliyetlerini düşürerek küçük işletmelere maliyet avantajı sağlayabilir."
            //Element
            Two.innerHTML         = "Hızlı yükleme süreleri ve düşük çalışma süreleri etkili bir hosting hizmeti ile optimize edilebilir. Böylece web siteniz daha üst sıralara çıkabilir."
            //Element
            Three.innerHTML       = "Kaliteli bir hosting yüksek uptime garantisi sunarak web sitenizin sürekli çevrimiçi olmasını sağlar, bu müşteri memnuniyetini artırır ve iş sürekliliğini korur."
            //Element
            Description.innerHTML = "Ücretsiz ve güvenilir web hosting hizmeti ile, istediğiniz internet sitesini oluşturarak dijital dünyada hızla varlık göstermeye başlayabilirsiniz."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "İyi bir hosting hizmeti, enerji maliyetlerini düşürerek orta ölçekli işletmelere maliyet avantajı sağlayabilir."
            //Element
            Two.innerHTML         = "Hızlı yükleme süreleri ve düşük çalışma süreleri, etkili bir hosting hizmeti ile optimize edilebilir. Böylece internet siteniz daha üst sıralara çıkabilir."
            //Element
            Three.innerHTML       = "Kaliteli bir hosting, yüksek uptime garantisi sunarak web sitenizin sürekli çevrimiçi olmasını sağlar, bu müşteri memnuniyetini artırır ve iş sürekliliğini korur."
            //Element
            Description.innerHTML = "Dijital varlığınızı hızla oluşturmak için ücretsiz, güvenilir web hosting hizmeti kullanarak istediğiniz internet sitesini hayata geçirebilirsiniz."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "İyi bir hosting hizmeti, yeni işletmeler için maliyet avantajı sağlayarak enerji ve bakım masraflarını azaltır."
            //Element
            Two.innerHTML         = "SEO : İçin kritik olan hızlı yükleme, düşük çalışma süreleri, etkili bir hosting hizmeti ile artırılabilir."
            //Element
            Three.innerHTML       = "Etkili bir hosting hizmeti, yüksek uptime garantisi sunarak web sitenizin sürekli olarak çevrimiçi olmasını sağlar."
            //Element
            Description.innerHTML = "Hayalinizdeki internet sitesini ücretsiz ve güvenilir bir web hosting hizmeti ile gerçeğe dönüştürün."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "İyi bir hosting hizmeti, yeni işletmeler için maliyet avantajı sağlayarak enerji ve bakım masraflarını azaltır."
            //Element
            Two.innerHTML         = "SEO için kritik olan hızlı yükleme ve düşük çalışma süreleri, etkili bir hosting hizmeti ile artırılabilir."
            //Element
            Three.innerHTML       = "Etkili bir hosting hizmeti, yüksek uptime garantisi sunarak web sitenizin sürekli olarak çevrimiçi olmasını sağlar."
            //Element
            Description.innerHTML = "Hayalinizdeki internet sitesini ücretsiz ve güvenilir bir web hosting hizmeti ile gerçeğe dönüştürün."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "İyi bir hosting hizmeti, enerji ve bakım maliyetlerini azaltarak, küçük işletmeler için maliyet avantajı sunar."
           //Element
           Two.innerHTML         = "SEO için önemli olan hızlı yükleme ve düşük çalışma süreleri, etkili bir hosting ile artırılabilir."
           //Element
           Three.innerHTML       = "İyi bir hosting, yüksek uptime garantisi sunarak web sitenizin sürekli çevrimiçi olmasını sağlar."
           //Element
           Description.innerHTML = "Ücretsiz ve güvenilir bir web hosting hizmeti ile hayalinizdeki, internet sitesini hayata geçirin."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "İyi hosting, marka güçlendirmenin yanı sıra maliyet tasarrufu sağlar. Hızlı ve güvenli altyapıyla uzun vadeli bütçenizi koruyun!"
            //Element
            Two.innerHTML         = "İyi bir hosting, markanızı güçlendirmenin yanı sıra SEO performansını artırır. Hızlı ve güvenli hostingle üst sıralara çıkın!"
            //Element
            Three.innerHTML       = "Hızlı ve güçlü hosting, markanızın çevrimiçi performansını artırır. İyi hosting seçimi, web sitenizin kusursuz performansını sağlar."
            //Element
            Description.innerHTML = "Markanızı güçlendirmek için uygun bir hosting seçin. Hızlı, güvenli ve ölçeklenebilir bir altyapıyla çevrimiçi varlığınızı en üst düzeye çıkarın!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "İyi bir hosting seçimi, markanızı güçlendirmenin yanı sıra maliyet tasarrufu sağlar. Hızlı ve güvenli bir altyapıyla uzun vadeli maliyetlerinizi minimize edin, işletme bütçenizi koruyun!"
            //Element
            Two.innerHTML         = "İyi bir hosting seçimi, markanızın güçlenmesinin yanı sıra SEO performansını da artırır. Hızlı ve güvenli hosting ile arama motorlarında üst sıralara çıkın!"
            //Element
            Three.innerHTML       = "Hızlı ve güçlü hosting, markanızın çevrimiçi performansını artırır. İyi bir hosting seçimiyle web siteniz her zaman etkileşimde kusursuz performans sergiler."
            //Element
            Description.innerHTML = "Markanızı güçlendirmek için iyi bir hosting seçimi yapın. Hızlı, güvenli ve ölçeklenebilir bir altyapıyla çevrimiçi varlığınızı maksimum düzeyde artırın!"
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Birinci Sınıf Ücretsiz Web Hosting Edinin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Ücretsiz ve güvenilir bir web hosting hizmeti ile hayalinizdeki, internet sitesini hayata geçirerek dijital dünyada kolayca varlık göstermeye başlayabilirsiniz."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Birinci Sınıf Ücretsiz Web Hosting Edinin", itemProp: "name headline" },
                                    //Element
                                    "Birinci Sınıf Ücretsiz Web ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Hosting"
                                    ),
                                    //Element
                                    " Edinin"
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Ücretsiz ve güvenilir bir web hosting hizmeti ile hayalinizdeki, internet ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "sitesini hayata geçirerek dijital dünyada kolayca varlık göstermeye ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "başlayabilirsiniz."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Birinci Sınıf Ücretsiz Web Hosting Edinin", title: "Birinci Sınıf Ücretsiz Web Hosting Edinin", src: "https://cdn.benozgurtosun.com/v1/business/573398.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Maliyet Tasarrufu", Summary: "İyi bir hosting hizmeti, enerji ve bakım maliyetlerini azaltarak, küçük işletmeler için maliyet avantajı sunabilir, bu da bulut tabanlı çözümleri daha cazip kılar.", Photo: "086921.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "SEO Performansı", Summary: "SEO için önemli olan hızlı yükleme ve düşük çalışma süreleri, etkili hosting ile artırılabilir, böylece web siteniz daha üst sıralara çıkabilir.", Photo: "264588.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Hız ve Performans", Summary: "İyi bir hosting, yüksek uptime garantisi sunarak web sitenizin sürekli çevrimiçi olmasını sağlar, bu müşteri memnuniyetini artırır ve iş sürekliliğini korur.", Photo: "602758.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Hosting;