//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Default from "../../Shortcode/Sustainability/Default";
//Install
import Headline from "../../Shortcode/Sustainability/Headline";

//Element
class Manifesto extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Manifesto : Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir. - Benozgurtosun.com"
        //Element
        Description.content = "BM Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi, Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/manifesto"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Manifesto : Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir. - Benozgurtosun.com"
        //Element
        Description.content = "BM Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi, Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/manifesto"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Manifesto : Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir. - Benozgurtosun.com"
        //Element
        Description.content = "BM Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi, Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Custom = () => {
        //Element
        let One   = document.querySelector( "#React_T7642S" )
        //Element
        let Two   = document.querySelector( "#React_D0956Q" )
        //Element
        let Three = document.querySelector( "#React_Z98P3S" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML   = "Global başarı için kilit rol oynayan faktörlerden bir topluluk bilinci ve lider katkısının sürdürülebilir kalkınma hedeflerini şekillendirmesidir."
            //Element
            Two.innerHTML   = "BM, Türkiye ile iş birliği yaparak küresel sorunlara çözüm odaklı Sürdürülebilir Kalkınma Hedefleri'ne yöneliyor."
            //Element
            Three.innerHTML = "BM Türkiye'nin sürdürülebilir kalkınma hedeflerini destekleyerek toplumsal farkındalığı artırmaya önem atfetmektedir."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma amaçlarına ulaşmada önemli bir etken olan topluluk bilinci ve lider katkısı, küresel başarı için temel faktörlerden biridir."
            //Element
            Two.innerHTML   = "BM Türkiye ile birlikte çalışarak küresel sorunlar için çözümleri hedefleyen Sürdürülebilir Kalkınma Hedefleri'ne odaklanıyor."
            //Element
            Three.innerHTML = "BM, Türkiye'nin sürdürülebilir kalkınma hedefleri için destek vererek toplumsal farkındalığı artırmaya büyük önem vermektedir."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma hedeflerine ulaşmada önemli bir etken olan topluluk bilinci ve lider katkısı, küresel başarı için temel faktörlerden biridir."
            //Element
            Two.innerHTML   = "BM, Türkiye ile birlikte çalışarak küresel sorunlar için çözümleri hedefleyen Sürdürülebilir Kalkınma Hedefleri'ne odaklanıyor."
            //Element
            Three.innerHTML = "BM, Türkiye'nin sürdürülebilir kalkınma hedefleri için destek vererek toplumsal farkındalığı artırmaya büyük önem vermektedir."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma için bu amaçları ne kadar insan bilirse o kadar başarılı olacaktır. Bunun için çabalarsak, liderlerde bunu gerçekleştirebilecektir."
            //Element
            Two.innerHTML   = "Türkiye Sürdürülebilir Kalkınma Hedeflerine, ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar Dünya'da insanların karşı karşıya kaldığı ana sorunların çözümünü hedefliyor."
            //Element
            Three.innerHTML = "Türkiye, bu amaçlara 2030 yılına kadar ulaşılması için desteğini sürdürüyor. Bu amaçlara ulaşılabilmesi için herkesin bilgilenlenmesi gerekiyor."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma için, bu hedefleri ne kadar insan bilirse o kadar başarılı olacaktır. Bunun için çabalarsak, liderlerde bunu gerçekleştirebilecektir."
            //Element
            Two.innerHTML   = "BM Sürdürülebilir Kalkınma Hedeflerine, ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar Dünya'da insanların karşı karşıya kaldığı sorunların çözümünü hedefliyor."
            //Element
            Three.innerHTML = "BM ve Türkiye, bu amaçlara 2030 yılına kadar ulaşılması için desteğini sürdürüyor. Bu amaçlara ulaşılabilmesi için herkesin bilgilenlenmesi gerekiyor."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma için, bu hedefleri ne kadar insan bilirse o kadar başarılı olacaktır. Bunun için çabalarsak, liderlerde bunu gerçekleştirebilecektir."
            //Element
            Two.innerHTML   = "BM Sürdürülebilir Kalkınma Hedeflerine, ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar Dünya'da insanların karşı karşıya kaldığı sorunların çözümünü hedefliyor."
            //Element
            Three.innerHTML = "BM ve Türkiye, bu amaçlara 2030 yılına kadar ulaşılması için desteğini sürdürüyor. Bu amaçlara ulaşılabilmesi için herkesin bilgilenlenmesi gerekiyor."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma için, bu hedefleri ne kadar insan bilirse o kadar başarılı olacaktır. Bunun için çabalarsak, liderlerde bunu gerçekleştirebilecektir."
            //Element
            Two.innerHTML   = "BM Sürdürülebilir Kalkınma Hedeflerine, ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar Dünya'da insanların karşı karşıya kaldığı sorunların çözümünü hedefliyor."
            //Element
            Three.innerHTML = "BM ve Türkiye, bu amaçlara 2030 yılına kadar ulaşılması için desteğini sürdürüyor. Bu amaçlara ulaşılabilmesi için herkesin bilgilenlenmesi gerekiyor."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma için, bu hedefleri ne kadar insan bilirse o kadar başarılı olacaktır. Bunun için çabalarsak, liderlerde bunu gerçekleştirebilecektir."
            //Element
            Two.innerHTML   = "BM Sürdürülebilir Kalkınma Hedeflerine, ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar Dünya'da insanların karşı karşıya kaldığı sorunların çözümünü hedefliyor."
            //Element
            Three.innerHTML = "BM ve Türkiye, bu amaçlara 2030 yılına kadar ulaşılması için desteğini sürdürüyor. Bu amaçlara ulaşılabilmesi için herkesin bilgilenlenmesi gerekiyor."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma hedeflerinin küresel başarısı, geniş bir topluluk bilinci arttığında daha olasıdır. Liderlerin bu hedeflere katkıda bulunmaları önemlidir."
            //Element
            Two.innerHTML   = "BM, Türkiye ile iş birliği yaparak Sürdürülebilir Kalkınma Hedefleri'ne odaklanıyor; bu hedefler, dünya genelinde temel sorunlara çözüm sunmayı amaçlıyor."
            //Element
            Three.innerHTML = "BM, Türkiye'nin 2030 sürdürülebilir kalkınma hedeflerini destekliyor ve toplumun bilinçlendirilmesini vurguluyor."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma hedeflerinin küresel başarısı, geniş bir topluluk bilinci arttığında daha olasıdır. Liderlerin bu hedeflere katkıda bulunmaları önemlidir."
            //Element
            Two.innerHTML   = "BM, Türkiye ile iş birliği yaparak Sürdürülebilir Kalkınma Hedefleri'ne odaklanıyor; bu hedefler, dünya genelinde temel sorunlara çözüm sunmayı amaçlıyor."
            //Element
            Three.innerHTML = "BM, Türkiye'nin 2030 sürdürülebilir kalkınma hedeflerini destekliyor ve toplumun bilinçlendirilmesini vurguluyor."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML   = "Sürdürülebilir kalkınma hedeflerinin küresel başarısı, geniş bir topluluk tarafından bilincinin arttığı zaman daha olasıdır. Bu nedenle, bu hedeflere ulaşmak için çaba harcamak, liderlerin de bu başarıya katkıda bulunmalarını sağlayabilir."
           //Element
           Two.innerHTML   = "BM, Türkiye ile iş birliği yaparak Sürdürülebilir Kalkınma Amaçlarına odaklanıyor; bu amaçlar, dünya genelinde insanların karşılaştığı temel sorunlara çözüm sunmayı hedefliyor."
           //Element
           Three.innerHTML = "BM, Türkiye'nin 2030'daki sürdürülebilir kalkınma hedeflerine destek veriyor ve toplumun bilinçlendirilmesini vurguluyor."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma için küresel hedeflerin başarısı, daha geniş bir topluluk tarafından bilindiğinde artar. Bu amaçla çaba sarf etmek, liderlerin de bu hedeflere ulaşmasını sağlayabilir."
            //Element
            Two.innerHTML   = "BM Türkiye İle Ortakları; Sürdürülebilir Kalkınma Amaçlarına ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar Türkiye Dahil Tüm Dünya'da insanların karşı karşıya kaldığı ana sorunların çözümünü hedefliyor."
            //Element
            Three.innerHTML = "BM, Türkiye'de 2030'a kadar sürdürülebilir kalkınma hedeflerine ulaşmaya yönelik desteğini sürdürüyor. Bu hedeflere ulaşmak için toplumun geniş kesimlerinin bilinçlendirilmesi önemlidir."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML   = "Sürdürülebilir kalkınma için, küresel amaçları ne kadar çok insan bilirse o kadar başarılı olacaktır. Eğer bunun için çabalarsak, liderlerde bunu gerçekleştirebilecektir. Amaçların, insanlar tarafından öğrenilmesi gerekiyor."
            //Element
            Two.innerHTML   = "BM Türkiye İle Ortakları; Sürdürülebilir Kalkınma Amaçlarına ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar Türkiye Dahil Tüm Dünya'da insanların karşı karşıya kaldığı ana sorunların çözümünü hedefliyor."
            //Element
            Three.innerHTML = "BM Türkiye'de bu amaçlara 2030 yılına kadar erişilebilmesi için desteğini sürdürüyor. Bunun için herkesin bilgilenlenmesi gerekiyor."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    Resposive = () => {
        //Element
        let Description = document.querySelector( "#React_I5963A" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML = "BM, Sürdürülebilir Kalkınma Amaçları Yoksulluğun Bitirilmesi, Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML = "BM, Sürdürülebilir Kalkınma Amaçları Yoksulluğun Bitirilmesi, Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML = "UN Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi, Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "BM Sürdürülebilir Kalkınma Amaçları, Yoksulluğun Sona Erdirilmesi, Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "BM Sürdürülebilir Kalkınma Hedefleri, Yoksulluğun Sona Erdirilmesi, Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML = "BM Sürdürülebilir Kalkınma Hedefleri, Yoksulluğun Sona Erdirilmesi, Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "BM Sürdürülebilir Kalkınma Hedefleri, Yoksulluğun Sona Erdirilmesi, Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML = "BM : Sürdürülebilir Kalkınma Amaçları, Yoksulluğun Bitirilmesi Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML = "Sürdürülebilir Kalkınma Amaçları, Yoksulluğun Bitirilmesi Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML = "Sürdürülebilir Kalkınma Amaçları, Yoksulluğun Bitirilmesi Çevrenin Korunması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
            //Element
            Description.innerHTML = "Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "BM : Sürdürülebilir Kalkınma Hedefleri, Yoksulluğun Sona Erdirilmesi, Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Description.innerHTML = "Sürdürülebilir Kalkınma Hedefleri Yoksulluğun Sona Erdirilmesi, Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Manifesto : Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir. - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "BM Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi, Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Resposive()
        //Element
        this.Custom()
    }
    //Element
    render() {
        //Element
        const Sustainability = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "6",
            //Element
            autoplay        : {
                //Element
                delay       : 4000
            },
            //Element
            breakpoints     : {
                //Element
                320: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                346: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                360: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                400: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                411: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                412: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                414: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                432: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                480: {
                    //Element
                    slidesPerView : 3
                },
                //Element
                504: {
                    //Element
                    slidesPerView : 4
                },
                //Element
                600: {
                    //Element
                    slidesPerView : 4
                },
                //Element
                720: {
                    //Element
                    slidesPerView : 4
                },
                //Element
                768: {
                    //Element
                    slidesPerView : 4
                },
                //Element
                1024: {
                    //Element
                    slidesPerView : 5
                },
                //Element
                1280: {
                    //Element
                    slidesPerView : 6
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Headline, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 j8w7l9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 s84j9p" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Sustainability },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "1", Title: "Yoksulluğa Son.", Photo: "001.svg", Target: "https://turkiye.un.org/tr/sdgs/1?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "2", Title: "Açlığa Son.", Photo: "002.svg", Target: "https://turkiye.un.org/tr/sdgs/2?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "3", Title: "Sağlık ve Kaliteli Yaşam.", Photo: "003.svg", Target: "https://turkiye.un.org/tr/sdgs/3?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "4", Title: "Nitelikli Eğitim", Photo: "004.svg", Target: "https://turkiye.un.org/tr/sdgs/4?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "5", Title: "Toplumsal Cinsiyet Eşitliği.", Photo: "005.svg", Target: "https://turkiye.un.org/tr/sdgs/5?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "6", Title: "Temiz Su ve Sanitasyon.", Photo: "006.svg", Target: "https://turkiye.un.org/tr/sdgs/6?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "7", Title: "Erişilebilir ve Temiz Enerji.", Photo: "007.svg", Target: "https://turkiye.un.org/tr/sdgs/7?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "8", Title: "İnsana Yakışır İş ve Ekonomik Büyüme.", Photo: "008.svg", Target: "https://turkiye.un.org/tr/sdgs/8?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "9", Title: "Sanayi, Yenilikçilik ve Altyapı.", Photo: "009.svg", Target: "https://turkiye.un.org/tr/sdgs/9?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "10", Title: "Eşitsizliklerin Azaltılması.", Photo: "010.svg", Target: "https://turkiye.un.org/tr/sdgs/10?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "11", Title: "Sürdürülebilir Şehirler ve Topluluklar.", Photo: "011.svg", Target: "https://turkiye.un.org/tr/sdgs/11?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "12", Title: "Sorumlu Üretim ve Tüketim.", Photo: "012.svg", Target: "https://turkiye.un.org/tr/sdgs/12?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "13", Title: "İklim Eylemi.", Photo: "013.svg", Target: "https://turkiye.un.org/tr/sdgs/13?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "14", Title: "Sudaki Yaşam.", Photo: "014.svg", Target: "https://turkiye.un.org/tr/sdgs/14?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "15", Title: "Karasal Yaşam.", Photo: "015.svg", Target: "https://turkiye.un.org/tr/sdgs/15?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "16", Title: "Barış, Adalet ve Güçlü Kurumlar.", Photo: "016.svg", Target: "https://turkiye.un.org/tr/sdgs/16?ref=benozgurtosun.com" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Default, { ID: "17", Title: "Amaçlar için Ortaklıklar.", Photo: "017.svg", Target: "https://turkiye.un.org/tr/sdgs/17?ref=benozgurtosun.com" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 j8w7l9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-2 n98kk4", itemScope: "itemScope", itemType: "https://schema.org/VideoObject" },
                                //Element
                                React.createElement( "iframe", { width: "560", height: "315", src: "https://www.youtube.com/embed/mBAIjieSkU4?si=1ZV6o4Y9uRMCZCHO", frameBorder: "0", allowFullScreen: true, itemProp: "video" } ),
                                //Element
                                React.createElement( "meta", { itemProp: "name", content: "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir." } ),
                                //Element
                                React.createElement( "meta", { itemProp: "description", content: "BM Sürdürülebilir Kalkınma Amaçları Yoksulluğun Sona Erdirilmesi, Çevre'nin Korunması, İklim Krizine Karşı Önlem Alınması, Refahın Adil Paylaşımı ve Barışı Hedefliyor." } ),
                                //Element
                                React.createElement( "meta", { itemProp: "uploadDate", content: "2024-01-01T08:00:00+08:00" } ),
                                //Element
                                React.createElement( "meta", { itemProp: "thumbnailUrl", content: "https://img.youtube.com/vi/mBAIjieSkU4/0.jpg" } ),
                                //Element
                                React.createElement( "meta", { itemProp: "contentUrl", content: "https://www.youtube.com/embed/mBAIjieSkU4?si=1ZV6o4Y9uRMCZCHO" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 m-0 mt-2 y76v0s" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 p5o2a6", itemProp: "mainEntityOfPage" },
                                    //Element
                                    "#HerkeseAnlat"
                                ),
                                //Element
                                React.createElement( "p", { id: "React_T7642S", className: "float-start w-100 p-0 m-0 mt-3 s194hd", itemProp: "mainEntityOfPage" },
                                    //Element
                                    "Sürdürülebilir kalkınma için, küresel amaçları ne kadar çok insan bilirse o kadar başarılı olacaktır. Eğer bunun için çabalarsak, liderlerde bunu gerçekleştirebilecektir. Amaçların, insanlar tarafından öğrenilmesi gerekiyor."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_D0956Q", className: "float-start w-100 p-0 m-0 mt-3 s194hd", itemProp: "mainEntityOfPage" },
                                    //Element
                                    "BM Türkiye İle Ortakları; Sürdürülebilir Kalkınma Amaçlarına ulaşılması için çalışıyor. Birbiri ile bağlantılı amaçlar ",
                                    //Element
                                    React.createElement( "u", {},
                                        //Element
                                        "Türkiye Dahil Tüm Dünya'da "
                                    ),
                                    //Element
                                    "insanların karşı karşıya kaldığı ana sorunların çözümünü hedefliyor."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_Z98P3S", className: "float-start w-100 p-0 m-0 mt-3 s194hd", itemProp: "mainEntityOfPage" },
                                    //Element
                                    "BM Türkiye'de bu amaçlara 2030 yılına kadar erişilebilmesi için desteğini sürdürüyor. Bu amaçlara ulaşılabilmesi için herkesin bilgilenlenmesi gerekiyor."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 u8J6o7" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "picture", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 Up955K", itemScope: "itemScope", itemType: "https://schema.org/ImageObject" },
                                //Element
                                React.createElement( "img", { alt: "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir", title: "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir", src: "https://cdn.benozgurtosun.com/v1/un/000.svg", width: "130", height: "130", itemProp: "contentUrl" } ),
                                //Element
                                React.createElement( "meta", { itemProp: "name", content: "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir" } )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Manifesto;