//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Brand extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Marka bilinirliğini artırın - Benozgurtosun.com"
        //Element
        Description.content = "Marka bilinirliğini artırmak ve işletme ile ilgilenme olasılığı olan önemli müşterilere erişmek için başlamaya hazır mısın?"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/isletme-rehberligi/bilinirligi-artirin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Marka bilinirliğini artırın - Benozgurtosun.com"
        //Element
        Description.content = "Marka bilinirliğini artırmak ve işletme ile ilgilenme olasılığı olan önemli müşterilere erişmek için başlamaya hazır mısın?"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/isletme-rehberligi/bilinirligi-artirin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Marka bilinirliğini artırın - Benozgurtosun.com"
        //Element
        Description.content = "Marka bilinirliğini artırmak ve işletme ile ilgilenme olasılığı olan önemli müşterilere erişmek için başlamaya hazır mısın?"
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "İlk izlenimleri güçlendirin! Benzersiz web tasarımı ile markanızı ön plana çıkarın ve çevrimiçi varlığınızı unutulmaz kılın."
            //Element
            Two.innerHTML         = "Marka kimliğinizi inşa edin! Kurumsal web tasarım ile markanızı öne çıkarın ve çevrimiçi dünyada kalıcı bir izlenim bırakın."
            //Element
            Three.innerHTML       = "SEO performansını artırın! Şık bir web tasarım ile, hem çekici bir görünüm elde edin hem de arama motorlarında üst sıralarda yer alın."
            //Element
            Description.innerHTML = "Marka bilinirliğinizi artırın! Kurumsal web tasarımı ile dikkat çekin ve markanızı geniş bir kitlelere tanıtın."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "İlk izlenimleri güçlendirin! Benzersiz web tasarımı ile markanızı ön plana çıkarın ve çevrimiçi varlığınızı unutulmaz kılın."
            //Element
            Two.innerHTML         = "Marka kimliğinizi inşa edin! Kurumsal web tasarım ile markanızı öne çıkarın ve çevrimiçi dünyada kalıcı bir izlenim bırakın."
            //Element
            Three.innerHTML       = "SEO performansınızı artırmak için iyi web tasarımı kullanın ve çekici bir görünüm elde edin."
            //Element
            Description.innerHTML = "Marka bilinirliğini artırın! Kurumsal web tasarım ile dikkat çekin ve markanızı geniş bir kitlelere tanıtın."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "İlk izlenimler kalıcıdır. Profesyonel bir web tasarımı ile işletmenizin dijital yüzünü güçlendirin ve markanızı unutulmaz kılın."
            //Element
            Two.innerHTML         = "Kurumsal bir marka kimliği oluşturun! Marka kimliğinizi web tasarım ile güçlendirin."
            //Element
            Three.innerHTML       = "İşletmenizin online görünürlüğünü artırın! Profesyonel web tasarım ile arama motorlarında üst sıralara çıkın ve müşterileri çekin."
            //Element
            Description.innerHTML = "Marka bilinirliğinizi en üst düzeye çıkarın! Benzersiz bir dijital varlık oluşturun ve rakiplerinizin önüne geçin."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "İlk izlenimler kalıcıdır. Profesyonel bir web tasarımı ile işletmenizin dijital yüzünü güçlendirin ve markanızı unutulmaz kılın."
            //Element
            Two.innerHTML         = "Kurumsal bir marka kimliği oluşturun! Marka kimliğinizi web tasarım ile güçlendirin."
            //Element
            Three.innerHTML       = "İşletmenizin online görünürlüğünü artırın! Profesyonel web tasarım ile arama motorlarında üst sıralara çıkın ve müşterileri çekin."
            //Element
            Description.innerHTML = "Marka bilinirliğinizi en üst düzeye çıkarın! Benzersiz bir dijital varlık oluşturun ve rakiplerinizin önüne geçin."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Güçlü bir web tasarım ziyaretçilere olumlu bir ilk izlenim bırakır. Profesyonel, düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler."
            //Element
            Two.innerHTML         = "Web tasarımı, markanızı güçlendirmek için renk, logo ve grafiklerle desteklenir, böylece dijital ortamda daha tanınabilir hale gelir."
            //Element
            Three.innerHTML       = "SEO, şık bir web tasarım ile desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motorlarındaki sıralamanızı iyileştirebilir."
            //Element
            Description.innerHTML = "Marka bilinirliğini artırmak ve işiniz ile ilgilenme olasılığı olan müşterilere erişmek için başlamaya hazır mısın?"
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Güçlü bir web tasarımı ziyaretçilere olumlu bir ilk izlenim bırakır. Profesyonel, düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler."
            //Element
            Two.innerHTML         = "Web tasarımı, markanızı güçlendirmek için renk, logo ve grafiklerle desteklenir, böylece dijital ortamda daha tanınabilir bir hale gelirsiniz."
            //Element
            Three.innerHTML       = "SEO, şık bir web tasarım ile desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motorlarındaki sıralamanızı iyileştirebilir."
            //Element
            Description.innerHTML = "Marka bilinirliğini artırmak işinizle ilgilenme olasılığı olan müşterilere erişmek için başlamaya hazır mısınız?"
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Güçlü bir web tasarımı ziyaretçilere olumlu bir ilk izlenim bırakır. Profesyonel, düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler."
            //Element
            Two.innerHTML         = "Web tasarımı, markanızı güçlendirmek için renk, logo ve grafiklerle desteklenir, böylece dijital ortamda daha tanınabilir bir hale gelirsiniz."
            //Element
            Three.innerHTML       = "SEO, şık bir web tasarım ile desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motorlarındaki sıralamanızı iyileştirebilir."
            //Element
            Description.innerHTML = "Marka bilinirliğini artırmak, işinizle ilgilenme olasılığı olan müşterilere erişmek için başlamaya hazır mısınız?"
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Güçlü bir web tasarımı ziyaretçilere olumlu bir ilk izlenim bırakır. Profesyonel, düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler."
            //Element
            Two.innerHTML         = "Web tasarımı, markanızı güçlendirmek için renk, logo ve grafiklerle desteklenir, böylece dijital ortamda daha tanınabilir bir hale gelirsiniz."
            //Element
            Three.innerHTML       = "SEO : Şık bir web tasarım ile desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motorlarındaki sıralamanızı iyileştirebilir."
            //Element
            Description.innerHTML = "Marka bilinirliğini artırmak, işinizle ilgilenme olasılığı olan müşterilere erişmek için başlamaya hazır mısınız?"
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Kurumsal bir web tasarım müşterilere olumlu bir ilk izlenim bırakır. Düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler."
            //Element
            Two.innerHTML         = "İyi web tasarımı, markanızı güçlendirmek için renk, logo ve grafiklerle desteklenir, böylece çevrimiçi ortamda daha tanınabilir hale gelir."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarımı, ile desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motoru sıralamanızı iyileştirebilirsiniz."
            //Element
            Description.innerHTML = "Marka bilinirliğinizi artırın ve yeni müşterilere ulaşın! İşinize ilgi çekici bir başlangıç yapmaya hazır mısınız?"
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Kurumsal bir web tasarım, müşterilere olumlu bir ilk izlenim bırakır. Profesyonel, düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler."
            //Element
            Two.innerHTML         = "İyi web tasarımı, markanızı güçlendirmek için renk, logo ve grafiklerle desteklenir, böylece çevrimiçi ortamda daha tanınabilir hale gelir."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarım ile desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motoru sıralamanızı iyileştirebilirsiniz."
            //Element
            Description.innerHTML = "Marka bilinirliğinizi artırın ve yeni müşterilere ulaşın! İşinize ilgi çekici bir başlangıç yapmaya hazır mısınız?"
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Profesyonel bir web tasarım, müşterilere olumlu bir ilk izlenim bırakır. Profesyonel, düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler."
           //Element
           Two.innerHTML         = "Web tasarımı, markanızı güçlendirmek için renkler, logolar ve grafiklerle desteklenir, böylece çevrimiçi ortamda daha tanınabilir hale gelir."
           //Element
           Three.innerHTML       = "Profesyonel bir web tasarım ile desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motoru sıralamanızı iyileştirebilirsiniz."
           //Element
           Description.innerHTML = "Marka bilinirliğinizi yükseltin ve yeni müşterilere ulaşın! İşinize ilgi çekici bir başlangıç yapmaya hazır mısınız?"
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "İlk izlenimleri güçlendirmek için özel web tasarımımızla tanışın! İyi ve kullanıcı dostu arayüzlerle markanızı dijital dünyada unutulmaz kılın."
            //Element
            Two.innerHTML         = "Marka kimliğinizi özgün ve çarpıcı bir şekilde hayata geçirin! Uzman web tasarım ekibimizle markanızı güçlendirin."
            //Element
            Three.innerHTML       = "İyi ve SEO dostu bir web tasarım ile işinizi dijital dünyada öne çıkarın! Bizimle tanışın, arama motorlarında yüksek performans elde edin."
            //Element
            Description.innerHTML = "Marka bilinirliğinizi artırmak için web tasarımımızı keşfedin! Estetik ve etkili tasarımlarla unutulmaz bir deneyim sunun."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "İlk izlenimleri güçlendirmek için özel web tasarımımızla tanışın. Etkileyici ve kullanıcı dostu arayüzlerle, markanızın dijital dünyada unutulmaz bir giriş yapmasını sağlayın."
            //Element
            Two.innerHTML         = "Marka kimliğinizi özgün ve çarpıcı bir şekilde hayata geçirin. Uzman web tasarım ekibimizle, markanızın güçlü bir dijital varlık kazanmasını sağlayarak öne çıkın."
            //Element
            Three.innerHTML       = "Sadece estetik değil, aynı zamanda SEO dostu! İşinizi dijital dünyada öne çıkarmak ve arama motorlarında yüksek performans elde etmek için bizimle tanışın."
            //Element
            Description.innerHTML = "Web tasarımımızla marka bilinirliğinizi artırın! Estetik ve etkili tasarımlarla hedef kitlenize unutulmaz bir deneyim sunun."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            //Element
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Marka bilinirliğini artırın - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Marka bilinirliğini artırmak ve işletme ile ilgilenme olasılığı olan önemli müşterilere erişmek için başlamaya hazır mısın?"
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Marka bilinirliğini artırın.", itemProp: "name headline" },
                                    //Element
                                    "Marka ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "bilinirliğini"
                                    ),
                                    //Element
                                    " artırın."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 text-center u04m7q", itemProp: "description" },
                                    //Element
                                    "Marka bilinirliğini artırmak ve işletme ile ilgilenme olasılığı olan önemli müşterilere",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "erişmek için başlamaya hazır mısın?"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Marka bilinirliğini artırın.", title: "Marka bilinirliğini artırın.", src: "https://cdn.benozgurtosun.com/v1/business/213287.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "İlk İzlenimlerin Güçlendirilmesi", Summary: "İyi bir web tasarım, ziyaretçilere olumlu bir ilk izlenim bırakır. Profesyonel, düzenli ve kullanıcı dostu bir tasarım, olumlu düşünceleri tetikler.", Photo: "771175.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Marka Kimliği Oluşturulması", Summary: "Web tasarımı, markanızı güçlendirmek için renkler, logolar ve grafiklerle desteklenir, böylece çevrimiçi ortamda daha tanınabilir hale gelir.", Photo: "010423.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "SEO Performansının Artırılması", Summary: "SEO, kurumsal bir web tasarımla desteklenerek web sitenizin görünürlüğünü artırabilir ve arama motorlarındaki sıralamanızı iyileştirebilir.", Photo: "771658.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Brand;