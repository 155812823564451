//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Shortcode from "../../Shortcode/Reference/Default";
//Install
import User from "../../Shortcode/Reference/User";
//Install
import Comment from "../../Shortcode/Reference/Comment";
//Install
import Brand from "../../Shortcode/Reference/Brand";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Referanslar : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        Description.content = "Merhaba, Ben Özgür Tosun 7 Farklı Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/referanslar"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Referanslar : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        Description.content = "Merhaba, Ben Özgür Tosun 7 Farklı Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/referanslar"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Referanslar : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        Description.content = "Merhaba, Ben Özgür Tosun 7 Farklı Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Comments = () => {
        //Element
        let Comment_One   = document.querySelector( "#React_0DH74F" )
        //Element
        let Comment_Two   = document.querySelector( "#React_719V0M" )
        //Element
        let Comment_Three = document.querySelector( "#React_2349TL" )
        //Element
        let Comment_Four  = document.querySelector( "#React_OB432O" )
        //Element
        let Comment_Five  = document.querySelector( "#React_2P86HG" )
        //Element
        let Comment_Six   = document.querySelector( "#React_4N28J9" )
        //Element
        let Comment_Seven = document.querySelector( "#React_W8Q1J9" )
        //Element
        let Comment_Eight = document.querySelector( "#React_2R6229" )
        //Element
        let Comment_Nine  = document.querySelector( "#React_4CV378" )
        //Element
        let Comment_Ten   = document.querySelector( "#React_P7777C" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayalimdeki web tasarımını iyi bir domain önerisiyle hayata geçirdi. Kesinlikle öneririm."
            //Element
            Comment_Two.innerHTML   = "Özgür, etkileyici bir web tasarımı ve harika iletişim becerileriyle öne çıktı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında harika işlere imza attı. Hızlı iletişimi ve olumlu müşteri geri dönüşleri için teşekkür ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Yaratıcılığı ve iletişim becerileri ile beni etkiledi. Tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey işbirliği sürecinde öne çıkan bir isimdi. Profesyonel ve yaratıcı, iyi bir sonuç elde ettik. Teşekkürler."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı harika! Profesyonelliği ve etkisi olağanüstü. Teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey beklentilerimi aşan modern ve kullanıcı dostu bir site ortaya koydu. Teşekkürler."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey şirketimizin web tasarımını hızlı esnek ve yaratıcı bir şekilde güncelledi. Teşekkür ederim."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in Google reklam danışmanlığı işimizi hızla ileri taşıdı. Profesyonel ve etkili yaklaşımına minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Özgür Bey'e kozmetik işletmemin web tasarımında gösterdiği harika çalışma için teşekkür ederim."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayalimdeki web tasarımını iyi bir domain önerisiyle hayata geçirdi. Kesinlikle öneririm."
            //Element
            Comment_Two.innerHTML   = "Özgür, etkileyici bir web tasarımı ve harika iletişim becerileriyle öne çıktı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında harika işlere imza attı. Hızlı iletişimi ve olumlu müşteri geri dönüşleri için teşekkür ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Yaratıcılığı ve iletişim becerileri ile beni etkiledi. Tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey işbirliği sürecinde öne çıkan bir isimdi. Profesyonel ve yaratıcı, iyi bir sonuç elde ettik. Teşekkürler."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı harika! Profesyonelliği ve etkisi olağanüstü. Teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey beklentilerimi aşan modern ve kullanıcı dostu bir site ortaya koydu. Teşekkürler."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirketimizin web tasarımını hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkür ederim."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in Google reklam danışmanlığı işimizi hızla ileri taşıdı. Profesyonel ve etkili yaklaşımına minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Özgür Bey'e kozmetik işletmemin web tasarımında gösterdiği harika çalışma için teşekkür ederim."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için harika bir web tasarım ve iyi bir domain önerisiyle beklentilerimi aştı. Kendisine teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetinizden memnunum. Harika bir site ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, şirket web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak sadece keyifli değil, aynı zamanda uzmanlığı sayesinde harika bir sonuç elde etmemi sağladı."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı muhteşem! Etkisi gerçekten olağanüstü. Kendisine teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey, sunduğu modern ve kullanıcı dostu web sitesi ile beklentilerimi aştı. Emeği için teşekkür ederim."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirket web tasarımımızı hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkürler."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in sunduğu Google Ads danışmanlığı sayesinde işimizde önemli bir ilerleme kaydettik. Kendisine minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Kozmetik işletmemin web tasarımında ortaya koyduğu üstün çaba ve harika iş için Özgür Bey'e teşekkürlerimi sunarım."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için harika bir web tasarım ve iyi bir domain önerisiyle beklentilerimi aştı. Kendisine teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetinizden memnunum. Harika bir site ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, şirket web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak sadece keyifli değil, aynı zamanda uzmanlığı sayesinde harika bir sonuç elde etmemi sağladı."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı muhteşem! Etkisi gerçekten olağanüstü. Kendisine teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey, sunduğu modern ve kullanıcı dostu web sitesi ile beklentilerimi aştı. Emeği için teşekkür ederim."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirket web tasarımımızı hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkürler."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in sunduğu Google Ads danışmanlığı sayesinde işimizde önemli bir ilerleme kaydettik. Kendisine minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Kozmetik işletmemin web tasarımında ortaya koyduğu üstün çaba ve harika iş için Özgür Bey'e teşekkürlerimi sunarım."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletme için harika bir web tasarım ve mükemmel bir domain önerisiyle beklentilerimi fazlasıyla karşıladı. Kendisine minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetiniz beni memnun etti. İyi bir web sitesi ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, işletme web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak sadece keyifli değil, aynı zamanda uzmanlığı sayesinde harika bir sonuç elde etmemi sağladı."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı muhteşem! Etkisi gerçekten olağanüstü oldu. Kendisine teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey, sunduğu modern ve kullanıcı dostu web sitesi ile beklentilerimi aştı. Emeği için teşekkür ederim."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirket web tasarımımızı hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkürler."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in sunduğu Google Ads danışmanlığı sayesinde işimizde önemli bir ilerleme kaydettik. Kendisine minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Kozmetik işletmemin web tasarımında gösterdiği  olağanüstü çaba ve mükemmel iş için Özgür Bey'e teşekkürlerimi sunarım."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Comment_One.innerHTML   = "Özgür, işletmem için mükemmel bir web tasarımı ve harika bir domain önerisiyle beklentilerimi fazlasıyla karşıladı. Kendisine minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetiniz beni memnun etti. İyi bir web sitesi ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, işletme web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey'in web site tasarımındaki uzmanlığı ve profesyonelliği, başarı elde etmemize önemli katkı sağladı. Teşekkürler."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı mükemmel! Etkisi gerçekten olağanüstü oldu. Kendisine teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey, sunduğu modern ve kullanıcı dostu web sitesi ile beklentilerimi aştı. Emeği için teşekkür ederim."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirket web tasarımımızı hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkürler."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in sunduğu Google Ads danışmanlığı sayesinde işimizde önemli bir ilerleme kaydettik. Kendisine minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Kozmetik işletmemin web tasarımında gösterdiği  olağanüstü çaba ve mükemmel iş için Özgür Bey'e teşekkürlerimi sunarım."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Comment_One.innerHTML   = "Özgür, işletmem için mükemmel bir web tasarımı ve harika bir domain önerisiyle beklentilerimi fazlasıyla karşıladı. Kendisine minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, web tasarım hizmetiniz beni memnun etti. İyi bir web sitesi ve profesyonel iletişim için teşekkür ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, işletme web tasarımımıza iyi bir dokunuş kattı! Olumlu müşteri geri bildirimleri alıyoruz. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel ve müşteri memnuniyetine odaklı. İletişimi harika ve sonuç beni çok memnun etti."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey'in web site tasarımındaki uzmanlığı ve profesyonelliği, başarı elde etmemize önemli katkı sağladı. Teşekkürler."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı mükemmel! Etkisi gerçekten olağanüstü oldu. Kendisine teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey, sunduğu modern ve kullanıcı dostu web sitesi ile beklentilerimi aştı. Emeği için teşekkür ederim."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirket web tasarımımızı hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkürler."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in sunduğu Google Ads danışmanlığı sayesinde işimizde önemli bir ilerleme kaydettik. Kendisine minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Kozmetik işletmemin web tasarımında gösterdiği  olağanüstü çaba ve mükemmel iş için Özgür Bey'e teşekkürlerimi sunarım."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayallerimdeki web tasarımı gerçeğe dönüştürdü ve harika bir domain önerisiyle beni etkiledi. Kesinlike tavsiye ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür muhteşem bir internet sitesi tasarladı ve iletişimdeki profesyonelliği benzersizdi. Kesinlikle tavsiye ediyorum."
            //Element
            Comment_Three.innerHTML = "Özgür web tasarımında harikalar yarattı. İletişim hızlı ve müşteri geri dönüşleri çok olumlu. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonelliği, yaratıcılığı ve iletişim becerileri gerçekten etkileyici. Tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey'le çalışmak keyifliydi. Profesyonelliği ve tasarım yetenekleri sayesinde mükemmel bir sonuç elde ettik. Teşekkür ederim."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı mükemmel! Etkisi gerçekten olağanüstü oldu. Kendisine teşekkür ediyoruz."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey, sunduğu modern ve kullanıcı dostu web sitesi ile beklentilerimi aştı. Emeği için teşekkür ederim."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirket web tasarımımızı hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkürler."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in sunduğu Google Ads danışmanlığı sayesinde işimizde önemli bir ilerleme kaydettik. Kendisine minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Kozmetik işletmemin web tasarımında gösterdiği  olağanüstü çaba ve mükemmel iş için Özgür Bey'e teşekkürlerimi sunarım."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayal ettiğim web tasarımını sundu ve mükemmel bir domain önerisiyle şaşırttı. Profesyonelliği ve yaratıcılığı için minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, muhteşem bir web sitesi tasarladı ve iletişimdeki profesyonelliği benzersizdi. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında harikalar yarattın! Müşteri geri dönüşleri pozitif ve iletişim hızlı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel, yaratıcı ve iletişim becerileri harika. Kesinlikle tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey ile işbirliği keyifliydi. Profesyonelliği ve tasarım yetenekleri mükemmel bir sonuç elde etmemize katkı sağladı. Teşekkürler."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı harika! Profesyonel ve etkili iletişimi, işimize olumlu bir katkı sağladı."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey'in web tasarımı etkileyici! Beklentilerimi aşan modern ve kullanıcı dostu bir site sundu. Teşekkürler!"
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirketimizin web tasarımını hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkür ederim."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in Google reklam danışmanlığı, işimizi anında ileri taşıdı. Profesyonel ve etkili yaklaşımı için minnettarım."
            //Element
            Comment_Ten.innerHTML   = "Kozmetik işletmemin web tasarımını harika bir şekilde tamamlayan Özgür Bey'e teşekkür ederim. Hızlı ve etkili iletişimi işleri kolaylaştırdı."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, hayal ettiğim web tasarımını sundu ve mükemmel bir domain önerisiyle şaşırttı. Profesyonelliği ve yaratıcılığı için minnettarım."
            //Element
            Comment_Two.innerHTML   = "Özgür, muhteşem bir web sitesi tasarladı ve iletişimdeki profesyonelliği benzersizdi. Kesinlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, web tasarımında harikalar yarattın! Müşteri geri dönüşleri pozitif ve iletişim hızlı. Kesinlikle tavsiye ederim."
            //Element
            Comment_Four.innerHTML  = "Özgür, mükemmel bir web tasarımcı! Profesyonel, yaratıcı ve iletişim becerileri harika. Kesinlikle tavsiye ederim."
            //Element
            Comment_Five.innerHTML  = "Özgür Bey ile işbirliği keyifliydi. Profesyonelliği ve tasarım yetenekleri mükemmel bir sonuç elde etmemize katkı sağladı. Teşekkürler."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığından çok memnunum. Profesyonel ve etkili iletişimi, işimize olumlu bir katkı sağladı."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey'in web tasarım yetenekleri beni etkiledi. Beklentilerimi aşan modern ve kullanıcı dostu bir web sitesi oluşturdu. Teşekkürler."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirketimizin web tasarımını hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkürler."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in Google reklam danışmanlığı, işimizi hızla büyüttü. Profesyonel ve etkili yaklaşımı için teşekkür ederim."
            //Element
            Comment_Ten.innerHTML   = "Özgür Bey, kozmetik işletmemin web tasarımını mükemmel bir şekilde tamamladı. Hızlı ve etkili iletişimi için teşekkür ederim."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           Comment_One.innerHTML   = "Özgür Bey, sunduğu benzersiz web tasarımı ve ideal domain önerisiyle beklentilerimi aştı. Profesyonelliği ve yaratıcılığı için kendisine teşekkür ederim."
           //Element
           Comment_Two.innerHTML   = "Özgür, harika bir web sitesi tasarladın ve iletişimdeki profesyonellik takdire değerdi. Sizi memnuniyetle tavsiye ederim."
           //Element
           Comment_Three.innerHTML = "Özgür, web tasarımında harika bir iş çıkardın. Müşteri geri dönüşleri olumlu ve iletişim hızlıydı. Kesinlikle tavsiye ederim."
           //Element
           Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcı! Profesyonel, yaratıcı ve sonuç odaklı. İletişimi mükemmel. Kesinlikle tavsiye ederim."
           //Element
           Comment_Five.innerHTML  = "Özgür ile işbirliği keyifliydi. Profesyonelliği ve tasarım yetenekleri mükemmel bir sonuç elde etmemize katkı sağladı. Teşekkürler."
           //Element
           Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığından son derece memnunum. Profesyonel tutumu ve etkili iletişimi, işimize olumlu bir etki sağladı."
           //Element
           Comment_Seven.innerHTML = "Özgür Bey'in web tasarım yetenekleri beni etkiledi. Modern ve kullanıcı dostu web sitesi beklentilerimi aştı. Teşekkür ederim."
           //Element
           Comment_Eight.innerHTML = "Özgür Bey, şirketimizin web tasarımını hızlı, esnek ve yaratıcı bir şekilde güncelledi. Teşekkür ederim."
           //Element
           Comment_Nine.innerHTML  = "Özgür Bey'in Google reklam danışmanlığı, işimizi hızla büyüttü. Profesyonel stratejileri ve etkili yaklaşımı sayesinde başarıyı yakaladık. Teşekkürler."
           //Element
           Comment_Ten.innerHTML   = "Özgür Bey, kozmetik işletmemin web tasarımını mükemmel bir şekilde tamamladı. İletişimde başarılı ve hızlı çözümler sunan bir uzman. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için sunduğunuz özel web tasarımı ve harika domain önerisi beni etkiledi. Profesyonellik ve yaratıcılığınız için teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür'ün işletmem için hazırladığı web sitesine bayıldım! Harika bir site ve profesyonel yaklaşımıyla beni etkiledi. İçtenlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, kurumsal web tasarımımızı yeniden şekillendirdiğin için minnettarız! İletişim becerilerin ve işbirliği anlayışın sayesinde projemiz çok başarılı oldu. Teşekkürler."
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcısı! Profesyonellik, yaratıcılık ve müşteri memnuniyeti konusundaki yetenekleri beni çok etkiledi. Çok memnun kaldım. Teşekkürler."
            //Element
            Comment_Five.innerHTML  = "Özgür ile çalışmak inanılmazdı! Web sitemin tasarımındaki ustalığı ve yaratıcılığı sayesinde mükemmel bir sonuç elde ettik. Teşekkürler."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığından çok memnunum. Profesyonel yaklaşımı ve etkili iletişimi, işimize olumlu bir etki sağladı. Teşekkürler."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey'in web tasarım becerileri beni etkiledi. Modern ve kullanıcı dostu web sitesi beklentilerimi aştı. Teşekkür ederim."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirketimizin web tasarımını özgün ve etkileyici bir şekilde güncelledi. Hızlı, esnek ve yaratıcı yaklaşımıyla projeyi başarıyla tamamladı. Teşekkür ederim."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in Google reklam danışmanlığı, işimizin büyüme hızını artırdı. Profesyonel stratejileri ve etkili yaklaşımı, işimizi önemli ölçüde ileri taşıdı."
            //Element
            Comment_Ten.innerHTML   = "Özgür Bey, kozmetik işletmemin web tasarımını mükemmel bir şekilde tamamladı. İletişimde başarılı, hızlı çözümler sunan bir uzman. Teşekkürler."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Comment_One.innerHTML   = "Özgür Bey, işletmem için özel bir web tasarımı ve mükemmel bir domain önerisiyle beni şaşırttı. Profesyonelliğiniz ve yaratıcılığınız için teşekkür ederim."
            //Element
            Comment_Two.innerHTML   = "Özgür web tasarımınıza hayran kaldım. Oluşturduğunuz harika web sitesi ve gösterdiğiniz profesyonellik beni etkiledi. Sizi içtenlikle tavsiye ederim."
            //Element
            Comment_Three.innerHTML = "Özgür, kurumsal web tasarımımızı yeniden şekillendirdiğin için minnettarız! İletişim becerilerin ve işbirliği anlayışın sayesinde projemiz çok başarılı oldu. Teşekkür ederim!"
            //Element
            Comment_Four.innerHTML  = "Özgür, harika bir web tasarımcısı! Profesyonellik, yaratıcılık ve müşteri memnuniyeti konusundaki yetenekleri beni çok etkiledi. Sonuçtan fazlasıyla memnun kaldım."
            //Element
            Comment_Five.innerHTML  = "Özgür'le işbirliği yapmak muhteşemdi! Web sitemin tasarımındaki profesyonellik ve yetenekleri sayesinde harika bir sonuç aldık. Teşekkür ederim."
            //Element
            Comment_Six.innerHTML   = "Özgür'ün Google Ads danışmanlığı hizmetinden çok memnunum. Profesyonel yaklaşımı ve etkili iletişimi, işimize olumlu bir etki sağladı."
            //Element
            Comment_Seven.innerHTML = "Özgür Bey'in web tasarım becerileri beni etkiledi. Hazırladığı modern ve kullanıcı dostu web sitesi, beklentilerimi aştı. Teşekkürler."
            //Element
            Comment_Eight.innerHTML = "Özgür Bey, şirketimizin web tasarımını benzersiz ve etkileyici bir düzeye taşıdı. Hızlı, esnek ve yaratıcı yaklaşımıyla projeyi başarıyla tamamladı."
            //Element
            Comment_Nine.innerHTML  = "Özgür Bey'in Google reklam danışmanlığı, işimizin büyüme hızını artırdı. Profesyonel stratejileri ve etkili yaklaşımı, işimizi önemli ölçüde ileri taşıdı."
            //Element
            Comment_Ten.innerHTML   = "Özgür Bey, kozmetik işletmemin web tasarımını mükemmel bir şekilde hayata geçirdi. İletişimde başarılı, hızlı çözümler sunan bir uzman. Teşekkür ederim."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    Resposive = () => {
        //Element
        let Description = document.querySelector( "#React_U5024C" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Gururu İle Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
             //Element
             Description.innerHTML = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun 68 Farklı Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           Description.innerHTML = "Ben Özgür Tosun, 7 Bölge'de, 68 Şehir'de, 5.000 İşletme İçin Hizmet Sunabilmenin Mutluluğu İle, Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "Ben Özgür Tosun; 7 Bölge'de, 68 Şehir'de, 5.000 İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Description.innerHTML = "Merhaba, Ben Özgür Tosun 7 Bölge'de, 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Gururu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Referanslar : 68 Farklı Şehir'de. 5.000 Farklı İşletme. - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Merhaba, Ben Özgür Tosun 7 Farklı Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Resposive()
        //Element
        this.Comments()
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "2",
            //Element
            autoplay        : {
                //Element
                delay       : 3000
            },
            //Element
            breakpoints     : {
                //Element
                320: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                360: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                480: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                768: {
                    //Element
                    slidesPerView : 2
                },
                //Element
                1024: {
                    //Element
                    slidesPerView : 2
                },
                //Element
                1280: {
                    //Element
                    slidesPerView : 2
                }
            },
            //Element
            loop            : true,
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Mark = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            pagination      : {
                //Element
                clickable   : true
            },
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            breakpoints     : {
                //Element
                320: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                360: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                480: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                768: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024: {
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280: {
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Brands = {
            //Element
            spaceBetween    : "20.25",
            //Element
            slidesPerView   : "9",
            //Element
            autoplay        : {
                //Element
                delay       : 3000
            },
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                384:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                390:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                393:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                400:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                411:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                432:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 4
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                720:{
                    //Element
                    slidesPerView : 5
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 8
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 8
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 9
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Bugün 68 Farklı Şehir'de. 5.000 Farklı İşletme.", itemProp: "name alternateName" },
                                    //Element
                                    "Bugün ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "68"
                                    ),
                                    //Element
                                    " Farklı Şehir'de. ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "5.000"
                                    ),
                                    //Element
                                    " Farklı İşletme."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_U5024C", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Merhaba, Ben Özgür Tosun 7 Farklı Bölge'de, 68 Şehir'de, 5.000+ Farklı İşletme",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "Geride Bıraktım."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 pt-5 pb-4 m-0 a555ki" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Form Sünger", Photo: "001.webp", Target: "https://www.formsunger.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "GSD Marin", Photo: "002.webp", Target: "https://www.gsdmarin.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Intimissimi", Photo: "022.webp", Target: "https://www.intimissimi.com/tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "BYD Türkiye", Photo: "021.webp", Target: "https://www.bydauto.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Europap", Photo: "003.webp", Target: "https://www.tezol.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Pürsu", Photo: "020.webp", Target: "https://www.pursu.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Kalekim", Photo: "004.webp", Target: "https://www.kalekim.com/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "HES Kablo", Photo: "010.webp", Target: "https://www.hes.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Seranit", Photo: "005.webp", Target: "https://www.seranit.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "ZES", Photo: "006.webp", Target: "https://zes.net/ev" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Delphi", Photo: "007.webp", Target: "https://www.delphiautoparts.com/tr" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Universal", Photo: "008.webp", Target: "https://universaltextile.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Tören", Photo: "009.webp", Target: "https://www.toren.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Kilim Mobilya", Photo: "011.webp", Target: "https://www.kilimmobilya.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Çelikel", Photo: "012.webp", Target: "https://celikel.com/tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "World Medicine", Photo: "013.webp", Target: "https://www.worldmedicine.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Demir Export", Photo: "014.webp", Target: "https://www.demirexport.com/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Cansan", Photo: "015.webp", Target: "https://www.cansan.com.tr/tr/anasayfa/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Vaden Original", Photo: "016.webp", Target: "https://vaden.com.tr/tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Entek", Photo: "017.webp", Target: "https://www.entekelektrik.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Şahinler Group", Photo: "018.webp", Target: "https://www.sahinler.com.tr/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Shortcode, { ID: "0", Title: "Çamlı Yem", Photo: "019.webp", Target: "https://www.camli.com.tr/" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 j0r112" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Ahmet Delioğlu", Photo: "006.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Nejdet Baş", Photo: "007.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Bünyamin Baykal", Photo: "008.webp", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Nur Hendekli", Photo: "009.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Doğu Dost Onural", Photo: "002.webp", Target: "/" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 h68k88" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Mark },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_0DH74F", User: "Ömer Araz", Firm: "Viennalife Emeklilik ve Hayat A.Ş.", Photo: "005.webp", Text: "Özgür bey, işletmeme özel harika bir web tasarımı ve ideal bir domain önerisiyle beklentilerimi aştı. Profesyonelliği ve yaratıcılığı için kendisine teşekkür ederim.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_719V0M", User: "Cemgil Kaya", Firm: "Still Arser İş Makinaları Servis ve Tic. A.Ş.", Photo: "004.webp", Text: "Özgür, web tasarım hizmetinizden son derece memnun kaldım. Harika bir web sitesi oluşturdunuz ve iletişimdeki profesyonellik takdire değerdi. Sizi gönül rahatlığıyla tavsiye ederim.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_2349TL", User: "Meral Kamberoğlu", Firm: "Korozo Ambalaj San. ve Tic. A.Ş.", Photo: "003.webp", Text: "Özgür, şirketimizin web tasarımında dokunuşunu kattı ve sonuç harika! Müşterilerden olumlu geri dönüşler alıyoruz. İletişimi hızlı ve işbirliği keyifliydi. Hiç tereddüt etmeden tavsiye ederim.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_OB432O", User: "Doğu Dost Onural", Firm: "Karcher Servis Ticaret A.Ş.", Photo: "002.webp", Text: "Özgür, harika bir web tasarımcısı! Profesyonel, yaratıcı ve müşteri memnuniyetine odaklı bir yaklaşımı var. İletişimi mükemmel ve sonuç beni çok memnun etti. Kesinlikle tavsiye ederim.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_2P86HG", User: "İhsan Aydoğdu", Firm: "Turpak Elektromanyetik Yakıt İkmal Sis. Tic. A.Ş.", Photo: "001.webp", Text: "Özgür ile işbirliği yapmak gerçekten keyifliydi. Web sitemin tasarımındaki yetenekleri ve profesyonelliği, mükemmel bir sonuca ulaşmamıza katkı sağladı. Teşekkürler.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_4N28J9", User: "Nejdet Baş", Firm: "Akçelik Reklamcılık San. Tic. A.Ş.", Photo: "007.webp", Text: "Özgür'den aldığım Google Ads danışmanlığı hizmetinden çok memnun kaldım. Profesyonel yaklaşımı, etkili iletişimi ve detaylı analizleriyle işimizi olumlu yönde etkiledi.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_W8Q1J9", User: "Nur Hendekli", Firm: "Eyüboğlu Eğitim Kurumları Limited Şirketi", Photo: "009.webp", Text: "Özgür Bey, web tasarım konusundaki uzmanlığıyla beni etkiledi. Özel okulum için hazırladığı modern ve kullanıcı dostu web sitesi, beklentilerimi aştı. Teşekkürler.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_2R6229", User: "Melih Şahinöz", Firm: "Tıkır Makina San. ve Tic. Ltd. Şti.", Photo: "015.webp", Text: "Özgür Bey, şirketimizin web tasarımını benzersiz ve çarpıcı bir hale getirdi. Hızlı, esnek ve yaratıcı yaklaşımıyla projeyi başarıyla tamamladı.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_4CV378", User: "Özlem Altun", Firm: "Erzin Elektromekanik İnş. San. ve Tic. Ltd. Şti.", Photo: "013.webp", Text: "Özgür Bey'in Google reklam danışmanlığı, işimizin ivmesini artırdı. Etkin stratejileri ve profesyonel yaklaşımı, kitlemizi genişleterek işimizi önemli ölçüde ileri taşıdı. Teşekkürler.", Target: "/on-bilgilendirme-talebi" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Comment, { ID: "React_P7777C", User: "Elçin Önel", Firm: "Quantum Beauty Kozmetik", Photo: "014.webp", Text: "Özgür Bey, kozmetik işletmemin web tasarımını mükemmel bir şekilde gerçekleştirdi. İletişimde başarılı, hızlı çözümler sunan ve estetik anlamda beklentilerimi tam anlamıyla karşılayan bir uzman.", Target: "/on-bilgilendirme-talebi" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Cemgil Kaya", Photo: "004.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "İhsan Aydoğdu", Photo: "001.webp", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 m-0 p2769m" },
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Özlem Altun", Photo: "013.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Elçin Önel", Photo: "014.webp", Target: "/" } ),
                                        //Element
                                        React.createElement( User, { ID: "0", Title: "Melih Şahinöz", Photo: "015.webp", Target: "/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e8336u" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Brands },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "1", Title: "Ceyda Alev", Photo: "001.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "2", Title: "Ataer Emlak", Photo: "002.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "3", Title: "Demircioğlu İnşaat", Photo: "003.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "4", Title: "Demirler Hukuk", Photo: "004.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "5", Title: "Melekler Elektrik", Photo: "005.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "6", Title: "Deryalar", Photo: "006.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "7", Title: "Fabrika İnşaat", Photo: "007.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "8", Title: "Günal Lojistik", Photo: "008.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "9", Title: "Mine Tekstil", Photo: "010.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "10", Title: "Türker İlaç", Photo: "009.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "11", Title: "Baks Motor", Photo: "011.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "12", Title: "NOTA", Photo: "012.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "13", Title: "Emin Emlak", Photo: "013.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "14", Title: "Akallar Kırtasiye", Photo: "014.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "15", Title: "Filizler", Photo: "015.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "16", Title: "Nispetiye Hukuk", Photo: "016.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "17", Title: "Hayla Grup", Photo: "017.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "18", Title: "MSGR", Photo: "018.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "19", Title: "MakineX", Photo: "019.webp", Target: "/on-bilgilendirme-talebi" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Brand, { ID: "20", Title: "Sul Yapı", Photo: "020.webp", Target: "/on-bilgilendirme-talebi" } )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;