//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Connect extends Component {
    //Element
    Call = () => {
        //Element
        window.location.href = "tel:+905349204144"
    }
    //Element
    WhatsApp = () => {
        //Element
        window.location.href = "https://api.whatsapp.com/send/?phone=905349204144&text=Merhaba&type=phone_number&app_absent=0"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mb-4 w057op" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "button", { id: "React_BJ895Q", name: "React_BJ895Q", className: "d-flex w-100 justify-content-between align-items-center p-0 ps-4 m-0 mb-3 r307z9", type: "button", onClick: this.Call },
                        //Element
                        "Hemen Ara"
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "button", { className: "d-flex w-100 justify-content-between align-items-center p-0 ps-4 m-0 mb-3 bs0p47", type: "button", onClick: this.WhatsApp },
                        //Element
                        "WhatsApp'a Bağlan"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Connect;