//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Shortcode from "../../Shortcode/Competition/Default";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Fark Yaratan Rekabetçi Sektörler Programı - Benozgurtosun.com"
        //Element
        Description.content = "Rekabetçi Sektörler Programı, işletmelere özel tasarlanmış profesyonel bir web sitesi ile dijital ortamda önemli bir rekabet avantajı sunar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/rekabet-programi"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Fark Yaratan Rekabetçi Sektörler Programı - Benozgurtosun.com"
        //Element
        Description.content = "Rekabetçi Sektörler Programı, işletmelere özel tasarlanmış profesyonel bir web sitesi ile dijital ortamda önemli bir rekabet avantajı sunar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/rekabet-programi"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Fark Yaratan Rekabetçi Sektörler Programı - Benozgurtosun.com"
        //Element
        Description.content = "Rekabetçi Sektörler Programı, işletmelere özel tasarlanmış profesyonel bir web sitesi ile dijital ortamda önemli bir rekabet avantajı sunar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Resposive = () => {
        //Element
        let Description = document.querySelector( "#React_8699E1" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için özel bir dijital varlık oluşturmak için web tasarım planları sunar."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için özel bir dijital varlık oluşturmak için web tasarım planları sunar."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için güçlü bir dijital varlık oluşturmak için planlar sunar."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için tasarlanmış kurumsal bir web sitesi ile dijital ortamda önemli bir rekabet avantajı sunar."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için tasarlanmış kurumsal bir web sitesi ile dijital ortamda önemli bir rekabet avantajı sunar."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı: İşletmelere, kurumsal bir web tasarımı ile önemli bir rekabet avantajı sunar."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı: İşletmelere, kurumsal bir web tasarımı ile önemli bir rekabet avantajı sunar."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı : İşletmelere, kurumsal bir web tasarımı ile önemli bir rekabet avantajı sunar."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için özel bir dijital varlık oluşturmak için özel web tasarım planları sunar."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için özel bir dijital varlık oluşturmak için özel web tasarım planları sunar."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           Description.innerHTML = "Rekabetçi Sektörler Programı, işletmeler için çarpıcı bir dijital varlık oluşturmak için özel web tasarım planları sunar."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, profesyonel bir web sitesi tasarımı ile işletmelere dijital platformda dikkat çekme fırsatı sunar."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Description.innerHTML = "Rekabetçi Sektörler Programı, özelleştirilmiş profesyonel web tasarımı ile işletmelere dijital alanda öne çekçe fırsatı sunar."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Fark Yaratan Rekabetçi Sektörler Programı - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Rekabetçi Sektörler Programı, işletmelere özel tasarlanmış profesyonel bir web sitesi ile dijital ortamda önemli bir rekabet avantajı sunar."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Resposive()
        //Element
        let Space = document.getElementsByClassName( "hv89h7" )[0]
        //Element
        Space.classList.remove( "mt-5" )
        //Element
        Space.classList.add( "mt-4" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Fark Yaratan Rekabetçi Sektörler Programı", itemProp: "name alternateName" },
                                    //Element
                                    "Fark Yaratan ",
                                    //Element
                                    "Rekabetçi ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Sektörler"
                                    ),
                                    //Element
                                    " Programı"
                                ),
                                //Element
                                React.createElement( "p", { id: "React_8699E1", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Rekabetçi Sektörler Programı, işletmelere özel tasarlanmış profesyonel bir ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    " web sitesi ile dijital ortamda önemli bir rekabet avantajı sunar."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 k84mu0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "01", Title: "Otomotiv", Photo: "001.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "02", Title: "İnşaat ve Taahhüt", Photo: "002.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "03", Title: "Sağlık", Photo: "003.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "04", Title: "Tekstil", Photo: "004.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "05", Title: "Turizm", Photo: "005.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "06", Title: "Mobilya", Photo: "006.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "07", Title: "Tarım", Photo: "007.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "08", Title: "Gıda", Photo: "008.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "09", Title: "Perakende", Photo: "009.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "10", Title: "Elektrik ve Elektronik", Photo: "010.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "11", Title: "Lojistik", Photo: "011.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "12", Title: "Finans", Photo: "012.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "13", Title: "Sigorta", Photo: "013.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "14", Title: "Gayrimenkul", Photo: "014.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "15", Title: "e-Ticaret", Photo: "015.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "16", Title: "Makina ve Parça İmalatı", Photo: "016.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "17", Title: "Toptan Ticaret", Photo: "017.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "18", Title: "Restoranlar", Photo: "018.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "19", Title: "Eğlence", Photo: "019.svg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Shortcode, { ID: "20", Title: "Dayanıklı Tüketim", Photo: "020.svg", Target: "/" } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;