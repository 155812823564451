//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
class Legal extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Kişisel Verilerin Korunmasına İlişkin Önlemler - Benozgurtosun.com"
        //Element
        Description.content = "İşbu Aydınlatma Metni 6698 sayılı kişisel verilerin korunması kanunu ile alakalı mevzuata uygun olarak veri sorumlusu nezdinde işlenmesine ilişkin esasları içermektedir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/kisisel-verilerin-korunmasi"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Kişisel Verilerin Korunmasına İlişkin Önlemler - Benozgurtosun.com"
        //Element
        Description.content = "İşbu Aydınlatma Metni 6698 sayılı kişisel verilerin korunması kanunu ile alakalı mevzuata uygun olarak veri sorumlusu nezdinde işlenmesine ilişkin esasları içermektedir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/kisisel-verilerin-korunmasi"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Kişisel Verilerin Korunmasına İlişkin Önlemler - Benozgurtosun.com"
        //Element
        Description.content = "İşbu Aydınlatma Metni 6698 sayılı kişisel verilerin korunması kanunu ile alakalı mevzuata uygun olarak veri sorumlusu nezdinde işlenmesine ilişkin esasları içermektedir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Sub = document.querySelector( "#React_P287ZJ" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Sub.innerHTML = "Bu metin, 6698. sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak işlenen kişisel verilere dair esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Sub.innerHTML = "Bu metin, 6698. sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak işlenen kişisel verilere dair esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Sub.innerHTML = "Bu, Aydınlatma Metni 6698 Kişisel Verilerin Korunması Kanunu ile alakalı esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Sub.innerHTML = "İş bu, Aydınlatma Metni 6698 Kişisel Verilerin Korunması Kanunu ile alakalı esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Sub.innerHTML = "Bu, Aydınlatma Metni 6698 Kişisel Verilerin Korunması Kanunu ile alakalı esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Sub.innerHTML = "İş bu Aydınlatma Metni 6698 Kişisel Verilerin Korunması Kanunu ile alakalı esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Sub.innerHTML = "İş bu Aydınlatma Metni 6698 Kişisel Verilerin Korunması Kanunu ile alakalı esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Sub.innerHTML = "İş bu, Aydınlatma Metni 6698 Kişisel Verilerin Korunması Kanunu ile alakalı esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Sub.innerHTML = "Bu metin, 6698 sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak veri sorumlusu tarafından işlenen kişisel verilere ilişkin esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Sub.innerHTML = "Bu metin, 6698 sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak veri sorumlusu tarafından işlenen kişisel verilere ilişkin esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Sub.innerHTML = "İş bu, Aydınlatma Metni 6698 sayılı kişisel verilerin korunması kanunu ile alakalı mevzuata uygun olarak veri sorumlusu nezdinde işlenmesine ilişkin esasları içermektedir."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Kişisel Verilerin Korunmasına İlişkin Önlemler - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "İşbu Aydınlatma Metni 6698 sayılı kişisel verilerin korunması kanunu ile alakalı mevzuata uygun olarak veri sorumlusu nezdinde işlenmesine ilişkin esasları içermektedir."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Kişisel Verilerin Korunmasına İlişkin Önlemler", itemProp: "name alternateName" },
                                    //Element
                                    "Kişisel Verilerin ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Korunmasına"
                                    ),
                                    //Element
                                    " İlişkin Önlemler"
                                ),
                                //Element
                                React.createElement( "p", { id: "React_P287ZJ", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "İşbu Aydınlatma Metni 6698 sayılı kişisel verilerin korunması kanunu ile alakalı ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "mevzuata uygun olarak veri sorumlusu nezdinde işlenmesine ilişkin ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "esasları içermektedir."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 y0422f" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"6 offset-md-4" },
                            //Element
                            React.createElement( "ul", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 z493ea" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kişisel Verileri Korunma Politikası", href: "https://cdn.benozgurtosun.com/kvkk/001.pdf" },
                                        //Element
                                        "Kişisel Verileri Korunma Politikası"
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Çerez Politikaları", href: "https://cdn.benozgurtosun.com/kvkk/002.pdf" },
                                        //Element
                                        "Çerez Politikaları"
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Başvuru Formu", href: "https://cdn.benozgurtosun.com/kvkk/003.pdf" },
                                        //Element
                                        "Başvuru Formu"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Legal;