//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Mail } from "../../../Media/Icon/008.svg";
//Install
import propTypes from "prop-types";

//Element
class Email extends Component {
    //Element
    Target = () => {
        //Element
        window.location.href = "mailto:info@benozgurtosun.com"
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-5 m-0 bg-transparent border-0 n5x508", type: "button", onClick: this.Target },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 a9680l" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 y347yn" },
                    //Element
                    React.createElement( Mail, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 pb-4 m-0 mt-4 text-start rm263w" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-4 text-start t456c4" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Email.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Email;