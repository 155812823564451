//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Company extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Şirketler İçin - Benozgurtosun.com"
        //Element
        Description.content = "İnternet üzerinde marka bilinirliği oluşturarak, markanızı geniş bir çevrimiçi kitleye tanıtın ve potansiyel müşterilere daha etkili bir şekilde ulaşın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/sirketler-icin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Şirketler İçin - Benozgurtosun.com"
        //Element
        Description.content = "İnternet üzerinde marka bilinirliği oluşturarak, markanızı geniş bir çevrimiçi kitleye tanıtın ve potansiyel müşterilere daha etkili bir şekilde ulaşın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/sirketler-icin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Şirketler İçin - Benozgurtosun.com"
        //Element
        Description.content = "İnternet üzerinde marka bilinirliği oluşturarak, markanızı geniş bir çevrimiçi kitleye tanıtın ve potansiyel müşterilere daha etkili bir şekilde ulaşın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        let Heading           = document.querySelector( ".o49df7" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Kurumsal kimliğinizi güçlendirin! İyi bir tasarım ile markanızı öne çıkarın ve etkileyici bir dijital deneyim sunun."
            //Element
            Two.innerHTML         = "Küresel erişim kazanın! İyi bir web site tasarımı ile markanızı dünya genelinde tanıtın ve uluslararası bir kitleye ulaşın."
            //Element
            Three.innerHTML       = "Şık bir web sitesi tasarımı ürün ve hizmetleri etkili bir şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
            //Element
            Description.innerHTML = "Dijital'de güçlü bir marka kimliği için, web tasarım ile tanışın! Bilinirliğinizi maksimum seviyeye çıkarın."
            //Element
            Heading.innerHTML     = "Dijital Ortamda Marka Bilinirliğini Oluşturun."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Kurumsal kimliğinizi güçlendirin! İyi bir tasarım ile markanızı öne çıkarın ve etkileyici bir dijital deneyim sunun."
            //Element
            Two.innerHTML         = "Küresel erişim kazanın! İyi bir web site tasarımı ile markanızı dünya genelinde tanıtın ve uluslararası bir kitleye ulaşın."
            //Element
            Three.innerHTML       = "Şık bir web sitesi tasarımı ürün ve hizmetleri etkili bir şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
            //Element
            Description.innerHTML = "Dijital'de güçlü bir marka kimliği için, web tasarımı ile tanışın! Bilinirliğinizi maksimum seviyeye çıkarın."
            //Element
            Heading.innerHTML     = "Dijital Ortamda Marka Bilinirliğini Oluşturun."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Kurumsal kimliğinizi güçlendirin! Güçlü bir web tasarım ile markanızı öne çıkarın ve etkileyici bir dijital deneyim sunun."
            //Element
            Two.innerHTML         = "Küresel erişim kazanın! Profesyonel bir web tasarım ile markanızı dünya genelinde tanıtın ve uluslararası kitlelere ulaşın."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarımı ürün ve hizmetleri etkileyici bir şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
            //Element
            Description.innerHTML = "Dijital'de güçlü bir marka kimliği için, web tasarım ile tanışın! Bilinirliğinizi maksimum seviyeye çıkarın."
            //Element
            Heading.innerHTML     = "Dijital Ortamda Marka Bilinirliğini Oluşturun."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Kurumsal kimliğinizi güçlendirin! Güçlü bir web tasarım ile markanızı öne çıkarın ve etkileyici bir dijital deneyim sunun."
            //Element
            Two.innerHTML         = "Küresel erişim kazanın! Profesyonel bir web tasarım ile markanızı dünya genelinde tanıtın ve uluslararası kitlelere ulaşın."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarımı ürün ve hizmetleri etkileyici bir şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
            //Element
            Description.innerHTML = "Dijital'de güçlü bir marka kimliği için, web tasarım ile tanışın! Bilinirliğinizi maksimum seviyeye çıkarın."
            //Element
            Heading.innerHTML     = "Dijital Ortamda Marka Bilinirliğini Oluşturun."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Web tasarım işletmenizin kurumsal kimliğini yansıtan bir araçtır. Renk şemaları, logo ve genel tasarım marka imajınızı güçlendirerek etkili bir şekilde tanıtır."
            //Element
            Two.innerHTML         = "Bir web sitesi işinizi global ölçekte genişletme ve dünya çapındaki müşterilere ulaşma şansı sunarak rekabet avantajı elde etmenize imkan tanır."
            //Element
            Three.innerHTML       = "Bir internet sitesi, şirketinizi online dünyada öne çıkaran etkileyici bir vitrin olarak hizmet verir."
            //Element
            Description.innerHTML = "İnternet'te marka kimliği oluşturarak markanızı geniş bir dijital kitleye tanıtın ve müşterilere daha etkili bir şekilde ulaşın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Web tasarım işletmenizin kurumsal kimliğini yansıtan bir araçtır. Renk şemaları, logo ve genel tasarım marka imajınızı güçlendirerek etkili bir şekilde tanıtır."
            //Element
            Two.innerHTML         = "Bir web sitesi işinizi global ölçekte genişletme ve dünya çapındaki müşterilere ulaşma şansı sunarak rekabet avantajı elde etmenize imkan tanır."
            //Element
            Three.innerHTML       = "Bir internet sitesi, şirketinizi online dünyada öne çıkaran etkileyici bir vitrin olarak hizmet verir."
            //Element
            Description.innerHTML = "Marka kimliğinizi internet'te oluşturarak markanızı geniş bir dijital kitleye tanıtın ve müşterilere daha etkili bir şekilde ulaşın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Web tasarım işletmenizin kurumsal kimliğini yansıtan bir araçtır. Renk şemaları, logo ve genel tasarım marka imajınızı güçlendirerek etkili bir şekilde tanıtır."
            //Element
            Two.innerHTML         = "Bir web sitesi işinizi global ölçekte genişletme ve dünya çapındaki müşterilere ulaşma şansı sunarak rekabet avantajı elde etmenize imkan tanır."
            //Element
            Three.innerHTML       = "Bir internet sitesi, şirketinizi online dünyada öne çıkaran etkileyici bir vitrin olarak hizmet verir."
            //Element
            Description.innerHTML = "Marka kimliğinizi internet'te oluşturarak markanızı geniş bir dijital kitleye tanıtın ve müşterilere daha etkili bir şekilde ulaşın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Web tasarım, işletmenizin kurumsal kimliğini yansıtan bir araçtır. Renk şemaları, logo ve genel tasarım marka imajınızı güçlendirerek etkili bir şekilde tanıtır."
            //Element
            Two.innerHTML         = "Bir web sitesi işinizi küresel ölçekte genişletme ve dünya çapındaki müşterilere ulaşma şansı sunarak rekabet avantajı elde etmenize imkan tanır."
            //Element
            Three.innerHTML       = "Bir internet sitesi, şirketinizi online dünyada öne çıkaran etkileyici bir vitrin olarak hizmet verir."
            //Element
            Description.innerHTML = "Marka kimliğinizi internet'te oluşturarak markanızı geniş bir dijital kitleye tanıtın ve müşterilere daha etkili bir şekilde ulaşın."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Benzersiz ve estetik bir web sitesi müşterilere güven verir, marka bilinirliğinizi artırır."
            //Element
            Two.innerHTML         = "İyi web tasarım, şirketinizin sınırlarını aşarak küresel erişim sağlar. Önemli müşteriler ile dünya çapında bağlantı kurun, işinizi uluslararası arenada güçlendirin."
            //Element
            Three.innerHTML       = "Profesyonel bir web site tasarımı ürün ve hizmetlerini etkili şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
            //Element
            Description.innerHTML = "Çevrimiçi ortamda güçlü bir marka kimliği için web tasarım tanışın! Bilinirliğinizi maksimum seviyeye çıkarın."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Benzersiz ve estetik bir web sitesi, müşterilere güven verir, marka bilinirliğinizi artırır."
            //Element
            Two.innerHTML         = "İyi web tasarım, şirketinizin sınırlarını aşarak küresel erişim sağlar. Önemli müşteriler ile dünya çapında bağlantı kurun, işinizi uluslararası arenada güçlendirin."
            //Element
            Three.innerHTML       = "Profesyonel bir web site tasarımı, ürün ve hizmetlerini etkili şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
            //Element
            Description.innerHTML = "Çevrimiçi ortamda güçlü bir marka kimliği için web tasarım tanışın! Bilinirliğinizi maksimum seviyeye çıkarın."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Profesyonel ve estetik bir web sitesi, müşterilere güven verir, marka bilinirliğinizi artırır."
           //Element
           Two.innerHTML         = "İyi bir web tasarım, şirketinizin sınırlarını aşarak küresel erişim sağlar. Potansiyel müşterilerinizle dünya çapında bağlantı kurun, işinizi uluslararası arenada güçlendirin."
           //Element
           Three.innerHTML       = "Profesyonel bir web site tasarımı, ürün ve hizmetlerinizi etkili şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
           //Element
           Description.innerHTML = "Dijital ortamda güçlü bir marka oluşturmak için, web tasarım ile tanışın! Bilinirliğinizi maksimum seviyeye çıkarın."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Profesyonel ve estetik bir web sitesi, müşterilere güven verir, marka bilinirliğinizi artırır."
            //Element
            Two.innerHTML         = "İyi bir web tasarımı, şirketinizin sınırlarını aşarak küresel erişim sağlar. Potansiyel müşterilerinizle dünya çapında bağlantı kurun, işinizi uluslararası arenada güçlendirin."
            //Element
            Three.innerHTML       = "Profesyonel bir web site tasarımı, ürün ve hizmetlerinizi etkili şekilde sunarak müşteri bağını güçlendirir ve çevrimiçi başarıyı destekler."
            //Element
            Description.innerHTML = "Şirketinizi online güçlü bir şekilde temsil etmek ve çekici bir izlenim bırakmak için profesyonel web tasarımı şart!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Şirketinizin güçlü kurumsal kimliği, etkileyici bir web tasarımı ile çevrimiçi platformlarda öne çıkabilir. Profesyonel ve estetik bir web sitesi, müşterilere güven verir ve marka bilinirliğinizi artırır."
            //Element
            Two.innerHTML         = "Küresel erişim için etkileyici bir web tasarım, şirketinizin sınırları aşmasını sağlar. Potansiyel müşterilerinizle dünya çapında bağlantı kurarak küresel bir izlenim bırakabilir ve işinizi uluslararası arenada güçlendirebilirsiniz."
            //Element
            Three.innerHTML       = "Ürün ve hizmetlerinizi etkili bir şekilde tanıtabilmek için profesyonel bir web tasarımı, müşterilerinizle daha güçlü bir bağ kurmanızı ve işinizi çevrimiçi platformlarda başarıyla temsil etmenizi sağlar."
            //Element
            Description.innerHTML = "Şirketinizi çevrimiçi dünyada güçlü bir şekilde temsil etmek ve potansiyel müşterilere çekici bir ilk izlenim bırakmak için profesyonel bir web tasarımı kaçınılmaz bir yatırımdır."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Şirketler İçin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "İnternet üzerinde marka bilinirliği oluşturarak, markanızı geniş bir çevrimiçi kitleye tanıtın ve potansiyel müşterilere daha etkili bir şekilde ulaşın."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Çevrimiçi Ortamda Marka Bilinirliğini Oluşturun.", itemProp: "name headline" },
                                    //Element
                                    "Çevrimiçi Ortamda ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Marka"
                                    ),
                                    //Element
                                    " Bilinirliğini Oluşturun."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "İnternet üzerinde marka bilinirliği oluşturarak, markanızı geniş bir çevrimiçi kitleye",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "tanıtın ve potansiyel müşterilere daha etkili bir şekilde ulaşın."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Çevrimiçi Ortamda Marka Bilinirliğini Oluşturun.", title: "Çevrimiçi Ortamda Marka Bilinirliğini Oluşturun.", src: "https://cdn.benozgurtosun.com/v1/business/105656.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Kurumsal Kimliği Güçlendirme", Summary: "Web tasarım, şirketin kurumsal kimliğini yansıtan bir araçtır. Renk şemaları, logo ve genel tasarım marka imajınızı güçlendirerek etkili bir şekilde tanıtır.", Photo: "834858.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Küresel Erişim", Summary: "Web sitesi, dünya çapındaki müşterilere ulaşmanıza olanak tanır, işinizi küresel ölçekte genişletme ve uluslararası pazarlarda rekabet etme şansı verir.", Photo: "346442.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Ürün ve Hizmet Tanıtımı", Summary: "Web sitesi, şirketinizi dijital dünyada öne çıkaran bir vitrindir. Görsel ve metin içeriğiyle özelliklerinizi hızlı ve çekici bir şekilde sunabilirsiniz.", Photo: "320527.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Company;