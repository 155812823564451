//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Medium extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Orta Ölçekli İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "Yeni müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda benimle beraber adım adım ilerleyin."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/orta-olcekli-isletmeler-icin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Orta Ölçekli İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "Yeni müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda benimle beraber adım adım ilerleyin."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/orta-olcekli-isletmeler-icin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Orta Ölçekli İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "Yeni müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda benimle beraber adım adım ilerleyin."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Markanızı öne çıkarın! Şık bir web site tasarımı ile etkileyici bir dijital varlık oluşturun ve müşteri çekin."
            //Element
            Two.innerHTML         = "Rekabet avantajı kazanın! İyi bir web site tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin."
            //Element
            Three.innerHTML       = "Arama motorlarında öne çıkın! İyi bir web tasarım ile hem dikkat çekin hem de SEO dostu bir dijital varlık oluşturun."
            //Element
            Description.innerHTML = "Yeni müşteriler edinin ve büyüyün! Güçlü bir web tasarım ile işinizi önemli kitlelere tanıtın."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Markanızı öne çıkarın! Şık bir web site tasarımı ile etkileyici bir dijital varlık oluşturun ve müşteri çekin."
            //Element
            Two.innerHTML         = "Rekabet avantajı kazanın! İyi bir web site tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin."
            //Element
            Three.innerHTML       = "Arama motorlarında öne çıkın! İyi bir web tasarım ile hem dikkat çekin hem de SEO dostu bir dijital varlık oluşturun."
            //Element
            Description.innerHTML = "Yeni müşteriler edinin ve büyüyün! Güçlü bir web tasarım ile işinizi önemli kitlelere tanıtın."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Markanızı ön plana çıkarın! Benzersiz bir web tasarımı ile etkileyici bir dijital varlık oluşturun."
            //Element
            Two.innerHTML         = "Rekabet avantajı edinin! Güçlü bir web site tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin."
            //Element
            Three.innerHTML       = "Arama motorlarında ön plana çıkın! iyi bir web tasarımı ile hem dikkat çekin hem de SEO dostu bir çevrimiçi varlık oluşturun."
            //Element
            Description.innerHTML = "Önemli müşteriler edinin, büyüyün! Web tasarım ile işinizi önemli kitlelere tanıtın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Markanızı ön plana çıkarın! Benzersiz bir web tasarımı ile etkileyici bir dijital varlık oluşturun."
            //Element
            Two.innerHTML         = "Rekabet avantajı edinin! Güçlü bir web site tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin."
            //Element
            Three.innerHTML       = "Arama motorlarında ön plana çıkın! iyi bir web tasarımı ile hem dikkat çekin hem de SEO dostu bir çevrimiçi varlık oluşturun."
            //Element
            Description.innerHTML = "Önemli müşteriler edinin, büyüyün! Web tasarım ile işinizi önemli kitlelere tanıtın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Web siteniz, markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi doğru şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "Dikkat çekici bir web tasarımı, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "Arama motorları, internet sitelerini sıralamak için çeşitli kriterleri değerlendirir ve iyi bir web tasarım, bu kriterlere uygun olarak optimize edilebilir."
            //Element
            Description.innerHTML = "Önemli müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda ilerleyin."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Web siteniz, markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi doğru şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "Dikkat çekici bir web tasarımı, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "Arama motorları, internet sitelerini sıralamak için çeşitli kriterleri değerlendirir ve iyi bir web tasarım, bu kriterlere uygun olarak optimize edilebilir."
            //Element
            Description.innerHTML = "Önemli müşteriler bulun ve işletmenizi büyütün! Hedef, kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda ilerleyin."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Web siteniz, markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi doğru şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "Dikkat çekici bir web tasarımı, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "Arama motorları, internet sitelerini sıralamak için çeşitli kriterleri değerlendirir ve iyi bir web tasarım, bu kriterlere uygun olarak optimize edilebilir."
            //Element
            Description.innerHTML = "Önemli müşteriler bulun ve işletmenizi büyütün! Hedef kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda ilerleyin."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Web siteniz, markanızın online vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi doğru şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "Dikkat çekici bir web tasarımı, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "Arama motorları, internet sitelerini sıralamak için çeşitli kriterleri değerlendirir ve iyi bir web tasarım, bu kriterlere uygun olarak optimize edilebilir."
            //Element
            Description.innerHTML = "Önemli müşteriler bulun ve işletmenizi büyütün! Hedef kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda ilerleyin."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "İnternet siteniz markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi iyi şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "İyi web tasarım, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "İşletmenizin web sitesini optimize etmek, üst sıralarda yer almanıza ve daha fazla müşteri çekmenize katkı sağlar."
            //Element
            Description.innerHTML = "Yeni müşteriler bulun işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun ve rekabet avantajınızı artırın."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "İnternet siteniz markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi iyi şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "İyi web tasarım, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "İşletmenizin web sitesini optimize etmek, üst sıralarda yer almanıza ve daha fazla müşteri çekmenize katkı sağlar."
            //Element
            Description.innerHTML = "Yeni müşteriler bulun, işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun ve rekabet avantajınızı artırın."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "İnternet siteniz markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi iyi şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
           //Element
           Two.innerHTML         = "Etkileyici bir web tasarımı, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
           //Element
           Three.innerHTML       = "Web sitenizi arama motorları için optimize etmek, işletmenizin görünürlüğünü artırarak üst sıralarda yer almanıza ve daha fazla müşteri çekmenize katkı sağlar."
           //Element
           Description.innerHTML = "Yeni müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun ve rekabet avantajınızı artırın."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Markanızı geniş kitlelere tanıtmak, dijital varlığınızı güçlendirmek için özel bir web tasarımı, işletmenizin başarısını artırır."
            //Element
            Two.innerHTML         = "Dikkat çekmek ve rakiplerinizin önüne geçmek için özel web tasarımı, işletmenize kritik bir rekabet avantajı sunar."
            //Element
            Three.innerHTML       = "Arama motorlarında üst sıralarda yer almak için web sitenizi optimize etmek, işletmenizin görünürlüğünü artırarak daha fazla müşteri çekmenize katkı sağlar."
            //Element
            Description.innerHTML = "Özel bir web sitesi, işletmenizin çevrimiçi varlığını güçlendirir, marka güvenilirliğini artırır ve rekabet avantajı sağlar."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Markanızı geniş kitlelere tanıtmak ve online varlığınızı güçlendirmek için özel bir web tasarımı, işletmenizin başarısını artırmanın anahtarıdır."
            //Element
            Two.innerHTML         = "Rakiplerinizden öne geçmek ve dikkat çekmek için özel bir web tasarımı, işletmenize önemli bir rekabet avantajı sağlar."
            //Element
            Three.innerHTML       = "Web sitenizi optimize ederek arama motorlarında üst sıralara çıkmak, işletmenizin görünürlüğünü artırır ve daha fazla müşteri çekmenizi sağlar."
            //Element
            Description.innerHTML = "İşletmenizin çevrimiçi varlığını güçlendirmek ve müşterilere etkili bir şekilde ulaşmak için özel bir web sitesi, markanızın güvenilirliğini artırır ve rekabet avantajı sağlar."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Orta Ölçekli İşletmeler İçin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Yeni müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun, rekabet avantajınızı artırın ve başarıya giden yolda benimle beraber adım adım ilerleyin."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Yeni Müşteriler Kazanın, Büyüyün ve İlerleyin.", itemProp: "name headline" },
                                    //Element
                                    "Yeni Müşteriler Kazanın, ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Büyüyün"
                                    ),
                                    //Element
                                    " ve İlerleyin."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Yeni müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "kurun, rekabet avantajınızı artırın ve başarıya giden yolda benimle beraber",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "adım adım ilerleyin."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Yeni Müşteriler Kazanın, Büyüyün ve İlerleyin.", title: "Yeni Müşteriler Kazanın, Büyüyün ve İlerleyin.", src: "https://cdn.benozgurtosun.com/v1/business/296131.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Marka Tanıtımı", Summary: "Web siteniz, markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi doğru şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir.", Photo: "451249.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Rekabet Avantajı", Summary: "Etkileyici bir web tasarım, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker.", Photo: "961454.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Arama Motoru Optimizasyonu", Summary: "Arama motorları, web sitelerini sıralamak için çeşitli kriterleri değerlendirir ve iyi bir web tasarım, bu kriterlere uygun olarak optimize edilebilir.", Photo: "726494.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Medium;