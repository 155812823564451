//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Headline extends Component {
    //Element
    Target = () => {
        //Element
        window.location.href = "/on-bilgilendirme-talebi"
    }
    //Element
    Connect = () => {
        //Element
        window.location.href = "/referanslar"
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Photo, Target } = this.props
        //Element
        let Source = "https://benozgurtosun.s3.eu-north-1.amazonaws.com/video/"
        //Element
        let Video  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 w542au", itemScope: "itemScope", itemType: "https://schema.org/SpecialAnnouncement" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 pi4981" },
                        //Element
                        React.createElement( "video", { className: "float-start w-100 h-auto", playsInline: "playsinline", src: Video, controls: "controls", loop: true, autoPlay: true, muted: "muted", itemProp: "video" } ),
                    )
                ),
                //Element
                React.createElement( Col, { md:5 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 v7q534" },
                        //Element
                        React.createElement( "h1", { className: "float-start w-auto p-0 m-0 bg-transparent border-0 m6083e", itemProp: "name alternateName", title: Title },
                            //Element
                            "Tam İstediğiniz Gibi Bir ",
                            //Element
                            React.createElement( "label", {},
                                //Element
                                "Site"
                            ),
                            //Element
                            " Oluşturabilirim."
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 mb-1 e745l2", itemProp: "description text" },
                            //Element
                            "Ben Özgür Tosun 7 Farklı Bölge'de, 68+ Şehir'de, 5.000+ Farklı İşletme İçin ",
                            //Element
                            React.createElement( "br", {} ),
                            //Element
                            "Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı ",
                            //Element
                            React.createElement( "br", {} ),
                            //Element
                            "Geride Bıraktım."
                        ),
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 d01iq4" },
                            //Element
                            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-3 k51l81", type: "button", onClick: this.Target },
                                //Element
                                "Bilgilendirme Talebi"
                            ),
                            //Element
                            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-3 ms-3 b584xj", type: "button", onClick: this.Connect },
                                //Element
                                "Hemen Keşfet"
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:7 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 qcc560" },
                        //Element
                        React.createElement( "video", { className: "float-start w-100 h-auto", playsInline: "playsinline", src: Video, controls: "controls", loop: true, autoPlay: true, muted: "muted", itemProp: "video" } ),
                    )
                ),
                //Element
                React.createElement( "div", { itemProp: "spatialCoverage", itemType: "https://schema.org/AdministrativeArea", itemScope: "itemScope" },
                    //Element
                    React.createElement( "meta", { itemProp: "name", content: "Özgür Tosun, TR" } ),
                ),
                //Element
                React.createElement( "meta", { itemProp: "datePosted", content: "2024-01-01T08:00" } ),
                //Element
                React.createElement( "meta", { itemProp: "expires", content: "2020-02-05T08:00" } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Headline;