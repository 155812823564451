//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Comment extends Component {
    //Element
    render() {
        //Element
        const { ID, User, Firm, Photo, Text, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/user/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 d8q835", itemScope: "itemScope", itemType: "https://schema.org/Review", href: Target },
            //Element
            React.createElement( "div", { itemType: "https://schema.org/Product", itemProp: "itemReviewed", itemScope: "itemScope" },
                //Element
                React.createElement( "meta", { itemProp: "name", content: "Web Tasarım" } ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-5 m-0 y62e43" },
                    //Element
                    React.createElement( "p", { id: ID, className: "float-start w-auto p-0 m-0 mt-5 mb-5 q892wd", itemProp: "reviewBody" },
                        //Element
                        Text
                    )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 ps-5 pe-5 m-0 ja605u" },
                    //Element
                    React.createElement( "picture", { className: "d-inline-block w-auto position-relative h33p0j" },
                        //Element
                        React.createElement( "img", { alt: User, title: User, src: Image, width: "150", height: "150", itemProp: "image" } )
                    ),
                    //Element
                    React.createElement( "div", { className: "d-flex w-auto flex-column p-0 ps-4 pt-3 m-0 mt-5 z48376" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1 t53z62" },
                            //Element
                            User
                        ),
                        //Element
                        React.createElement( "label", { className: "float-start w-auto p-0 m-0 mt-1 mb-1 o1m43l", itemProp: "alternateName" },
                            //Element
                            Firm
                        )
                    )
                ),
                //Element
                React.createElement( "div", { itemType: "https://schema.org/Offer", itemProp: "offers", itemScope: "itemScope" },
                    //Element
                    React.createElement( "meta", { itemProp: "url", content: "https://benozgurtosun.com/ucretlendirme" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "availability", content: "https://schema.org/InStock" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "priceCurrency", content: "TRY" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "itemCondition", content: "" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "price", content: "16.000" } ),
                    //Element
                    React.createElement( "meta", { itemProp: "priceValidUntil", content: "2025-01-01" } )
                )
            ),
            //Element
            React.createElement( "div", { itemType: "https://schema.org/Rating", itemProp: "reviewRating", itemScope: "itemScope" },
                //Element
                React.createElement( "meta", { itemProp: "ratingValue", content: "4" } )
            ),
            //Element
            React.createElement( "div", { itemType: "https://schema.org/Person", itemProp: "author", itemScope: "itemScope" },
                //Element
                React.createElement( "meta", { itemProp: "name", content: User } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Comment.propTypes = {
    ID     : propTypes.string.isRequired,
    User   : propTypes.string.isRequired,
    Firm   : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Text   : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Comment;