//Install
import React, { Component } from "react";

//Element
class Design extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 z93q7k" },
            //Element
            React.createElement( "h4", { className: "float-start w-auto p-0 m-0 lh-1 c28g97", title: "İnternet: İşletmeler için Başarısının Anahtarı" },
                //Element
                "İnternet: İşletmeler için ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Başarısının"
                ),
                //Element
                " Anahtarı!"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 qo3320" },
                //Element
                "İşletmenizin online varlığını güçlendirmek ve müşteri kitlenizi genişletmek için ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "profesyonel bir web tasarım ile sınırları zorlayın."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Design;