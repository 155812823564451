//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class SSL extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : İnsanlara Güvenilir Olduğunuzu Gösterin - Benozgurtosun.com"
        //Element
        Description.content = "SSL Sertifikaları; internet sitesi verilerinizi giriş bilgilerinden, kredi kartı numaralarına kadar, tümüyle şifreleyerek koruma sağlar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/ssl"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : İnsanlara Güvenilir Olduğunuzu Gösterin - Benozgurtosun.com"
        //Element
        Description.content = "SSL Sertifikaları; internet sitesi verilerinizi giriş bilgilerinden, kredi kartı numaralarına kadar, tümüyle şifreleyerek koruma sağlar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/ssl"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : İnsanlara Güvenilir Olduğunuzu Gösterin - Benozgurtosun.com"
        //Element
        Description.content = "SSL Sertifikaları; internet sitesi verilerinizi giriş bilgilerinden, kredi kartı numaralarına kadar, tümüyle şifreleyerek koruma sağlar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : İnsanlara Güvenilir Olduğunuzu Gösterin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "SSL Sertifikaları; internet sitesi verilerinizi giriş bilgilerinden, kredi kartı numaralarına kadar, tümüyle şifreleyerek koruma sağlar."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "İnsanlara Güvenilir Olduğunuzu Gösterin.", itemProp: "name headline" },
                                    //Element
                                    "İnsanlara ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Güvenilir"
                                    ),
                                    //Element
                                    " Olduğunuzu Gösterin."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "SSL Sertifikaları; internet sitesi verilerinizi giriş bilgilerinden, kredi kartı ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "numaralarına kadar, tümüyle şifreleyerek koruma sağlar. "
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "İnsanlara Güvenilir Olduğunuzu Gösterin.", title: "İnsanlara Güvenilir Olduğunuzu Gösterin.", src: "https://cdn.benozgurtosun.com/v1/business/424630.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "0", Title: "Güvenilirlik ve Güvenlik", Summary: "SSL sertifikaları, web sitenizin güvenilir olduğunu ve kullanıcı verilerini koruduğunu gösterir. Bu, müşterilerin sitenizle güvenli bir şekilde etkileşimde bulunmalarını sağlar.", Photo: "406409.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "0", Title: "Veri Şifreleme", Summary: "SSL Sertifikaları, web sitenizle etkileşimde olan kullanıcıların verilerini şifreleyerek, özel bilgilerin izinsiz erişimlere karşı korunmasını sağlar.", Photo: "858073.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "0", Title: "Marka Güvenilirliği", Summary: "SSL Sertifikaları işletmenizin çevrimiçi güvenilirliğini artırarak müşterilerin güvenli alışveriş yapmalarını sağlar.", Photo: "266531.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SSL;