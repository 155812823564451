//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Startup extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Yeni İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "Yeni bir işletme için; önemli müşteriler bulma, işletmenin başarısını ve büyümesini doğrudan etkileyen kritik bir stratejidir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/yeni-isletmeler-icin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Yeni İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "Yeni bir işletme için; önemli müşteriler bulma, işletmenin başarısını ve büyümesini doğrudan etkileyen kritik bir stratejidir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/yeni-isletmeler-icin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Yeni İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "Yeni bir işletme için; önemli müşteriler bulma, işletmenin başarısını ve büyümesini doğrudan etkileyen kritik bir stratejidir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        let Heading           = document.querySelector( ".o49df7" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlendiren, benzersiz bir dijital deneyim sunmak için web tasarımı şart."
            //Element
            Two.innerHTML         = "Kurumsal kimliğinizi yansıtan, şık bir web tasarım ile işletmenizi öne çıkarın."
            //Element
            Three.innerHTML       = "İyi bir web site tasarımı ile yeni müşterilere ulaşın. Müşterilere güvenilir bir imaj sunmak için iyi tasarımın detaylarını keşfedin!"
            //Element
            Description.innerHTML = "İşletmenizin dijital başarısı için web tasarımı şart. Kurumsal bir site ile müşterileri etkileyin."
            //Element
            Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlendiren, benzersiz bir dijital deneyim sunmak için web tasarımı şart."
            //Element
            Two.innerHTML         = "Kurumsal kimliğinizi yansıtan, şık bir web tasarım ile işletmenizi öne çıkarın."
            //Element
            Three.innerHTML       = "İyi bir web site tasarımı ile yeni müşterilere ulaşın. Müşterilere güvenilir bir imaj sunmak için iyi tasarımın detaylarını keşfedin!"
            //Element
            Description.innerHTML = "İşletmenizin dijital başarısı için web tasarımı şart. Kurumsal bir site ile müşterileri etkileyin."
            //Element
            Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlendiren! Dijital dünyada etkileyici bir iz bırakmak için web tasarım şart."
            //Element
            Two.innerHTML         = "Kurumsal kimliğinizi yansıtan, güçlü bir web tasarım ile işletmenizi öne çıkarın."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarım ile önemli müşterilere ulaşın. Müşterilere güvenilir bir imaj sunmak için iyi tasarımın detaylarını keşfedin!"
            //Element
            Description.innerHTML = "İşletmenizin çevrimiçi başarısı için web tasarım şart. Kurumsal bir site ile müşterileri etkileyin."
            //Element
            Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
           //Element
           One.innerHTML         = "Marka kimliğinizi güçlendiren! Dijital dünyada etkileyici bir iz bırakmak için web tasarım şart."
           //Element
           Two.innerHTML         = "Kurumsal kimliğinizi yansıtan, güçlü bir web tasarım ile işletmenizi öne çıkarın."
           //Element
           Three.innerHTML       = "Kurumsal bir web tasarım ile önemli müşterilere ulaşın. Müşterilere güvenilir bir imaj sunmak için iyi tasarımın detaylarını keşfedin!"
           //Element
           Description.innerHTML = "İşletmenizin çevrimiçi başarısı için web tasarım şart. Kurumsal bir site ile müşterileri etkileyin."
           //Element
           Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Web tasarım işletmenizin marka bilinirliğini artırmak için etkili bir araçtır. Renk, logo ve tasarım markanızın tanıtımını güçlendirir ve akılda kalıcı kılar."
            //Element
            Two.innerHTML         = "Profesyonel bir web site tasarımı, işletmenizin kaliteli ve güvenilir olduğu imajını hemen oluşturur ve müşterilerin ilk bakışta olumlu bir etki almasını sağlar."
            //Element
            Three.innerHTML       = "Kurumsal bir web site tasarımı kurumsal kimliğinizi etkili bir şekilde yansıtarak potansiyel müşterilerle kalıcı ve derin bağlantılar kurma şansı sunar."
            //Element
            Description.innerHTML = "Yeni bir işletme için, önemli müşteriler bulma işletmenin başarısını ve büyümesini doğrudan etkileyen kritik bir stratejidir."
            //Element
            Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Web tasarım işletmenizin marka bilinirliğini artırmak için etkili bir araçtır. Renk, logo ve tasarım markanızın tanıtımını güçlendirir ve akılda kalıcı kılar."
            //Element
            Two.innerHTML         = "Profesyonel bir web site tasarımı, işletmenizin kaliteli ve güvenilir olduğu imajını oluşturur ve müşterilerin ilk bakışta olumlu bir etki almasını sağlar."
            //Element
            Three.innerHTML       = "Kurumsal bir web site tasarım, kurumsal kimliğinizi etkili bir şekilde yansıtarak önemli müşterilerle kalıcı ve derin bağlantılar kurma şansı sunar."
            //Element
            Description.innerHTML = "Yeni bir işletme için önemli müşteriler bulmak işletmenin başarısını ve büyümesini doğrudan etkileyen önemli müşterileri bulmaktır."
            //Element
            Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Web tasarım işletmenizin marka bilinirliğini artırmak için etkili bir araçtır. Renk, logo ve tasarım markanızın tanıtımını güçlendirir ve akılda kalıcı kılar."
            //Element
            Two.innerHTML         = "Profesyonel bir web site tasarımı, işletmenizin kaliteli ve güvenilir olduğu imajını oluşturur ve müşterilerin ilk bakışta olumlu bir etki almasını sağlar."
            //Element
            Three.innerHTML       = "Kurumsal bir web site tasarımı, kurumsal kimliğinizi etkili bir şekilde yansıtarak önemli müşterilerle kalıcı ve derin bağlantılar kurma şansı sunar."
            //Element
            Description.innerHTML = "Yeni bir işletme için önemli müşteriler bulmak işletmenin başarısını ve büyümesini doğrudan etkileyen önemli müşterileri bulmaktır."
            //Element
            Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Web tasarımı, işletmenizin marka bilinirliğini artırmak için etkili bir araçtır. Renk, logo ve tasarım markanızın tanıtımını güçlendirir ve akılda kalıcı kılar."
            //Element
            Two.innerHTML         = "Profesyonel bir web site tasarımı, işletmenizin kaliteli ve güvenilir olduğu imajını oluşturur ve müşterilerin ilk bakışta olumlu bir etki almasını sağlar."
            //Element
            Three.innerHTML       = "Kurumsal bir web site tasarımı, kurumsal kimliğinizi etkili bir şekilde yansıtarak önemli müşterilerle kalıcı ve derin bağlantılar kurma şansı sunar."
            //Element
            Description.innerHTML = "Yeni bir işletme için, önemli müşteriler bulmak işletmenin başarısını ve büyümesini doğrudan etkileyen önemli müşterileri bulmaktır."
            //Element
            Heading.innerHTML     = "Yeni İşletmen İçin Yeni Müşteriler Bulmalısın."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlendirmek ve etkili bir dijital deneyim sunmak için profesyonel web tasarımı şart."
            //Element
            Two.innerHTML         = "Kurumsal kimliğinizi yansıtan, profesyonel bir web tasarım ile işletmenizi öne çıkarın."
            //Element
            Three.innerHTML       = "Etkileyici bir web site tasarım ile önemli müşterilere ulaşın. Potansiyel müşterilere çekici ve güvenilir bir imaj sunmak için profesyonel tasarımın detaylarını keşfedin!"
            //Element
            Description.innerHTML = "İşletmenizin dijital başarısı için web tasarım şart. Kurumsal ve benzersiz bir site ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlendirmek ve etkili bir dijital deneyim sunmak için profesyonel web tasarımı şart."
            //Element
            Two.innerHTML         = "Kurumsal kimliğinizi yansıtan, profesyonel bir web tasarım ile işletmenizi öne çıkarın."
            //Element
            Three.innerHTML       = "Etkileyici bir web site tasarımı ile önemli müşterilere ulaşın. Potansiyel müşterilere çekici ve güvenilir bir imaj sunmak için profesyonel tasarımın detaylarını keşfedin!"
            //Element
            Description.innerHTML = "İşletmenizin dijital başarısı için web tasarım şart. Kurumsal, çekici ve benzersiz bir site ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Marka kimliğinizi güçlendirmek ve etkileyici bir dijital deneyim sunmak için profesyonel web tasarımı şart."
           //Element
           Two.innerHTML         = "Kurumsal kimliğinizi yansıtan, profesyonel bir web tasarım ile işletmenizi öne çıkarın."
           //Element
           Three.innerHTML       = "Etkileyici bir web site tasarımı ile önemli müşterilere ulaşın. Potansiyel müşterilere çekici ve güvenilir bir imaj sunmak için profesyonel tasarımın detaylarını keşfedin!"
           //Element
           Description.innerHTML = "İşletmenizin online başarısı için web tasarımı şart. Kurumsal, çekici ve benzersiz bir site ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlendirmek ve iyi bir online deneyim sunmak için profesyonel web tasarımı şart. Hemen öne çıkın, birlikte çalışalım!"
            //Element
            Two.innerHTML         = "Özel web tasarımıyla işletmenizi profesyonelce öne çıkarın. Sizi en iyi şekilde temsil etmek için buradayız!"
            //Element
            Three.innerHTML       = "Etkileyici bir web site tasarım ile önemli müşterilere ulaşın. Potansiyel müşterilere çekici ve güvenilir bir görünüm sunmak için profesyonel tasarımı keşfedin!"
            //Element
            Description.innerHTML = "İşletmenizin çevrimiçi başarısı için özel web tasarımı şart. Kurumsal, çekici ve benzersiz bir site ile müşterilerinizi etkileyin."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Marka kimliğinizi güçlendirmek ve müşterilere çekici bir dijital deneyim sunmak için profesyonel web tasarımına ihtiyacınız var. Sizi öne çıkaracak özel bir çevrimiçi varlık için birlikte çalışalım!"
            //Element
            Two.innerHTML         = "Profesyonel bir görünüm için özel web tasarımı ile işletmenizi öne çıkarın. Sizi en iyi şekilde temsil etmek için buradayız!"
            //Element
            Three.innerHTML       = "Önemli müşterilere ulaşmak için etkileyici bir web sitesine ihtiyacınız var. Sizi keşfetmelerini sağlayacak özel bir tasarım ile potansiyel müşterilere çekici ve güvenilir bir görünüm sunalım!"
            //Element
            Description.innerHTML = "İşletmenizin çevrimiçi başarıya ulaşması için özel bir web tasarımı şart. Profesyonel, çekici ve benzersiz bir site ile müşterilerinizi etkileyin, rekabetin önünde olun!"
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Yeni İşletmeler İçin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Yeni bir işletme için; önemli müşteriler bulma, işletmenin başarısını ve büyümesini doğrudan etkileyen kritik bir stratejidir."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Yeni İşletmen İçin Önemli Müşteriler Bulmalısın.", itemProp: "name headline" },
                                    //Element
                                    "Yeni İşletmen İçin Önemli ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Müşteriler"
                                    ),
                                    //Element
                                    " Bulmalısın."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Yeni bir işletme için; önemli müşteriler bulma, işletmenin başarısını ve büyümesini",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "doğrudan etkileyen kritik bir stratejidir."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Yeni işletmen için önemli müşteriler bulmalısın.", title: "Yeni işletmen için önemli müşteriler bulmalısın.", src: "https://cdn.benozgurtosun.com/v1/business/184022.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Marka Kimliği Oluşturun", Summary: "Web tasarımı, işletmenizin marka gücünü artırmak için etkili bir araçtır. Renkler, logo ve tasarım markanızın tanıtımını güçlendirir, müşterilere markanızı akılda kalıcı kılar.", Photo: "722126.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Profesyonel Görünün", Summary: "Profesyonel bir web tasarımı, işletmenizin güvenilir ve kaliteli olduğu izlenimini hemen oluşturur. Müşterilerin ilk bakışta olumlu bir etki almasını sağlar.", Photo: "262424.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Önemli Müşterilere Ulaşın", Summary: "Profesyonel bir web site tasarımı, kurumsal kimliğini etkili bir şekilde yansıtarak potansiyel müşterilerle kalıcı ve derin bağlantılar kurma şansı sunar.", Photo: "070466.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Startup;