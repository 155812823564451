//Install
import React, { Component } from "react";

//Element
class Video extends Component {
    //Element
    render() {
        //Element
        let Source = "https://benozgurtosun.s3.eu-north-1.amazonaws.com/video/002.mp4"
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 pb-4 m-0 q41b5t" },
            //Element
            React.createElement( "video", { className: "float-start w-100 h-auto", playsInline: "playsinline", src: Source, controls: "controls", loop: true, autoPlay: true, muted: "muted" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Video;