//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Yandex extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Yandex : Yandex Direct İle Önemli Müşteriler Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Kullanıcılar, Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/yandex"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Yandex : Yandex Direct İle Önemli Müşteriler Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Kullanıcılar, Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/yandex"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Yandex : Yandex Direct İle Önemli Müşteriler Edinin - Benozgurtosun.com"
        //Element
        Description.content = "Kullanıcılar, Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Yandex reklamları ile, işinizi geniş kitlelere tanıtın ve başarıyı garantileyin!"
            //Element
            Two.innerHTML         = "Yandex Reklam Ağı ile işinizi geniş kitlelere tanıtın ve başarıya bir adım daha yaklaşın!"
            //Element
            Three.innerHTML       = "Markanızı öne çıkarmak için Yandex Yeniden Pazarlama'yı kullanın! Yandex ile etkileşimi artırın!"
            //Element
            Description.innerHTML = "Yandex reklamları ile hedef kitlenize ulaşın! İşinizi binlerce müşteriye tanıtmanın sunduğu avantajları keşfedin!"
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Yandex reklamları ile, işinizi geniş kitlelere tanıtın ve başarıyı garantileyin!"
            //Element
            Two.innerHTML         = "Yandex Reklam Ağı ile işinizi geniş kitlelere tanıtın ve başarıya bir adım daha yaklaşın!"
            //Element
            Three.innerHTML       = "Markanızı öne çıkarmak için Yandex Yeniden Pazarlama'yı kullanın! Yandex ile etkileşimi artırın!"
            //Element
            Description.innerHTML = "Yandex reklamları ile hedef kitlenize ulaşın! İşinizi binlerce müşteriye tanıtmanın sunduğu avantajları keşfedin!"
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Arama içeriğine bağlı reklamlar hem masaüstü hem de mobil cihazlarda, Yandex arama sonuçlarında ve ortaklarının arama sitelerinde gösterilir."
            //Element
            Two.innerHTML         = "Mevcut kullanıcı isteklerine cevap veren reklamlarının aksine, tematik reklamlar kullanıcının ilgi alanlarına ve web sitesinin içeriğine odaklanır."
            //Element
            Three.innerHTML       = "İnsanlara daha önce, zaten ilgilendiği bir şey önerildiği için yeniden pazarlama yoluyla reklamınızın verimliliğini daha da artırabilir."
            //Element
            Description.innerHTML = "Müşteriler, Yandex'te çeşitli ürün veya hizmetleri arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Arama içeriğine bağlı reklamlar, hem masaüstü hem de mobil cihazlarda, Yandex arama sonuçlarında ve Reklam Ağı ortaklarının arama sitelerinde gösterilir."
            //Element
            Two.innerHTML         = "Kullanıcıların mevcut taleplerine yanıt veren reklamların aksine, tematik reklamlar kullanıcının ilgi alanlarına ve web sitesinin içeriğine odaklanır."
            //Element
            Three.innerHTML       = "Kullanıcıya daha önce zaten ilgilendiği bir şey önerdiğiniz için yeniden pazarlama yoluyla reklamınızın verimliliğini daha da artırabilirsiniz."
            //Element
            Description.innerHTML = "Kullanıcılar Yandex'te çeşitli ürün ve hizmetleri arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Arama içeriğine uygun reklamlar hem masaüstü hem de mobil cihazlarda Yandex arama sonuçlarında ve Reklam Ağı ortaklarının arama sitelerinde gösterilir."
            //Element
            Two.innerHTML         = "Kullanıcı isteklerine cevap veren arama ağı reklamlarının aksine, tematik reklamlar kullanıcının ilgi alanlarına ve web tesinin içeriğine odaklanır."
            //Element
            Three.innerHTML       = "Kullanıcıya, daha önce zaten ilgilendiği bir şey önerildiği için yeniden pazarlama yoluyla reklamınızın verimliliğini daha da artırabilirsiniz."
            //Element
            Description.innerHTML = "İnsanlar Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Arama içeriğine uygun reklamlar hem masaüstü hem de mobil cihazlarda, arama sonuçlarında ve Reklam Ağı ortaklarının arama sitelerinde gösterilir."
            //Element
            Two.innerHTML         = "Kullanıcı isteklerine cevap veren arama ağı reklamlarının aksine, tematik reklamlar kullanıcının ilgi alanlarına ve web tesinin içeriğine odaklanır."
            //Element
            Three.innerHTML       = "Kullanıcıya, daha önce zaten ilgilendiği bir şey önerildiği için yeniden pazarlama yoluyla reklamınızın verimliliğini daha da artırabilirsiniz."
            //Element
            Description.innerHTML = "İnsanlar Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Arama içeriğine uygun reklamlar hem masaüstü hem de mobil cihazlarda, arama sonuçlarında ve Reklam Ağı ortaklarının arama sitelerinde gösterilir."
            //Element
            Two.innerHTML         = "Kullanıcı isteklerine cevap veren arama ağı reklamlarının aksine, tematik reklamlar kullanıcının ilgi alanlarına ve web tesinin içeriğine odaklanır."
            //Element
            Three.innerHTML       = "Kullanıcıya, daha önce zaten ilgilendiği bir şey önerildiği için yeniden pazarlama yoluyla reklamınızın verimliliğini daha da artırabilirsiniz."
            //Element
            Description.innerHTML = "İnsanlar Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Arama içeriğine uygun reklamlar hem masaüstü, hem de mobil cihazlarda, arama sonuçlarında ve Reklam Ağı ortaklarının arama sitelerinde gösterilir."
            //Element
            Two.innerHTML         = "Kullanıcı isteklerine cevap veren, arama ağı reklamlarının aksine, tematik reklamlar kullanıcının ilgi alanlarına ve web tesinin içeriğine odaklanır."
            //Element
            Three.innerHTML       = "Kullanıcılara daha önce zaten ilgilendiği bir şey önerildiği için yeniden pazarlama yoluyla reklamınızın verimliliğini daha da artırabilirsiniz."
            //Element
            Description.innerHTML = "İnsanlar, Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Yandex Arama’da reklam verin, işinizi geniş kitlelere tanıtın ve başarıyı garantileyin! Önemli müşterilere ulaşmanın gücünü keşfedin."
            //Element
            Two.innerHTML         = "Yandex Reklam Ağı ile işinizi onlarca yeni müşteriye tanıtın ve hedef kitlenizi etkileyin, markanızı geniş kitlelere duyurun, başarıya bir adım daha yaklaşın!"
            //Element
            Three.innerHTML       = "Yandex Yeniden Pazarlama ile markanızı öne çıkarın! Şimdi reklam verin, hedef kitlenizle etkileşimi artırın."
            //Element
            Description.innerHTML = "Yandex reklamları ile hedef kitlenize ulaşın! İşinizi binlerce müşteriye tanıtmanın sunduğu avantajları keşfedin!"
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Yandex Arama’da reklam verin, işinizi geniş kitlelere tanıtın ve başarıyı garantileyin! Önemli müşterilere ulaşmanın gücünü keşfedin."
            //Element
            Two.innerHTML         = "Yandex Reklam Ağı ile işinizi onlarca yeni müşteriye tanıtın! Hedef kitlenizi etkileyin, markanızı geniş kitlelere duyurun ve başarıya bir adım daha yaklaşın!"
            //Element
            Three.innerHTML       = "Yandex Yeniden Pazarlama ile markanızı öne çıkarın! Şimdi reklam verin, hedef kitlenizle etkileşimi artırın."
            //Element
            Description.innerHTML = "Yandex reklamları ile hedef kitlenize ulaşın! İşinizi binlerce müşteriye tanıtmanın sunduğu avantajları keşfedin!"
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Yandex Arama’da reklam verin, işinizi geniş kitlelere duyurun ve başarıyı garantileyin! Önemli müşterilere ulaşmanın gücünü keşfedin."
           //Element
           Two.innerHTML         = "Yandex Reklam Ağı ile işinizi milyonlarca potansiyel müşteriye tanıtın! Hedef kitlenizi etkileyin, markanızı geniş kitlelere duyurun ve başarıya bir adım daha yaklaşın!"
           //Element
           Three.innerHTML       = "Yandex Yeniden Pazarlama ile markanızı öne çıkarın! Şimdi reklam verin, hedef kitlenizle etkileşimi artırın."
           //Element
           Description.innerHTML = "Yandex reklamları ile hedef kitlenize ulaşın! İşinizi milyonlarca müşteriye tanıtmanın sunduğu avantajları keşfedin!"
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Yandex Arama'da öne çıkın, müşterilerinizi bulun! Yandex Reklamları ile işinizi hemen potansiyel müşterilere taşıyın!"
            //Element
            Two.innerHTML         = "Yandex Reklam Ağı ile milyonlarca potansiyel müşteriye ulaşın! Özel reklamlarınızla hedef kitlenizi çekin ve başarıya giden yolda güçlü adımlar atın!"
            //Element
            Three.innerHTML       = "Kayıp fırsatları geri kazanın! Yandex Yeniden Pazarlama ile müşterilerinizi özel kampanyalarla çekin, markanızı hatırlatın ve etkili bir şekilde yeniden hedefleyin!"
            //Element
            Description.innerHTML = "Yandex reklamlarıyla hedef kitlenize ulaşın! İşinizi milyonlarca önemli müşteriye tanıtmanın avantajlarını keşfedin!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Yandex Arama'da öne çıkın, müşterilerinizi bulun! Yandex Reklamları ile işinizi arama sonuçlarında ön sıralara taşıyın ve potansiyel müşterilere hemen ulaşın!"
            //Element
            Two.innerHTML         = "Yandex Reklam Ağı ile işinizi milyonlarca potansiyel müşteriye taşıyın! Hedef kitlenize özel reklamlarla dikkat çekin, başarıya giden yolda güçlü adımlar atın!"
            //Element
            Three.innerHTML       = "Kayıp fırsatları geri kazanın, Yandex Yeniden Pazarlama ile müşterilerinizi tekrar çekin! Özel kampanyalarınızla dikkat çekin, markanızı hatırlatın ve potansiyel müşterilerinizi etkili bir şekilde yeniden hedefleyin!"
            //Element
            Description.innerHTML = "Yandex reklamları ile hedef kitlenize ulaşın! İşinizi milyonlarca potansiyel müşteriye tanıtmanın avantajlarını keşfedin."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Yandex : Yandex Direct İle Önemli Müşteriler Edinin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Kullanıcılar, Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Yandex Direct İle Önemli Müşteriler Edinin.", itemProp: "name headline" },
                                    //Element
                                    "Yandex Direct İle Önemli ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Müşteriler"
                                    ),
                                    //Element
                                    " Edinin."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Kullanıcılar, Yandex'te çeşitli ürün veya hizmetler arar. Yanıt olarak arama",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "sonuçlarında içeriksel reklamlar şeklinde uygun çözümler önerilir."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Yandex Direct İle Önemli Müşteriler Edinin.", title: "Yandex Direct İle Önemli Müşteriler Edinin.", src: "https://cdn.benozgurtosun.com/v1/business/057341.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Yandex Arama’da Reklam", Summary: "Arama içeriğine bağlı reklamlar hem masaüstü hem de mobil cihazlarda, Yandex arama sonuçlarında ve Reklam Ağı ortaklarının arama sitelerinde gösterilir.", Photo: "714228.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Yandex Reklam Ağı", Summary: "Mevcut kullanıcı isteklerine cevap veren arama reklamlarının aksine, tematik reklamlar kullanıcının ilgi alanlarına ve web sitesinin içeriğine odaklanır.", Photo: "461095.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Yandex Yeniden Pazarlama", Summary: "Kullanıcıya daha önce zaten ilgilendiği bir şey önerdiğiniz için yeniden pazarlama yoluyla reklamınızın verimliliğini daha da artırabilir ve müşteri çekme maliyetini azaltabilirsiniz.", Photo: "709981.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Yandex;