//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Domain extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : İşiniz İçin Ücretsiz Bir Domain Edinin - Benozgurtosun.com"
        //Element
        Description.content = "İyi bir domain insanların neden çevrimiçi ortamda olduğunuzu ve neden ideal olduğunuzu bir bakışta anlamasını sağlar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/domain"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : İşiniz İçin Ücretsiz Bir Domain Edinin - Benozgurtosun.com"
        //Element
        Description.content = "İyi bir domain insanların neden çevrimiçi ortamda olduğunuzu ve neden ideal olduğunuzu bir bakışta anlamasını sağlar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/domain"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : İşiniz İçin Ücretsiz Bir Domain Edinin - Benozgurtosun.com"
        //Element
        Description.content = "İyi bir domain insanların neden çevrimiçi ortamda olduğunuzu ve neden ideal olduğunuzu bir bakışta anlamasını sağlar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Kaliteli bir alan adı, işletmenizin marka tanınırlığını artırabilir. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "Kurumsal eposta adresi işletmenizi güvenilir kılar. Bugün bir alan adı edinin, e-posta iletişiminde öne çıkın!"
            //Element
            Three.innerHTML       = "Başarılı bir domain sektörünüzde rekabet avantajı sağlar. Dikkat çekici bir alan adı, işletmenizin öne çıkmasına katkı sağlar."
            //Element
            Description.innerHTML = "İşletmenizin dijital varlığını güçlendirmek, başarıyı artırmak için hemen kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Kaliteli bir alan adı, işletmenizin marka tanınırlığını artırabilir. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "İşletmenizin güvenilirliğini artırmak için hemen bir kurumsal e-posta adresi alın ve iletişimde profesyonelliği yakalayın!"
            //Element
            Three.innerHTML       = "Başarılı bir domain sektörünüzde rekabet avantajı sağlar. Dikkat çekici bir alan adı, işletmenizin öne çıkmasına katkı sağlar."
            //Element
            Description.innerHTML = "İşletmenizin dijital etkisini güçlendirmek ve başarı şansını artırmak için hemen özgün bir alan adı kaydı yapın!"
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "İyi bir domain, işletmenizin marka bilinirliğini artırabilir. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "Kurumsal e-posta adresleri, işletmenizin güvenilirliğini pekiştirerek profesyonel bir imaj sunar."
            //Element
            Three.innerHTML       = "Rekabetçi bir sektörde kaliteli bir domain, işletmenize belirgin bir rekabet avantajı sağlar."
            //Element
            Description.innerHTML = "İşletmenizin dijital varlığını güçlendirmek ve başarıyı artırmak için kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "İyi bir domain, işletmenizin marka bilinirliğini artırabilir. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "Kurumsal e-posta adresleri, işletmenizin güvenilirliğini pekiştirerek profesyonel bir imaj sunar."
            //Element
            Three.innerHTML       = "Rekabetçi bir sektörde kaliteli bir domain, işletmenize belirgin bir rekabet avantajı sağlar."
            //Element
            Description.innerHTML = "İşletmenizin dijital varlığını güçlendirmek ve başarıyı artırmak için kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Kendi domaininizi kullanmak işletmenizi daha cazip hale getirir ve müşterilerle sıkı bir ilişki kurma olanağı sağlar."
            //Element
            Two.innerHTML         = "Özel bir domain ile kurulan e-posta adresleri, işletmenin güvenilirliğini artırabilir. Örneğin, info@sirketiniz.com gibi bir adres, etkileyici bir izlenim bırakabilir."
            //Element
            Three.innerHTML       = "Birçok işletme, sadece sosyal medyada varlık gösterse de kendi alan adınızla rekabet avantajı sağlayarak daha kurumsal bir imaj oluşturabilirsiniz."
            //Element
            Description.innerHTML = "İyi bir domain adı insanların neden dijital olduğunuzu  ve neden sizin ideal bir seçenek olduğunuzu hemen anlamalarına yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Kendi domaininizi kullanmak işletmenizi daha cazip hale getirir ve müşterilerle sıkı bir ilişki kurma olanağı sağlar."
            //Element
            Two.innerHTML         = "Özel bir domain ile kurulan e-posta adresleri, işletmenin güvenilirliğini artırabilir. Örneğin, info@sirketiniz.com gibi bir adres, etkileyici bir izlenim bırakabilir."
            //Element
            Three.innerHTML       = "Birçok işletme, sadece sosyal medyada varlık gösterse de kendi alan adınızla rekabet avantajı sağlayarak daha kurumsal bir imaj oluşturabilirsiniz."
            //Element
            Description.innerHTML = "İyi bir domain adı insanların neden dijital olduğunuzu  ve neden sizin ideal bir seçenek olduğunuzu hemen anlamalarına yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Kendi domaininizi kullanmak işletmenizi daha cazip hale getirir ve müşterilerle sıkı bir ilişki kurma olanağı sağlar."
            //Element
            Two.innerHTML         = "Özel bir domain ile kurulan e-posta adresleri, işletmenin güvenilirliğini artırabilir. Örneğin, info@sirketiniz.com gibi bir adres, etkileyici bir izlenim bırakabilir."
            //Element
            Three.innerHTML       = "Birçok işletme, sadece sosyal medyada varlık gösterse de kendi alan adınızla rekabet avantajı sağlayarak daha kurumsal bir imaj oluşturabilirsiniz."
            //Element
            Description.innerHTML = "İyi bir domain adı insanların neden dijital olduğunuzu  ve neden sizin ideal bir seçenek olduğunuzu hemen anlamalarına yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Kendi domaininizi kullanmak, işletmenizi daha cazip hale getirir ve müşterilerle ilişki kurma olanağı sağlar."
            //Element
            Two.innerHTML         = "Özel bir domain ile kurulan e-posta adresleri, işletmenin güvenilirliğini artırabilir."
            //Element
            Three.innerHTML       = "Bir çok işletme, sadece sosyal medyada varlık gösterse de kendi domain adınızla rekabet avantajı sağlayarak daha kurumsal bir imaj oluşturabilirsiniz."
            //Element
            Description.innerHTML = "İyi bir domain adı, insanların neden dijital olduğunuzu  ve neden sizin ideal bir seçenek olduğunuzu hemen anlamalarına yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "İyi bir alan adı işletmenizin marka bilinirliğini artırır. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "Özel e-posta adresleri ile işletmenizi güvenilir kılın. Bugün bir alan adı edinin, e-posta iletişiminde öne çıkın!"
            //Element
            Three.innerHTML       = "Başarılı bir alan adı, sektörünüzde rekabet avantajı sağlar. Dikkat çekici bir domain adı, işletmenizin öne çıkmasına katkı sağlar."
            //Element
            Description.innerHTML = "İşletmenizin online varlığını güçlendirmek ve dijital başarıyı artırmak için hemen kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "İyi bir alan adı işletmenizin marka bilinirliğini artırır. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "Özel e-posta adresleri ile işletmenizi güvenilir kılın. Bugün bir alan adı edinin, e-posta iletişiminde öne çıkın!"
            //Element
            Three.innerHTML       = "Başarılı bir alan adı, sektörünüzde rekabet avantajı sağlar. Dikkat çekici bir domain adı, işletmenizin öne çıkmasına katkı sağlar."
            //Element
            Description.innerHTML = "İşletmenizin online varlığını güçlendirmek ve dijital başarıyı artırmak için hemen kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "İyi bir domain adı işletmenizin marka bilinirliğini artırır. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
           //Element
           Two.innerHTML         = "Özel e-posta adresleri ile işletmenizi güvenilir kılın. Bugün bir alan adı edinin, e-posta iletişiminde öne çıkın!"
           //Element
           Three.innerHTML       = "Başarılı bir domain adı, sektörünüzde rekabet avantajı sağlar. Akılda kalıcı bir domain, işletmenizin öne çıkmasını destekler."
           //Element
           Description.innerHTML = "İşletmenizin çevrimiçi varlığını güçlendirmek ve dijital başarıyı artırmak için hemen kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Özel bir alan adı, işletmenizin marka bilinirliğini artırır. Akılda kalıcı ve özgün bir domain, marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "Özel e-posta adresleri ile işletmenizi güvenilir kılın. Bugün bir alan adı edinin, e-posta iletişiminde öne çıkın!"
            //Element
            Three.innerHTML       = "İlgili bir domain sektörünüzde rekabet avantajı sağlar. Hafızalarda yer edinen bir domain, işletmenizin öne çıkmasını sağlar."
            //Element
            Description.innerHTML = "İşletmenizin dijital varlığını güçlendirmek ve online başarıyı artırmak için hemen kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Özel bir alan adı, işletmenizin marka bilinirliğini yükseltir. Akılda kalıcı ve özgün bir domain, müşterilerin ve potansiyel müşterilerin zihninde kalıcı bir izlenim bırakır, böylece marka tanıtımınızı güçlendirir."
            //Element
            Two.innerHTML         = "Kendi özel alan adınızla özelleştirilmiş e-posta adresleri oluşturarak işletmenizi daha güvenilir kılın. Hemen bir domain edinin, işletmenizin e-posta iletişiminde öne çıkın!"
            //Element
            Three.innerHTML       = "İlgili bir alan adına sahip olmak, sektörünüzde rekabet avantajı sağlayabilir. Müşterilerinizin ve rakiplerinizin hafızasında yer edinen bir alan adı, işletmenizin öne çıkmasını sağlar."
            //Element
            Description.innerHTML = "İşletmenizin dijital varlığını güçlendirmek ve online başarıyı artırmak için bugün kendi özel alan adınızı edinin!"
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : İşiniz İçin Ücretsiz Bir Domain Edinin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "İyi bir domain insanların neden çevrimiçi ortamda olduğunuzu ve neden ideal olduğunuzu bir bakışta anlamasını sağlar."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "İşiniz İçin Ücretsiz Bir Domain Edinin.", itemProp: "name headline" },
                                    //Element
                                    "İşiniz İçin ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Ücretsiz"
                                    ),
                                    //Element
                                    " Bir Domain Edinin."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "İyi bir domain insanların neden çevrimiçi ortamda olduğunuzu ve ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "neden ideal olduğunuzu bir bakışta anlamasını sağlar."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "İşiniz İçin Ücretsiz Bir Domain Edinin.", title: "İşiniz İçin Ücretsiz Bir Domain Edinin.", src: "https://cdn.benozgurtosun.com/v1/business/007735.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Marka Bilinirliği", Summary: "Kendi domain'inizi kullanmak, işletmenizi daha çekici kılar ve müşterilerle sıkı bir bağ kurma şansı sunar.", Photo: "528698.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "E-posta Güvenilirliği", Summary: "Özel bir domain ile e-posta adresleri oluşturmak işletmenizin güvenilirliğini artırır. Örneğin, info@sirketiniz.com gibi bir adres, müşterilere etkileyici bir izlenim bırakabilir.", Photo: "615355.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Rekabet Üstünlüğü", Summary: "Birçok işletme sadece sosyal medyada varlığını sürdürse de, kendi alan adınızla rekabet avantajı sağlayarak daha kurumsal bir imaj oluşturabilirsiniz.", Photo: "742201.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Domain;