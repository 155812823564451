//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";

//Element
class Form extends Component {
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        emailjs.sendForm( process.env.REACT_APP_N04A4C, process.env.REACT_APP_Q919J2, "#React_A01NB8", process.env.REACT_APP_W4961H )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                window.location.href = "/tesekkur-ederim"
            }
        }, (error) => {
            //Element
            let Frame = document.getElementsByClassName( "i471vp" )[0]
            //Element
            let Text  = document.getElementsByClassName( "w70883" )[0]
            //Element
            Frame.classList.toggle( "d-none" )
            //Element
            Text.innerHTML = error.text
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 j396h2" },
            //Element
            React.createElement( "form", { id: "React_A01NB8", name: "React_A01NB8", className: "float-start w-100 p-0 m-0 q87a3p", method: "GET", onSubmit: this.Send },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 b384t5" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 c997p0" },
                                //Element
                                "Ad"
                            ),
                            //Element
                            React.createElement( "input", { id: "React_AF215L", name: "React_AF215L", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-2 a21z73", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 b384t5" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 c997p0" },
                                //Element
                                "Soyad"
                            ),
                            //Element
                            React.createElement( "input", { id: "React_M50606", name: "React_M50606", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-2 a21z73", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 b384t5" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 c997p0" },
                                //Element
                                "Telefon numarası"
                            ),
                            //Element
                            React.createElement( "input", { id: "React_DQP087", name: "React_DQP087", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-2 a21z73", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 b384t5" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 c997p0" },
                                //Element
                                "E-posta"
                            ),
                            //Element
                            React.createElement( "input", { id: "React_R019AP", name: "React_R019AP", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-2 a21z73", type: "email", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 b384t5" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 c997p0" },
                                //Element
                                "Mesaj"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "React_C636U2", name: "React_C636U2", className: "float-start w-100 p-0 ps-4 pe-4 pt-3 pb-3 m-0 mt-2 uk6620", rows: "5", cols: "30", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 b384t5" },
                            //Element
                            React.createElement( "button", { id: "React_G0316X", name: "React_G0316X", className: "float-start w-100 p-0 m-0 z160i4", type: "submit" },
                                //Element
                                "Hemen Gönder"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d-none i471vp" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center lh-1 w70883" },
                                //Element
                                "Tebrikler Başarı İle Gönderildi."
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Form;