//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Navbar } from "../../Media/Mobile/001.svg";
//Install
import { ReactComponent as Close } from "../../Media/Mobile/002.svg";
//Install
import { ReactComponent as Call } from "../../Media/Mobile/003.svg";

//Element
class Menu extends Component {
    //Element
    Information = () => {
        //Element
        window.location.href = "/on-bilgilendirme-talebi"
    }
    //Element
    Connect = () => {
        //Element
        window.location.href = "tel:+905349204144"
    }
    //Element
    Toggle = () => {
        //Element
        let Menu  = document.getElementsByClassName( "w22y4l" )[0]
        //Element
        let Bar   = document.getElementsByClassName( "q928g8" )[0]
        //Element
        let Close = document.getElementsByClassName( "v10846" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Bar.classList.toggle( "d-none" )
        //Element
        Close.classList.toggle( "d-none" )
        //Element
        Menu.classList.toggle( "d-block" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 ox8525" },
            //Element
            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 ps-4 pe-4 m-0 me-4 n772zy", type: "button", onClick: this.Information } ),
            //Element
            React.createElement( "button", { id: "React_EAQ612", name: "React_EAQ612", className: "d-flex w-auto justify-content-center align-items-center bg-transparent border-0 p-0 m-0 me-4 v7c42n", title: "Hemen Ara", type: "button", onClick: this.Connect },
                //Element
                React.createElement( Call, { id: "React_EAQ613", name: "React_EAQ613" } )
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center bg-transparent border-0 p-0 m-0 q928g8", type: "button", onClick: this.Toggle },
                //Element
                React.createElement( Navbar, {} )
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center bg-transparent border-0 p-0 m-0 d-none v10846", type: "button", onClick: this.Toggle },
                //Element
                React.createElement( Close, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;