//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Call from "../../Shortcode/Contact/Call";
//Install
import Form from "../../Shortcode/Contact/Form";
//Install
import Email from "../../Shortcode/Contact/Email";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Memnuniyetiniz İçin Daima Burada Olacağım - Benozgurtosun.com"
        //Element
        Description.content = "Hâlâ nelere ihtiyaçlarınız konusunda kararsız mısınız? Siz müşteri olmasanız bile memnuniyetle yardımcı olur, yönlendiririm."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/hemen-ulasin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Memnuniyetiniz İçin Daima Burada Olacağım - Benozgurtosun.com"
        //Element
        Description.content = "Hâlâ nelere ihtiyaçlarınız konusunda kararsız mısınız? Siz müşteri olmasanız bile memnuniyetle yardımcı olur, yönlendiririm."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/hemen-ulasin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Memnuniyetiniz İçin Daima Burada Olacağım - Benozgurtosun.com"
        //Element
        Description.content = "Hâlâ nelere ihtiyaçlarınız konusunda kararsız mısınız? Siz müşteri olmasanız bile memnuniyetle yardımcı olur, yönlendiririm."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Resposive = () => {
        //Element
        let Description = document.querySelector( "#React_27E0XH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML = "Karar vermekte hâlâ zorlanıyor musunuz? Müşteri olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML = "Karar vermekte hâlâ zorlanıyor musunuz? Müşteri olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML = "Karar verme konusunda hâlâ zorlanıyor musunuz? Siz müşterimiz olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "Hâlâ, hangi konularda ihtiyaçlarınıza net bir şekilde karar veremiyor musunuz? Siz bir müşteri olmasanız bile, memnuniyetle yardımcı olabilirim."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "Karar verme konusunda hâlâ, zorlanıyor musunuz? Siz müşterimiz olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML = "Karar verme konusunda hâlâ, zorlanıyor musunuz? Siz müşterim olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "Karar verme konusunda hâlâ, zorlanıyor musunuz? Siz müşterim olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML = "Karar verme konusunda hâlâ, zorlanıyor musunuz? Sizler müşterim olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML = "Karar vermekte hâlâ zorlanıyor musun? Müşteri olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML = "Karar vermekte hâlâ zorlanıyor musun? Müşteri olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           Description.innerHTML = "Karar vermekte hâlâ zorlanıyor musunuz? Müşteri olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "Karar vermekte hâlâ zorlanıyor musunuz? Müşteri olmasanız bile size yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Description.innerHTML = "Hâlâ belirli konularda net bir karar alamadınız mı? Müşteri olmasanız bile, yardımcı olmaktan memnuniyet duyarım."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else if( window.innerWidth >= "1280" && window.innerWidth <= "1281" ){
            //Element
            Description.innerHTML = "Hâlâ belirli konularda net bir karar veremiyor musun? Siz bir müşteri olmasanız bile, memnuniyetle yardımcı olabilirim."
        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Memnuniyetiniz İçin Daima Burada Olacağım - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Hâlâ nelere ihtiyaçlarınız konusunda kararsız mısınız? Siz müşteri olmasanız bile memnuniyetle yardımcı olur, yönlendiririm."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Resposive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/ContactPage" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Memnuniyetiniz İçin Daima Burada Olacağım.", itemProp: "name alternateName" },
                                    //Element
                                    "Memnuniyetiniz İçin ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Daima"
                                    ),
                                    //Element
                                    " Burada Olacağım."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_27E0XH", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Hâlâ, hangi konular'da ihtiyaçlarınıza net bir şekilde karar veremiyor musunuz? ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "Siz bir müşteri olmasanız bile, memnuniyetle yardımcı olabilirim."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 w728x2" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Call, { ID: "0", Title: "Kurumsal Bilgilendirme", Summary: "Çağrı merkezi numaraları 08:00 ile 19:00 saatleri arasında hizmet vermektedir.", Photo: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Email, { ID: "0", Title: "Hemen Bilgi Edinin", Summary: "İhtiyaçlarınız için buradayım. Bana yazın, size yardımcı olmaktan mutluluk duyarım.", Photo: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-5 m-0 f63v0g" },
                                //Element
                                React.createElement( Form, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;