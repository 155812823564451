//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Instagram extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Instagram For Business : Instagram İle Yeni Müşterilerin Dikkatini Çekin - Benozgurtosun.com"
        //Element
        Description.content = "İnsanlar, instagram'a sevdikleri şeyleri keşfetmek için gelir. Sizinki gibi işletmeler de buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/instagram"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Instagram For Business : Instagram İle Yeni Müşterilerin Dikkatini Çekin - Benozgurtosun.com"
        //Element
        Description.content = "İnsanlar, instagram'a sevdikleri şeyleri keşfetmek için gelir. Sizinki gibi işletmeler de buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/reklam-ver/instagram"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Instagram For Business : Instagram İle Yeni Müşterilerin Dikkatini Çekin - Benozgurtosun.com"
        //Element
        Description.content = "İnsanlar, instagram'a sevdikleri şeyleri keşfetmek için gelir. Sizinki gibi işletmeler de buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Instagram'da bilinirliğinizi yükselterek, çarpıcı bir şekilde önemli müşterilere ulaşın."
            //Element
            Two.innerHTML         = "Instagram reklamları ile, önemli bağlantılar kurun ve markanızı tanıtarak etkileşimi artırın."
            //Element
            Three.innerHTML       = "Instagram reklamları kullanarak, müşteri tabanınızı genişletin ve çarpıcı içeriklerle markanızı öne çıkarın."
            //Element
            Description.innerHTML = "Instagram reklamları ile işinizi önemli müşterilere tanıtın ve yaratıcı içeriklerle markanızı öne çıkarın."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Instagram'da bilinirliğinizi yükselterek, çarpıcı bir şekilde önemli müşterilere ulaşın."
            //Element
            Two.innerHTML         = "Instagram reklamları ile, önemli bağlantılar kurun ve markanızı tanıtarak etkileşimi artırın."
            //Element
            Three.innerHTML       = "Instagram reklamları kullanarak müşteri tabanınızı genişletin ve çarpıcı içeriklerle markanızı öne çıkarın."
            //Element
            Description.innerHTML = "Instagram reklamları ile işinizi önemli müşterilere tanıtın ve yaratıcı içeriklerle markanızı öne çıkarın."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "İşinizi zirveye taşıyın! Instagram reklamları ile, bilinirliği anında artırın ve hedef kitlenizin gözünde öne çıkın."
            //Element
            Two.innerHTML         = "İşinizde çığır açın. Instagram reklamlarıyla sınırları aşın ve yeni ilişkiler ile markanızı binlerce müşteriyle buluşturun."
            //Element
            Three.innerHTML       = "İşinizi bir üst seviyeye taşıyın! Instagram reklamlarıyla önemli müşteriler edinin, markanızı öne çıkarın ve sektörde lider konuma yükselin."
            //Element
            Description.innerHTML = "İşinizi büyütmenin temeli Instagram reklamlarında gizli Hedef kitlenizi etkilemek ve müşteri çekmek için şimdi adım atın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Günlük 200 milyon işletme hesabının ziyaret edildiği, bu geniş platforma katılın ve işletmenizi daha geniş bir kitleye tanıtın."
            //Element
            Two.innerHTML         = "Hikayelerde yer alan etkileşimli çıkartmaları veya bire bir mesajlaşma seçeneklerini kullanarak, müşteriler ile iletişim kurun ve kişisel bağlar oluşturun."
            //Element
            Three.innerHTML       = "Gelecekteki alışveriş planlarını yaparken, insanların %70'i Instagram'ı tercih ediyor, bu yüzden şu an mağaza açmak için ideal bir zaman."
            //Element
            Description.innerHTML = "İnsanlar, instagram'a sevdiği şeyleri keşfetmek için gelir. Sizinki gibi işletmelerde buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Günde 200M fazla işletme hesabının ziyaret edildiği, bu geniş platforma katılın ve önemli kitlelere erişin."
            //Element
            Two.innerHTML         = "Hikayelerde yer alan etkileşimli çıkartmaları veya birebir mesajlaşma seçeneklerini kullanarak, müşterilerle doğrudan iletişim kurun."
            //Element
            Three.innerHTML       = "Gelecekteki, alışveriş planlarını yaparken insanların %70 Instagram'ı tercih ediyor. Bu yüzden şu an mağaza açmak için ideal bir zaman."
            //Element
            Description.innerHTML = "İnsanlar instagram'a sevdiği şeyleri keşfetmek için gelir. Sizinki gibi işletmelerde buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Günde 200M'den fazla işletme hesabının ziyaret edildiği bu geniş platforma katılın ve önemli kitlelere erişin."
            //Element
            Two.innerHTML         = "Hikayelerde yer alan etkileşimli çıkartmaları veya birebir mesajlaşma seçeneklerini kullanarak, müşterilerle doğrudan iletişim kurun."
            //Element
            Three.innerHTML       = "Gelecekteki, alışveriş planlarını yaparken insanların %70 Instagram'ı tercih ediyor. Bu yüzden şu an mağaza açmak için ideal bir zaman."
            //Element
            Description.innerHTML = "İnsanlar, instagram'a sevdiği şeyleri keşfetmek için gelir. Sizinki gibi işletmelerde buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Günde 200M'den fazla işletme hesabının ziyaret edildiği bu geniş platforma katılın ve önemli kitlelere erişin."
            //Element
            Two.innerHTML         = "Hikayelerde yer alan etkileşimli çıkartmaları veya birebir mesajlaşma seçeneklerini kullanarak, müşterilerle doğrudan iletişim kurun."
            //Element
            Three.innerHTML       = "Gelecekteki, alışveriş planlarını yaparken insanların %70 Instagram'ı tercih ediyor. Bu yüzden şu an mağaza açmak için ideal bir zaman."
            //Element
            Description.innerHTML = "İnsanlar, instagram'a sevdiği şeyleri keşfetmek için gelir. Sizinki gibi işletmelerde buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Günde, 200M'den fazla işletme hesabının ziyaret edildiği bu geniş platforma katılın ve önemli kitlelere erişin."
            //Element
            Two.innerHTML         = "Hikayelerde yer alan, etkileşimli çıkartmaları veya birebir mesajlaşma seçeneklerini kullanarak, müşterilerle doğrudan iletişim kurun."
            //Element
            Three.innerHTML       = "Gelecekteki, alışveriş planlarını yaparken insanların %70'i Instagram'ı tercih ediyor. Bu yüzden şu an mağaza açmak için ideal bir zaman."
            //Element
            Description.innerHTML = "İnsanlar, instagram'a sevdiği şeyleri keşfetmek için gelir. Sizinki gibi işletmelerde buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Instagram'da bilinirliğinizi artırarak potansiyel müşterilere çarpıcı bir şekilde ulaşın. "
            //Element
            Two.innerHTML         = "Instagram'a reklam vererek önemli ilişkiler kurun markanızı çekici içeriklerle tanıtarak etkileşimi artırın."
            //Element
            Three.innerHTML       = "Instagram reklamları ile müşteri tabanını genişletin. Dikkat çekici içerikler ile markanızı öne çıkarın."
            //Element
            Description.innerHTML = "Instagram reklamları ile, işinizi önemli kitlelere tanıtın ve yaratıcı içeriklerle markanızı öne çıkarın."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Instagram'da bilinirliğinizi artırarak potansiyel müşterilere çarpıcı bir şekilde ulaşın. "
            //Element
            Two.innerHTML         = "Instagram'a reklam vererek önemli ilişkiler kurun! Markanızı çekici içeriklerle tanıtarak etkileşimi artırın."
            //Element
            Three.innerHTML       = "Instagram reklamları ile müşteri tabanını genişletin. Dikkat çekici içerikler ile markanızı öne çıkarın."
            //Element
            Description.innerHTML = "Instagram reklamları ile, işinizi önemli kitlelere tanıtın ve yaratıcı içeriklerle markanızı öne çıkarın."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Instagram'da bilinirliğinizi artırarak önemli müşterilere çarpıcı bir şekilde ulaşın. "
           //Element
           Two.innerHTML         = "Instagram'da reklam vererek önemli ilişkiler kurun! Markanızı çekici içeriklerle tanıtarak etkileşimi artırın."
           //Element
           Three.innerHTML       = "Instagram reklamları ile yeni müşteriler edinin! Hedef kitlenizi etkileyici içeriklerle çekin ve markanızı öne çıkarın."
           //Element
           Description.innerHTML = "Instagram reklamları ile işinizi geniş kitlelere tanıtın ve yaratıcı içeriklerle markanızı öne çıkarın."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Instagram'da hızlı bir şekilde bilinirliğinizi artırın! Görsel çekiciliğinizle milyonlarca potansiyel müşteriye ulaşarak markanızı büyütün!"
            //Element
            Two.innerHTML         = "Yeni müşteri ilişkileri kurmanın etkili yolu : Instagram! Hedef kitlenizle etkileşime geçerek markanızı tanıtın ve bağlantılarınızı güçlendirin!"
            //Element
            Three.innerHTML       = "Yeni iş bağlantıları kurmanın güçlü yolu : Instagram reklamları! Hedef kitlenizle iletişime geçin, markanızı tanıtarak önemli ilişkiler oluşturun!"
            //Element
            Description.innerHTML = "Instagram reklamlarını kullanarak, işletmenizi geniş bir kitleye tanıtın ve yaratıcı içeriklerle markanızı öne çıkarın."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Instagram'da bilinirliğinizi yükseltmenin en hızlı yolu: Reklamlarınızla öne çıkın! Görsel çekiciliğinizle milyonlarca potansiyel müşteriye ulaşın, markanızı Instagram'da büyütün!"
            //Element
            Two.innerHTML         = "Yeni müşteri ilişkileri kurmanın en çarpıcı yolu: Instagram! Hedef kitleniz ile etkileşime geçin, markanızı Instagram'da tanıtarak bağlantılarınızı güçlendirin!"
            //Element
            Three.innerHTML       = "Yeni iş bağlantıları kurmanın en çarpıcı yolu: Instagram reklamları! Hedef kitlenizle doğrudan iletişim kurun, markanızı Instagram'da tanıtarak önemli ilişkiler inşa edin!"
            //Element
            Description.innerHTML = "Instagram reklamları ile potansiyel müşterilere ulaşın ve işinizi daha geniş bir kitleye tanıtın. Yaratıcı içeriklerle markanızı ön plana çıkarın."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Instagram For Business : Instagram İle Yeni Müşterilerin Dikkatini Çekin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "İnsanlar, instagram'a sevdikleri şeyleri keşfetmek için gelir. Sizinki gibi işletmeler de buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Instagram İle Yeni Müşterilerin Dikkatini Çekin.", itemProp: "name headline" },
                                    //Element
                                    "Instagram İle Yeni ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Müşterilerin"
                                    ),
                                    //Element
                                    " Dikkatini Çekin."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "İnsanlar, instagram'a sevdikleri şeyleri keşfetmek için gelir. Sizinki gibi işletmeler",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "de buna dahildir. Tutkunuzu bir reklam aracılığıyla paylaşın."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Instagram İle Yeni Müşterilerin Dikkatini Çekin.", title: "Instagram İle Yeni Müşterilerin Dikkatini Çekin.", src: "https://cdn.benozgurtosun.com/v1/business/689981.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Instagram'da Bilinirliği Artırın.", Summary: "Günlük 200 milyondan fazla işletme hesabının ziyaret edildiği bu geniş platforma katılın ve işletmenizi daha geniş bir kitleye tanıtın.", Photo: "504217.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Yeni İlişkiler Kurun.", Summary: "Hikayelerdeki etkileşimli çıkartmalar ve bire bir mesajlaşma seçeneklerini kullanarak, müşterilerle doğrudan iletişim kurun ve kişisel bağlar oluşturun.", Photo: "964607.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Önemli Müşteriler Edinin.", Summary: "Gelecekteki alışverişlerini planlarken müşterilerin yüzde 70'i Instagram'ı tercih ediyor, bu yüzden şu an mağaza açmak için ideal bir zaman.", Photo: "334060.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Instagram;