//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Tab extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Photo, Target } = this.props
        //Element
        let Source = "https://benozgurtosun.s3.eu-north-1.amazonaws.com/video/"
        //Element
        let Video  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 e10di5", itemScope: "itemScope", itemType: "https://schema.org/VideoObject" },
            //Element
            React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 n185ke" },
                //Element
                React.createElement( "video", { playsInline: "playsinline", className: "float-start w-100 h-auto", src: Video, controls: "controls", loop: true, autoPlay: true, muted: "muted", itemProp: "video" } ),
                //Element
                React.createElement( "meta", { itemProp: "name", content: Title } )
            ),
            //Element
            React.createElement( "meta", { itemProp: "uploadDate", content: "2024-01-01T08:00:00+08:00" } ),
            //Element
            React.createElement( "meta", { itemProp: "thumbnailUrl", content: "https://cdn.benozgurtosun.com/thumbnailUrl/001.png" } ),
            //Element
            React.createElement( "meta", { itemProp: "description", content: Summary } ),
            //Element
            React.createElement( "meta", { itemProp: "contentUrl", content: Video } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Tab.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Tab;