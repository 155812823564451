//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/showcase/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 mb-4 r4497j", title: Title, href: Target, target: "_blank", itemScope: "itemScope", itemType: "https://schema.org/LocalBusiness" },
            //Element
            React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 q9c6m0" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "642", height: "305", itemProp: "image" } )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-start p-0 m-0 mt-4 c37dc8" },
                //Element
                React.createElement( "h3", { className: "float-start w-auto p-0 m-0 w0r597", itemProp: "name alternateName", title: Title },
                    //Element
                    Title
                )
            ),
            //Element
            React.createElement( "meta", { itemProp: "url", content: Target } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Default;