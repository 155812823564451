//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/v1/business/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 i700xb" },
                    //Element
                    React.createElement( "h3", { className: "float-start w-auto p-0 m-0 z2499o", itemProp: "alternateName", title: Title },
                        //Element
                        Title
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:5 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 t883f0" },
                    //Element
                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 f43j54", itemScope: "itemScope", itemType: "https://schema.org/ImageObject" },
                        //Element
                        React.createElement( "img", { alt: Title, title: Title, src: Image, width: "416", height: "214", itemProp: "contentUrl" } ),
                        //Element
                        React.createElement( "meta", { itemProp: "name", content: Title } )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:4 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-5 i700xb" },
                    //Element
                    React.createElement( "p", { id: ID, className: "float-start w-auto p-0 m-0 m1364c", itemProp: "text" },
                        //Element
                        Summary
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Default;