//Install
import React, { Component } from "react";

//Element
class Middle extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 p9l58m" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 z93q7k" },
                //Element
                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 c28g97", title: "68 Farklı Şehir'de. 5.000 Farklı İşletme." },
                    //Element
                    "Bugün ",
                    //Element
                    React.createElement( "strong", {},
                        //Element
                        "68"
                    ),
                    //Element
                    " Farklı Şehir'de. ",
                    //Element
                    React.createElement( "strong", {},
                        //Element
                        "5.000"
                    ),
                    //Element
                    " Farklı İşletme."
                ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 qo3320" },
                    //Element
                    "Bugün 7 Bölge'de, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin ",
                    //Element
                    React.createElement( "br", {} ),
                    //Element
                    "Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım."
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Middle;