//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
class Bank extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Banka Hesabı Bilgileri - Benozgurtosun.com"
        //Element
        Description.content = "Bankalar'dan Yapılan EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/odeme"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Banka Hesabı Bilgileri - Benozgurtosun.com"
        //Element
        Description.content = "Bankalar'dan Yapılan EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/odeme"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Banka Hesabı Bilgileri - Benozgurtosun.com"
        //Element
        Description.content = "Bankalar'dan Yapılan EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_V33K32" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML = "Bankalar'dan Yapılan EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML = "Bankalardan Yapılan Havale İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML = "Bankalar Tarafından EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "Bankalar Tarafından Havale İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "Bankalar Tarafından EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML = "Bankalar Tarafından Havale İşlemleri için, Kesilen Ücret Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "Bankalar Tarafından EFT İşlemleri için, Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML = "Bankalar Tarafından EFT İşlemleri için, Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "Bankalar'dan Yapılan Havale İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Banka Hesabı Bilgileri - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Bankalar'dan Yapılan EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        let Source = "https://benozgurtosun.s3.eu-north-1.amazonaws.com/video/000.mp4"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Banka Hesabı Bilgileri", itemProp: "name alternateName" },
                                    //Element
                                    "Banka ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Hesabı"
                                    ),
                                    //Element
                                    " Bilgileri"
                                ),
                                //Element
                                React.createElement( "p", { id: "React_V33K32", className: "float-start w-auto p-0 m-0 mt-2 text-center u04m7q", itemProp: "description" },
                                    //Element
                                    "Bankalar'dan Yapılan EFT İşlemleri için Kesilen Ücretler Çalışılan Banka Şubeleri İle ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "İşlem Yapılan Kurumun Hizmet Ücretlerine Bağlıdır."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 p3287u" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 z8475v", itemScope: "itemScope", itemType: "https://schema.org/VideoObject" },
                                //Element
                                React.createElement( "video", { playsInline: "playsinline", className: "float-start w-100 h-auto", src: Source, controls: "controls", loop: true, autoPlay: true, muted: "muted", itemProp: "video" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 j71bv3" },
                                //Element
                                React.createElement( "table", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 i63421" },
                                    //Element
                                    React.createElement( "thead", {},
                                        //Element
                                        React.createElement( "tr", {},
                                            //Element
                                            React.createElement( "th", {},
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Banka"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "th", {},
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Şube"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "th", {},
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Şube Kodu"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "th", {},
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Para Birimi"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "th", {},
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "IBAN"
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "tbody", {},
                                        //Element
                                        React.createElement( "tr", {},
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türkiye İş Bankası"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türkmenbaşı"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "6047"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türk Lirası"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "TR36 0006 4000 0016 0470 3026 46"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "tr", {},
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Garanti BBVA"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türkmenbaşı"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "1158"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türk Lirası"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "TR38 0006 2001 1580 0006 2965 31"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "tr", {},
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Vakıfbank"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türkmenbaşı"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "0969"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türk Lirası"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "TR04 0001 5001 5800 7322 6360 85"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "tr", {},
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Halkbank"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türkmenbaşı"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "1515"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türk Lirası"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "TR29 0001 2001 5770 0001 1075 80"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "tr", {},
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Denizbank"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türkmenbaşı"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "1392"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "Türk Lirası"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "td", {},
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0" },
                                                    //Element
                                                    "TR81 0013 4000 0199 3518 8000 01"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Bank;