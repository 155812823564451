//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../Media/Mobile/005.svg";

//Element
class Sub extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 pb-3 m-0 d-none x0j627" },
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Web Tasarım", href: "/hemen-ulasin" },
                    //Element
                    React.createElement( "strong", {}, "Web Tasarım" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Domain", href: "/e-isletme/domain" },
                    //Element
                    React.createElement( "strong", {}, "Domain" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Hosting", href: "/e-isletme/hosting" },
                    //Element
                    React.createElement( "strong", {}, "Hosting" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "SSL Sertifikaları", href: "/e-isletme/ssl" },
                    //Element
                    React.createElement( "strong", {}, "SSL Sertifikaları" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kurumsal e-Posta", href: "/e-isletme/e-posta" },
                    //Element
                    React.createElement( "strong", {}, "Kurumsal e-Posta" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Search Engine Optimization", href: "/e-isletme/seo" },
                    //Element
                    React.createElement( "strong", {}, "Search Engine Optimization" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Sub;