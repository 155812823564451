//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Small extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Küçük İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "İnternette varlık oluşturmanız marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza ve hem internetten satışları hem de yerel satışları artırmanıza yardımcı olabilir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/kucuk-isletmeler-icin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Küçük İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "İnternette varlık oluşturmanız marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza ve hem internetten satışları hem de yerel satışları artırmanıza yardımcı olabilir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/maksimum-performans/kucuk-isletmeler-icin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Küçük İşletmeler İçin - Benozgurtosun.com"
        //Element
        Description.content = "İnternette varlık oluşturmanız marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza ve hem internetten satışları hem de yerel satışları artırmanıza yardımcı olabilir."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Dijital varlığınızı güçlendirin! Etkili bir web tasarım ile çevrimiçi görünürlüğü artırın ve markanızı geniş kitlelere duyurun."
            //Element
            Two.innerHTML         = "Rekabet avantajı elde edin! İyi bir web tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin"
            //Element
            Three.innerHTML       = "Güven oluşturun! Profesyonel bir web tasarımı ile markanızı güvenilir bir şekilde sunun ve müşterilerin güvenini kazanın."
            //Element
            Description.innerHTML = "Müşterilere ulaşın! Kurumsal bir web site tasarımı ile dijital bir varlık oluşturun ve müşterilerle etkileşimi artırın."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Online varlığınızı güçlendirin! Etkil bir web tasarım ile çevrimiçi görünürlüğü artırın ve markanızı geniş kitlelere duyurun."
            //Element
            Two.innerHTML         = "Rekabet avantajı elde edin! İyi bir web tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin"
            //Element
            Three.innerHTML       = "Güven oluşturun! Profesyonel bir web tasarımı ile markanızı güvenilir bir şekilde sunun ve müşterilerin güvenini kazanın."
            //Element
            Description.innerHTML = "Müşterilere ulaşın! Kurumsal bir web site tasarımı ile dijital bir varlık oluşturun ve müşterilerle etkileşimi artırın."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Dijital varlığınızı güçlendirin! Etkileyici bir web tasarımı ile çevrimiçi görünürlüğü artırın ve markanızı geniş kitlelere duyurun."
            //Element
            Two.innerHTML         = "Rekabet avantajı elde edin! İyi bir web tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin"
            //Element
            Three.innerHTML       = "Güven oluşturun! Profesyonel bir web site tasarımı ile markanızı güvenilir bir şekilde sunun ve müşterilerinizin güvenini kazanın."
            //Element
            Description.innerHTML = "Müşterilere ulaşın! Kurumsal bir web site tasarımı ile dijital bir varlık oluşturun ve müşterilerle etkileşimi artırın."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Dijital varlığınızı güçlendirin! Etkileyici bir web tasarımı ile çevrimiçi görünürlüğü artırın ve markanızı geniş kitlelere duyurun."
            //Element
            Two.innerHTML         = "Rekabet avantajı elde edin! İyi bir web tasarımı ile öne çıkarak, rakiplerinizden bir adım öne geçin"
            //Element
            Three.innerHTML       = "Güven oluşturun! Profesyonel bir web site tasarımı ile markanızı güvenilir bir şekilde sunun ve müşterilerinizin güvenini kazanın."
            //Element
            Description.innerHTML = "Müşterilere ulaşın! Kurumsal bir web site tasarımı ile dijital bir varlık oluşturun ve müşterilerle etkileşimi artırın."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Online varlığınızı güçlendirmek için, profesyonel bir web sitesi oluşturmak, müşterilere daha etkili ve sürekli bir şekilde erişim sağlar ve dijital dünyada güçlendirir."
            //Element
            Two.innerHTML         = "Rakiplerinizden öne geçmek ve sektörde lider olabilmek için işletmenize özel bir web tasarımı ile rekabet avantajı yakalayın."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarımıyla işletmenize daha güvenilir bir dijital imaj kazandırarak müşterilerin güvenini artırın ve online varlığınızı güçlendirin."
            //Element
            Description.innerHTML = "Dijital varlık oluşturmanız, marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Online varlığınızı güçlendirmek için, profesyonel bir web sitesi oluşturmak, müşterilere daha etkili ve sürekli bir şekilde erişim sağlar ve dijital dünyada güçlendirir."
            //Element
            Two.innerHTML         = "Rakiplerinizden öne geçmek ve sektörde lider olabilmek için işletmenize özel bir web tasarımı ile rekabet avantajı yakalayın."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarımıyla işletmenize daha güvenilir bir dijital imaj kazandırarak müşterilerin güvenini artırın ve online varlığınızı güçlendirin."
            //Element
            Description.innerHTML = "Online varlık oluşturmanız, marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Online varlığınızı güçlendirmek için, profesyonel bir web sitesi oluşturmak, müşterilere daha etkili ve sürekli bir şekilde erişim sağlar ve dijital dünyada güçlendirir."
            //Element
            Two.innerHTML         = "Rakiplerinizden öne geçmek ve sektörde lider olabilmek için işletmenize özel bir web tasarımı ile rekabet avantajı yakalayın."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarımıyla işletmenize daha güvenilir bir dijital imaj kazandırarak müşterilerin güvenini artırın ve online varlığınızı güçlendirin."
            //Element
            Description.innerHTML = "Online varlık oluşturmanız, marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Online varlığınızı güçlendirmek için, kurumsal bir internet sitesi oluşturmak, müşterilere daha etkili ve sürekli bir şekilde erişim sağlar ve dijital dünyada güçlendirir."
            //Element
            Two.innerHTML         = "Rakiplerinizin önüne geçmek ve sektörde lider olabilmek için işletmenize kurumsal bir web tasarımı ile rekabet avantajı yakalayın."
            //Element
            Three.innerHTML       = "Kurumsal bir web tasarımı ile işletmenize daha güvenilir bir dijital imaj kazandırarak müşterilerin güvenini artırın ve online varlığınızı güçlendirin."
            //Element
            Description.innerHTML = "Online varlık oluşturmanız, marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza yardımcı olabilir."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Web siteniz markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi doğru şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "İyi bir web site tasarım, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "Arama motorları, web sitelerini sıralamak için bir dizi kriteri değerlendirir ve başarılı bir web tasarım, bu kriterlere uygun şekilde optimize edilebilir."
            //Element
            Description.innerHTML = "Yeni müşteriler bulun işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun ve rekabet üstünlüğünüzü artırın."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Web siteniz, markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi doğru şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
            //Element
            Two.innerHTML         = "İyi bir web site tasarımı, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
            //Element
            Three.innerHTML       = "Arama motorları, web sitelerini sıralamak için bir dizi kriteri değerlendirir ve başarılı bir web tasarım, bu kriterlere uygun şekilde optimize edilebilir."
            //Element
            Description.innerHTML = "Yeni müşteriler bulun, işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun ve rekabet üstünlüğünüzü artırın."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "İnternet siteniz markanızın dijital vitrini gibidir. Profesyonel bir tasarım, marka kimliğinizi iyi şekilde yansıtarak müşterilere güçlü bir izlenim bırakabilir."
           //Element
           Two.innerHTML         = "Etkileyici bir web tasarımı, rakipleriniz arasında öne çıkmanızı sağlar ve müşterilerin ilgisini çeker."
           //Element
           Three.innerHTML       = "Web sitenizi arama motorları için optimize etmek, işletmenizin görünürlüğünü artırarak üst sıralarda yer almanıza ve daha fazla müşteri çekmenize katkı sağlar."
           //Element
           Description.innerHTML = "Yeni müşteriler bulun ve işletmenizi büyütün. Hedef kitlenizle güçlü bağlantılar kurun ve rekabet avantajınızı artırın."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Online varlığınızı güçlendirmek için profesyonel bir web sitesi edinin, online görünürlüğünüzü artırın, rekabette öne geçin!"
            //Element
            Two.innerHTML         = "Rakiplerinizi geride bırakmak ve işletmenizi çekici kılmak için hemen profesyonel web tasarımı ile avantaj sağlayın!"
            //Element
            Three.innerHTML       = "İşletme güvenilirliğinizi artırmak ve müşterileri etkilemek için çarpıcı bir dijital varlık oluşturun. Profesyonel web tasarımı ile online itibarınızı güçlendirin!"
            //Element
            Description.innerHTML = "İşletmenizi çevrimiçi öne çıkarmak ve rekabette lider olmak için şimdi profesyonel web tasarımını keşfedin, müşteri kitlenizi genişletin!"
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "İşletmenizin dijital varlığını güçlendirmek için şimdi profesyonel bir web sitesi edinin. Müşterilerle daha etkili iletişim kurun, online görünürlüğünüzü artırın ve rekabette öne geçin."
            //Element
            Two.innerHTML         = "Rakiplerinizden öne geçmek ve işletmenizi daha çekici kılmak için şimdi profesyonel bir web tasarımıyla rekabet avantajı elde edin!"
            //Element
            Three.innerHTML       = "İşletmenizin güvenilirliğini yükseltmek ve müşterilerinizi etkilemek için çarpıcı bir dijital varlık oluşturun. Profesyonel web tasarımıyla online itibarınızı güçlendirin!"
            //Element
            Description.innerHTML = "İşletmenizi online dünyada daha görünür hale getirerek müşteri kitlenizi genişletmek ve rekabette öne geçmek için şimdi profesyonel bir web tasarımıyla tanışın!"
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Küçük İşletmeler İçin - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "İnternette varlık oluşturmanız marka bilinirliğini artırmanıza ve daha fazla müşteri bulmanıza ve hem internetten satışları hem de yerel satışları artırmanıza yardımcı olabilir."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Müşteriler İçin Çevrimiçi Bir İşletme Oluşturun.", itemProp: "name headline" },
                                    //Element
                                    "Müşteriler İçin Çevrimiçi Bir ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "İşletme"
                                    ),
                                    //Element
                                    " Oluşturun."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "İnternette varlık oluşturmanız marka bilinirliğini artırmanıza ve daha fazla müşteri",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "bulmanıza ve hem internetten satışları hem de yerel satışları artırmanıza",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "yardımcı olabilir."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "Müşteriler İçin Çevrimiçi Bir İşletme Oluşturun.", title: "Müşteriler İçin Çevrimiçi Bir İşletme Oluşturun.", src: "https://cdn.benozgurtosun.com/v1/business/689981.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Dijital Varlığınızı Güçlendirin", Summary: "İşletmenizi online dünyada güçlendirmek için profesyonel bir web sitesi edinmek, müşterilere daha etkili ve sürekli bir şekilde ulaşmanızı sağlar.", Photo: "504217.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Rekabet Avantajı Kazanın", Summary: "Rakiplerinizden öne geçmek ve sektörde lider olabilmek için işletmenize özel bir web tasarım ile rekabet avantajı yakalayın!", Photo: "964607.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Güven Oluşturun", Summary: "İşletmenizi daha güvenilir kılacak bir dijital imaj için kurumsal bir web tasarımıyla müşterilerinizin güvenini kazanın ve online varlığınızı güçlendirin!", Photo: "816154.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Small;