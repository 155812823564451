//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Feature from "../Shortcode/Content/Feature";

//Element
class Features extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Col, { md:"10 offset-md-1" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( Feature, { ID: "React_UM296S", Title: "Bilinirliği artırın.", Summary: "İşletmenizin görünürlüğünü artırın! İyi web tasarım ile çevrimiçi etkileşimi maksimize ederek müşteri çekimini güçlendirin.", Target: "/" } )
                ),
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( Feature, { ID: "React_FT982U", Title: "İlişkiler kurun.", Summary: "İşletmenizin çevrimiçi varlığını güçlendirin ve müşteri ilişkilerinizi kurumsal bir web tasarım ile daha yakın, etkili bir şekilde kurun!", Target: "/" } )
                ),
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( Feature, { ID: "React_G80S0M", Title: "Yeni müşteriler edinin.", Summary: "Yeni müşteriler ile tanışın! İşletmenizi çevrimiçi olarak öne çıkaracak kurumsal web tasarım ile büyüme potansiyelinizi artırın.", Target: "/" } )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Features;