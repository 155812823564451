//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";

//Element
class Welcome extends Component {
    //Element
    render() {
        //Element
        const Brand = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            autoplay        : {
                //Element
                delay       : 3000
            },
            //Element
            loop            : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 g2d163" },
            //Element
            React.createElement( Swiper, { className: "d-flex w-100 justify-content-center align-items-center position-relative overflow-hidden", ...Brand },
                //Element
                React.createElement( SwiperSlide, { className: "d-flex w-100 justify-content-center align-items-center" },
                    //Element
                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 o188l9" },
                        //Element
                        "İyi bir ",
                        //Element
                        React.createElement( "u", {},
                            //Element
                            "web tasarım",
                        ),
                        //Element
                        " ile"
                    ),
                    //Element
                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 ms-1 q746r5" },
                        //Element
                        "marka kimliği oluşturun."
                    )
                ),
                //Element
                React.createElement( SwiperSlide, { className: "d-flex w-100 justify-content-center align-items-center" },
                    //Element
                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 o188l9" },
                        //Element
                        "İyi bir ",
                        //Element
                        React.createElement( "u", {},
                            //Element
                            "web tasarım",
                        ),
                        //Element
                        " ile"
                    ),
                    //Element
                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 ms-1 q746r5" },
                        //Element
                        "bilinirliği artırın."
                    )
                ),
                //Element
                React.createElement( SwiperSlide, { className: "d-flex w-100 justify-content-center align-items-center" },
                    //Element
                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 o188l9" },
                        //Element
                        "İyi bir ",
                        //Element
                        React.createElement( "u", {},
                            //Element
                            "web tasarım",
                        ),
                        //Element
                        " ile"
                    ),
                    //Element
                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 ms-1 q746r5" },
                        //Element
                        "yeni müşteriler edinin."
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Welcome;