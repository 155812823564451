//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Count extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 mt-5 o720ha", itemScope: "itemScope", itemType: "https://schema.org/Statement" },
            //Element
            React.createElement( "label", { className: "float-start w-auto p-0 m-0 o50h0d", itemProp: "name alternateName", title: Title },
                //Element
                Title
            ),
            //Element
            React.createElement( "span", { className: "float-start w-auto p-0 m-0 m8m73h", itemProp: "description", title: Title },
                //Element
                Summary
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Count.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Count;