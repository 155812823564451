//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../Media/Mobile/005.svg";

//Element
class Menu extends Component {
    //Element
    Target = (e) => {
        //Element
        e.preventDefault()
        //Element
        let TZ755Q = document.getElementsByClassName( "y98o1m" )[0]
        //Element
        let P8966A = document.getElementsByClassName( "x0j627" )[0]
        //Element
        TZ755Q.classList.toggle( "d-none" )
        //Element
        P8966A.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 pb-4 m-0 y98o1m" },
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Başlangıç", href: "/" },
                    //Element
                    React.createElement( "strong", {}, "Başlangıç" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kurumsal", href: "/kurumsal" },
                    //Element
                    React.createElement( "strong", {}, "Kurumsal" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Sunulan Hizmetler", href: "/sunulan-hizmetler", onClick: this.Target },
                    //Element
                    React.createElement( "strong", {}, "Sunulan Hizmetler" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Ücretlendirme", href: "/ucretlendirme" },
                    //Element
                    React.createElement( "strong", {}, "Ücretlendirme" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kimlere Hizmet Sunuldu?", href: "/referanslar" },
                    //Element
                    React.createElement( "strong", {}, "Kimlere Hizmet Sunuldu?" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Rekabet Programı", href: "/rekabet-programi" },
                    //Element
                    React.createElement( "strong", {}, "Rekabet Programı" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Hemen Ulaşın", href: "/hemen-ulasin" },
                    //Element
                    React.createElement( "strong", {}, "Hemen Ulaşın" ),
                    //Element
                    React.createElement( Icon, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;