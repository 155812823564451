//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Shortcode from "../../Shortcode/Partner/Default";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : İnternet'e Değer Katan Küresel İş Ortaklarım - Benozgurtosun.com"
        //Element
        Description.content = "Benimle çalışan işletmelere; değer katan küresel markalar, küçük işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/partner"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : İnternet'e Değer Katan Küresel İş Ortaklarım - Benozgurtosun.com"
        //Element
        Description.content = "Benimle çalışan işletmelere; değer katan küresel markalar, küçük işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/partner"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : İnternet'e Değer Katan Küresel İş Ortaklarım - Benozgurtosun.com"
        //Element
        Description.content = "Benimle çalışan işletmelere; değer katan küresel markalar, küçük işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Resposive = () => {
        //Element
        let Description = document.querySelector( "#React_J409W3" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML = "Bu markalar işbirliği yaptığı işletmelere değer katar ve işletmelerin başarılı olmaları için gerekli desteği sağlar."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML = "Benimle çalışan şirketlere değer katan bu küresel markalar, işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML = "Bu markalar iş birliği yaptığı işletmelere değer katar ve başarılı olmaları için gerekli desteği sağlar."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "Benimle çalışan şirketlere değer katan küresel markalar, işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "Benimle çalışan şirketlere değer katan küresel markalar, işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML = "Benimle çalışan şirketlere değer katan küresel markalar, işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "Benimle çalışan şirketlere değer katan küresel markalar, işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            Description.innerHTML = "Benimle çalışan şirketlere değer katan küresel markalar işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML = "Bu global markalar iş birliği yaptığı işletmelere değer katar ve işletmelerin başarılı olmaları için gerekli desteği sağlar."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML = "Bu global markalar iş birliği yaptığı işletmelere değer katar ve işletmelerin başarılı olmaları için gerekli desteği sağlar."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           Description.innerHTML = "Bu global markalar, işbirliği yaptığı işletmelere değer katar ve küçük işletmelerin başarılı olmaları için gerekli desteği sağlar."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "Bu küresel markalar, işbirliği yaptığı işletmelere değer katar ve küçük işletmelerin başarılı olmaları için gerekli desteği sağlar."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Description.innerHTML = "İşletmelerle işbirliği yapan bu küresel markalar, küçük işletmelere başarılı olmaları için gerekli destek ve araçları sağlar."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : İnternet'e Değer Katan Küresel İş Ortaklarım - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Benimle çalışan işletmelere; değer katan küresel markalar, küçük işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Resposive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "İnternet'e Değer Katan Küresel İş Ortaklarım", itemProp: "name alternateName" },
                                    //Element
                                    "İnternet'e Değer Katan Küresel ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "İş Ortaklarım"
                                    )
                                ),
                                //Element
                                React.createElement( "p", { id: "React_J409W3", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Benimle çalışan işletmelere değer katan bu küresel markalar, yeni işletmelere ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "başarılı olabilmeleri için gereken desteği ve araçları sunar."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 p2z98p" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 m505cz" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "1", Title: "Meta", Photo: "009.svg", Target: "https://about.meta.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "2", Title: "Symantec", Photo: "007.svg", Target: "https://sep.securitycloud.symantec.com/v2/landing" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "3", Title: "BigCommerce", Photo: "002.svg", Target: "https://www.bigcommerce.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "4", Title: "GoDaddy", Photo: "005.svg", Target: "https://www.godaddy.com/tr-tr" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "5", Title: "Semrush", Photo: "001.svg", Target: "https://www.semrush.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "6", Title: "Workplace", Photo: "006.svg", Target: "https://www.workplace.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "7", Title: "Hotjar", Photo: "003.svg", Target: "https://www.hotjar.com/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "8", Title: "Cloudways", Photo: "004.svg", Target: "https://www.cloudways.com/en/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "9", Title: "Shutterstock", Photo: "008.svg", Target: "https://www.shutterstock.com/tr/" } )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( Shortcode, { ID: "10", Title: "HubSpot", Photo: "010.svg", Target: "https://www.hubspot.com/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;