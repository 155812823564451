//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Digital from "../../Shortcode/Digital/Default";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Email extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : @sirketiniz Uzantılı e-Posta Adresi Alın - Benozgurtosun.com"
        //Element
        Description.content = "Kendi özel domaininize sahip olduktan sonra; domain adınızı içeren, bir kurumsal e-posta adresi alarak profesyonelliğinizi ve güvenilirliğinizi arttırın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/e-posta"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : @sirketiniz Uzantılı e-Posta Adresi Alın - Benozgurtosun.com"
        //Element
        Description.content = "Kendi özel domaininize sahip olduktan sonra; domain adınızı içeren, bir kurumsal e-posta adresi alarak profesyonelliğinizi ve güvenilirliğinizi arttırın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/e-isletme/e-posta"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : @sirketiniz Uzantılı e-Posta Adresi Alın - Benozgurtosun.com"
        //Element
        Description.content = "Kendi özel domaininize sahip olduktan sonra; domain adınızı içeren, bir kurumsal e-posta adresi alarak profesyonelliğinizi ve güvenilirliğinizi arttırın."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Responsive = () => {
        //Element
        let Description       = document.querySelector( "#React_91SQ74" )
        //Element
        let One               = document.querySelector( "#React_5T1K9Q" )
        //Element
        let Two               = document.querySelector( "#React_403CQM" )
        //Element
        let Three             = document.querySelector( "#React_0W08YH" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            One.innerHTML         = "Profesyonel bir imaj için, kurumsal e-posta adresi kullanın. İşletmenizi güvenilir bir şekilde öne çıkarmak için ideal bir adım atın!"
            //Element
            Two.innerHTML         = "Marka bilinirliğini artırmak için, kurumsal e-posta adresi kullanın ve profesyonel bir görüntü oluşturun!"
            //Element
            Three.innerHTML       = "İyi bir izlenim bırakmak, güçlü iletişim kurmak için kurumsal e-posta adresi edinin."
            //Element
            Description.innerHTML = "Kurumsal e-posta adresleri için bir domain edinin. Bu adım, profesyonelliğinizi ve güvenilirliğinizi vurgular."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            One.innerHTML         = "Profesyonel bir imaj için, kurumsal e-posta adresi kullanın. İşletmenizi güvenilir bir şekilde öne çıkarmak için ideal bir adım atın!"
            //Element
            Two.innerHTML         = "Marka bilinirliğini artırmak için, kurumsal e-posta adresi kullanın ve profesyonel bir görüntü oluşturun!"
            //Element
            Three.innerHTML       = "İyi bir izlenim bırakmak, güçlü iletişim kurmak için kurumsal e-posta adresi edinin."
            //Element
            Description.innerHTML = "Kurumsal e-posta adresleri için bir domain edinin. Bu adım, profesyonelliğinizi ve güvenilirliğinizi vurgular."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            One.innerHTML         = "Kurumsal bir imaj için, özel e-posta adresleri kullanın. İşletmenizi güvenilir bir şekilde öne çıkarmak için ideal bir adım atın!"
            //Element
            Two.innerHTML         = "Marka tanırlığını artırmak için kurumsal e-posta adresi kullanın ve profesyonel bir görüntü oluşturun!"
            //Element
            Three.innerHTML       = "İyi bir ilk izlenim bırakmak ve güçlü iletişim kurmak için kurumsal e-posta adresi edinin."
            //Element
            Description.innerHTML = "Kurumsal  e-posta adresi için özel bir alan adı edinin. Bu adım, işinizin profesyonelliğini ve güvenilirliğini ön plana çıkarmanın etkili bir yoludur."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            One.innerHTML         = "Kurumsal bir imaj için, özel e-posta adresleri kullanın. İşletmenizi güvenilir bir şekilde öne çıkarmak için ideal bir adım atın!"
            //Element
            Two.innerHTML         = "Marka tanırlığını artırmak için kurumsal e-posta adresi kullanın ve profesyonel bir görüntü oluşturun!"
            //Element
            Three.innerHTML       = "İyi bir ilk izlenim bırakmak ve güçlü iletişim kurmak için kurumsal e-posta adresi edinin."
            //Element
            Description.innerHTML = "Kurumsal  e-posta adresi için özel bir alan adı edinin. Bu adım, işinizin profesyonelliğini ve güvenilirliğini ön plana çıkarmanın etkili bir yoludur."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            One.innerHTML         = "Kurumsal e-posta kullanımı işletmenizi güvenilir kılar. İyi bir alan adına sahip e-posta adresi müşteri ve iş ortaklarında güven oluşturur."
            //Element
            Two.innerHTML         = "Kurumsal e-posta kullanımı, marka tanıtımını daha etkili bir şekilde güçlendirebilir. E-posta, logo ve kurumsal imza kullanarak marka bilinirliğini artırabilirsin."
            //Element
            Three.innerHTML       = "Kurumsal e-posta, müşterilerle ve iş ortakları ile iletişimi güçlendirir. Özel alan adı içeren e-posta adresleri, işletmenizin ciddiyetini vurgular."
            //Element
            Description.innerHTML = "Özel domaininizi edindikten sonra, kurumsal bir e-posta adresi alarak profesyonel imajınızı güçlendirin ve güvenilirliğinizi artırın."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            One.innerHTML         = "Kurumsal e-posta kullanımı işletmenizi güvenilir kılar. İyi bir alan adına sahip e-posta adresi müşteri ve iş ortaklarında güven oluşturur."
            //Element
            Two.innerHTML         = "Kurumsal e-posta kullanımı, marka tanıtımını daha etkili bir şekilde güçlendirebilir. E-posta, logo ve kurumsal imza kullanarak marka bilinirliğini artırabilirsin."
            //Element
            Three.innerHTML       = "Kurumsal e-posta, müşterilerle ve iş ortakları ile iletişimi güçlendirir. Özel alan adı içeren e-posta adresleri, işletmenizin ciddiyetini vurgular."
            //Element
            Description.innerHTML = "Özel domaininizi edindikten sonra, kurumsal bir e-posta adresi alarak profesyonel imajınızı güçlendirin ve güvenilirliğinizi artırın."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            One.innerHTML         = "Kurumsal e-posta kullanımı işletmenizi güvenilir kılar. İyi bir alan adına sahip e-posta adresi müşteri ve iş ortaklarında güven oluşturur."
            //Element
            Two.innerHTML         = "Kurumsal e-posta kullanımı, marka tanıtımını daha etkili bir şekilde güçlendirebilir. E-posta, logo ve kurumsal imza kullanarak marka bilinirliğini artırabilirsin."
            //Element
            Three.innerHTML       = "Kurumsal e-posta, müşterilerle ve iş ortakları ile iletişimi güçlendirir. Özel alan adı içeren e-posta adresleri, işletmenizin ciddiyetini vurgular."
            //Element
            Description.innerHTML = "Özel domaininizi edindikten sonra, kurumsal bir e-posta adresi alarak profesyonel imajınızı güçlendirin ve güvenilirliğinizi artırın."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
            //Element
            One.innerHTML         = "Kurumsal e-posta kullanımı, işletmenizi güvenilir kılar. İyi bir alan adına sahip e-posta adresi müşteri ve iş ortaklarında güven oluşturur."
            //Element
            Two.innerHTML         = "Kurumsal e-posta kullanımı, marka tanıtımını daha güçlü bir şekilde güçlendirebilir. E-posta ve kurumsal imza kullanarak marka bilinirliğini artırabilirsin."
            //Element
            Three.innerHTML       = "Kurumsal e-posta, müşterilerle ve iş ortakları ile iletişimi güçlendirir. Özel alan adı içeren e-posta adresleri, işletmenizin ciddiyetini vurgular."
            //Element
            Description.innerHTML = "Özel domaininizi edindikten sonra, kurumsal bir e-posta adresi alarak profesyonel imajınızı güçlendirin ve güvenilirliğinizi artırın."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            One.innerHTML         = "Profesyonel bir imaj için, kurumsal e-posta adresi kullanın. İşletmenizi güvenilir ve ciddi bir şekilde öne çıkarmak için ideal bir adım atın!"
            //Element
            Two.innerHTML         = "Marka bilinirliğinizi artırmak için, kurumsal e-posta adresi kullanın ve profesyonel bir görüntü oluşturun!"
            //Element
            Three.innerHTML       = "Profesyonel bir izlenim bırakmak, etkili iletişim kurmak için kurumsal e-posta adresleri tercih edin, işletmenizi güçlü bir şekilde temsil edin."
            //Element
            Description.innerHTML = "İşletmeniz için bir domain alarak kurumsal e-posta adresi edinin; bu adım, profesyonelliğinizi ve güvenilirliğinizi vurgulamanın mükemmel bir yoludur."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            One.innerHTML         = "Profesyonel bir imaj için, kurumsal e-posta adresi kullanın. İşletmenizi güvenilir ve ciddi bir şekilde öne çıkarmak için ideal bir adım atın!"
            //Element
            Two.innerHTML         = "Marka bilinirliğinizi artırmak için kurumsal e-posta adresleri kullanın ve profesyonel bir görüntü oluşturun!"
            //Element
            Three.innerHTML       = "Profesyonel bir izlenim bırakmak, etkili iletişim kurmak için kurumsal e-posta adresleri tercih edin, işletmenizi güçlü bir şekilde temsil edin."
            //Element
            Description.innerHTML = "İşletmenize özel bir domain alarak kurumsal e-posta adresi edinin; bu adım, profesyonelliğinizi ve güvenilirliğinizi vurgulamanın mükemmel bir yoludur."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           One.innerHTML         = "Profesyonel bir imaj için, kurumsal e-posta adresleri kullanın. İşletmenizi güvenilir ve ciddi bir şekilde öne çıkarmak için ideal bir adım atın!"
           //Element
           Two.innerHTML         = "Markanızı öne çıkarmak için kurumsal e-posta adresi kullanın. Profesyonel bir imajla marka bilinirliğinizi artırın!"
           //Element
           Three.innerHTML       = "Kurumsal e-posta adresleri ile profesyonel bir şekilde iletişim kurun ve işletmenizi güçlü bir şekilde temsil edin."
           //Element
           Description.innerHTML = "İşletmenize özel bir domain alarak kurumsal e-posta adresleri edinin; bu adım, profesyonelliğinizi ve güvenilirliğinizi vurgulamanın mükemmel bir yoludur."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            One.innerHTML         = "Profesyonel bir görünüm için kurumsal e-posta kullanın. İşletmenizi güvenilir ve ciddi bir şekilde öne çıkarın!"
            //Element
            Two.innerHTML         = "Markanızı öne çıkarmak için kurumsal e-posta kullanın. Profesyonel ve güvenilir bir imajla marka bilinirliğinizi artırın!"
            //Element
            Three.innerHTML       = "Profesyonel iletişim için kurumsal e-posta kullanın. İşletmenizi etkili bir şekilde temsil edin, müşterilerinizle güvenilir bir bağ kurun!"
            //Element
            Description.innerHTML = "Özel domain alarak kurumsal e-posta adresinizi edinin, işletmenizin profesyonelliğini ve güvenilirliğini artırın."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            One.innerHTML         = "Profesyonel bir imaj için kurumsal e-posta kullanın. Güvenilir ve ciddi bir görünümle işletmenizi öne çıkarın!"
            //Element
            Two.innerHTML         = "Markanızı öne çıkarmak için kurumsal e-posta kullanın. Profesyonel ve güvenilir bir imaj oluşturarak marka bilinirliğinizi artırın!"
            //Element
            Three.innerHTML       = "Profesyonel iletişim için kurumsal e-posta kullanın. Özel e-posta adresiyle işletmenizi daha etkili bir şekilde temsil edin ve müşterilerinizle güvenilir bir bağ kurun!"
            //Element
            Description.innerHTML = "Kendi özel domaininizi alarak, özelleştirilmiş bir kurumsal e-posta adresi edinin ve işletmenizin profesyonelliğini ile güvenilirliğini artırın."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : @sirketiniz Uzantılı e-Posta Adresi Alın - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Kendi özel domaininize sahip olduktan sonra; domain adınızı içeren, bir kurumsal e-posta adresi alarak profesyonelliğinizi ve güvenilirliğinizi arttırın."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Responsive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Article" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "@sirketiniz Uzantılı e-Posta Adresi Alın.", itemProp: "name headline" },
                                    //Element
                                    "@sirketiniz Uzantılı ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "e-Posta"
                                    ),
                                    //Element
                                    " Adresi Alın."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_91SQ74", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Kendi özel domaininize sahip olduktan sonra; domain adınızı içeren, ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "bir kurumsal e-posta adresi alarak profesyonelliğinizi ve ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "güvenilirliğinizi arttırın."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 sr551s" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"8 offset-md-2" },
                            //Element
                            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v408hk" },
                                //Element
                                React.createElement( "img", { alt: "@sirketiniz Uzantılı e-Posta Adresi Alın.", title: "@sirketiniz Uzantılı e-Posta Adresi Alın.", src: "https://cdn.benozgurtosun.com/v1/business/491114.webp", width: "856", height: "570", itemProp: "image" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_5T1K9Q", Title: "Profesyonel Görünüm", Summary: "Kurumsal e-posta, işletmenizi profesyonel ve güvenilir kılar. Özel bir alan adına sahip e-posta adresleri, müşteri ve iş ortaklarında güven oluşturur.", Photo: "846071.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_403CQM", Title: "Marka Tanıtımı", Summary: "Kurumsal e-posta, markanızın tanıtımını güçlendirir. E-posta adresleri, logo ve kurumsal imza kullanarak marka bilinirliğinizi artırabilirsiniz.", Photo: "732206.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 v7672m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Digital, { ID: "React_0W08YH", Title: "Profesyonel İletişim", Summary: "Kurumsal e-posta, müşterilerle ve iş ortaklarıyla profesyonel iletişimi güçlendirir. Özel alan adı içeren e-posta adresleri, işletmenizin ciddiyetini vurgular.", Photo: "285030.webp", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Email;